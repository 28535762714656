import React from 'react';
import cl from './redesign-header.module.css';
import {ReactComponent as LogoIcon} from '../../../images/logo.svg';

function RedesignHeader({
  BodyComponent,
  HeaderToolsComponent,
  propsForBody,
  propsForHeader,
}) {
  return (
    <div className={cl.pageWrapper}>
      <div className={cl.pageComposition}>
        <div className={cl.header}>
          <a href='/'><LogoIcon /></a>
          {HeaderToolsComponent && <HeaderToolsComponent {...propsForHeader} />}
        </div>

        <div className={cl.body}>
          {BodyComponent && <BodyComponent {...propsForBody} />}
        </div>
      </div>
    </div>
  );
}

export default RedesignHeader;
