import React from 'react';
import './beer-item-table.component.css';
import {ReactComponent as UnfilteredBeerIcon} from '../../../images/unfilteredBeerIcon.svg';
import {SERVER_URL} from '../../../config';

const BEER_LABELS = {
    dark: 'тёмное',
    light: 'светлое',
    sweet: 'сладкое',
    red: 'красное',
};

const BEER_TYPES = {
    dark: 'table-beer-item-dark',
    light: 'table-beer-item-light',
    red: 'table-beer-item-red',
    sweet: 'table-beer-item-sweet',
};

function BeerSmallItemTable({
                                index,
                                tags,
                                beerLabel,
                                beerType,
                                crane,
                                classList,
                                menuItem,
                                isDynamicCellSize,
                                screenItemsCount
                            }) {

    if (screenItemsCount <= 6 && isDynamicCellSize) {
        classList.push('beer-item-6')
    } else if (screenItemsCount <= 8 && isDynamicCellSize) {
        classList.push('beer-item-8')
    } else if (screenItemsCount <= 10 && isDynamicCellSize) {
        classList.push('beer-item-10')
    }

    let govnoList = ['class-for-gap']
    if (screenItemsCount % 2 === 1 && screenItemsCount <= 6 && isDynamicCellSize) {
        govnoList.push('beer-item-nechetnoe')
    } else if (screenItemsCount % 2 === 1 && screenItemsCount <= 8 && isDynamicCellSize) {
        govnoList.push('beer-item-nechetnoe-2')
    }

    const className = classList.join(' ');

    const backgroundOfPricePart = {
        backgroundImage: `linear-gradient(180deg,rgba(0,0,0,0.7),rgba(0,0,0,0.7)), var(--image-url)`,
        backgroundPosition: 'center',
        backgroundSize: 'contain',
        overflow: 'hidden',
        filter: `blur(20px)`,
        transform: 'scale(1.2)',
    }

    const imageUrl = menuItem.image && menuItem.image.url
        ? SERVER_URL + menuItem.image.url.split('?')[0]
        : null;

    return <div className={govnoList.join(' ')}>
        <div key={index} className={className}>
            <div className="number-container">
                {crane.number}
            </div>
            <div className='price-container'>
                <div className="beer-background-container">
                    <div
                        className='beer-background'
                        style={{
                            ...backgroundOfPricePart,
                            '--image-url': `url(${imageUrl})`,
                        }}>
                    </div>
                </div>
                <div className='background-wrapper'>
                    {menuItem.image && menuItem.image.url ? (
                        <>
                            <img
                                alt={menuItem.name}
                                src={SERVER_URL + menuItem.image.url.split('?')[0]}
                            />
                            <span className='empty-space'></span>
                        </>
                    ) : null}

                    <div className='divider-for-gap-12px-horizontal'></div>
                    <div className="beer-item-price-container">
                        {
                            crane.is_product_end ? (
                                <>
                                    <span className='beer-item-beer-ended'>закончилось</span>
                                </>
                            ) : (
                                <>
                                    <span className='beer-item-price'>{menuItem.price} ₽</span>
                                </>
                            )
                        }
                        {
                            menuItem.volume ? <span className='beer-item-volume'>{menuItem.volume}л</span> : null
                        }
                    </div>
                </div>
            </div>
            <div className='right-container'>
                <div className='name-container'>
                    <div className='name'>{menuItem.name}</div>
                    <div className='bottom'>
                        <div className='style'>{menuItem.beer_sort}</div>
                        <div className='brewery-beer-item'>{menuItem.brewery}</div>
                    </div>
                </div>
                <div className='params-container'>
                    <div className='top'>
                        <div className='param'>
                            {menuItem.alcohol_degrees
                                ? `${menuItem.alcohol_degrees}%`
                                : 'Б/А'}
                            <span>алк</span>
                        </div>
                        <div className='param'>
                            {menuItem.alcohol_density}%
                            <span>плт</span>
                        </div>
                        {menuItem.bitterness_scale ? (
                            <div className='param'>
                                {menuItem.bitterness_scale}
                                <span>IBU</span>
                            </div>
                        ) : null}

                    </div>
                    <div className='bottom'>
                        {
                            menuItem.filtered === false ? (
                                <div className='beer-item-unfiltered-wrapper'>
                                    <UnfilteredBeerIcon/>
                                    <span className='beer-item-unfiltered-typography'>Нефильтр</span>
                                </div>
                            ) : null
                        }
                        <div className='beer-item-color'>
                            <div className={beerType}/>
                            {
                                beerLabel ? (
                                    <span className='beer-item-color-typography'>
                                      {beerLabel[0].toUpperCase() + beerLabel.slice(1)}
                                    </span>
                                ) : null
                            }
                        </div>
                    </div>
                </div>
                <div className='tags-container'>
                    {Array.from(tags).map((tag, index) => {
                        let style = {backgroundColor: tag.color};
                        if (tag.color !== 'null') {
                            style.display = 'flex';
                        }
                        return (
                            <div key={index} className='beer-item-tag' style={style}>
                                {tag.name}
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>

        <div className='divider-for-gap-3px-vertical'></div>
    </div>
}

function BeerItemTable({index, menuItem, crane, store, isBigItems, screenItemsCount, isDynamicCellSize}) {
    const beerType = BEER_TYPES[menuItem.beer_color];
    const beerLabel = BEER_LABELS[menuItem.beer_color];

    const tags = [
        {color: menuItem.tag1_color, name: menuItem.tag1_name},
        {
            color: menuItem.tag2_color,
            name: menuItem.tag2_name,
        },
        {color: menuItem.tag3_color, name: menuItem.tag3_name},
        {color: menuItem.tag4_color, name: menuItem.tag4_name},
    ].filter((tag) => tag.name);

    const classList = ['beer-item-table'];

    if (crane.is_product_end) {
        classList.push('beer-item-ended');
    }
    if (screenItemsCount <= 10 && isDynamicCellSize) {
        return <BeerSmallItemTable
            index={index}
            crane={crane}
            tags={tags}
            beerType={beerType}
            beerLabel={beerLabel}
            menuItem={menuItem}
            classList={classList}
            screenItemsCount={screenItemsCount}
            isDynamicCellSize={isDynamicCellSize}/>
    }

    const className = classList.join(' ');
    const customHeightStyles = isBigItems
        ? {
            height: `${
                window.screen.width * window.devicePixelRatio > 1280
                    ? window.screen.width * window.devicePixelRatio > 3839
                        ? '140px'
                        : '68px'
                    : '43px'
            }`,
        }
        : {
            height: `${
                window.screen.width * window.devicePixelRatio > 1280
                    ? window.screen.width * window.devicePixelRatio > 3839
                        ? '108px'
                        : '56px'
                    : '36.8px'
            }`,
        };

    const customBorderStylesForPrice = isBigItems
        ? {
            border: `${
                window.screen.width * window.devicePixelRatio > 1280
                    ? window.screen.width * window.devicePixelRatio > 3839
                        ? '18.5px solid #ffffff'
                        : '8px solid #ffffff'
                    : '4px solid #ffffff'
            }`,
        }
        : {
            border: `${
                window.screen.width * window.devicePixelRatio > 1280
                    ? window.screen.width * window.devicePixelRatio > 3839
                        ? '3px solid #ffffff'
                        : '3px solid #ffffff'
                    : '2px solid #ffffff'
            }`,
        };

    const backgroundOfPricePart =
        crane.is_product_end || !menuItem.image
            ? {backgroundColor: '#353535'}
            : {
                backgroundImage: `linear-gradient(180deg,rgba(0,0,0,0.5),rgba(0,0,0,0.5)), var(--image-url)`,
                backgroundPosition: 'center',
                backgroundSize: 'contain',
                overflow: 'hidden',
                filter: `blur(28px)`,
            };

    const imageUrl = menuItem.image && menuItem.image.url
        ? SERVER_URL + menuItem.image.url.split('?')[0]
        : null;

    return (
        <div className='class-for-gap'>
            <div key={index} className={className} style={customHeightStyles}>
                {/* Номер крана */}
                <span className='beer-item-number'>{crane.number}</span>

                {/* Картинка и цена */}
                <div className='price-and-name-wrap'>
                    <div
                        className='beer-item-image-and-price'
                        style={customBorderStylesForPrice}>
                        {!crane.is_product_end ? (
                            <div
                                className='beer-background'
                                style={{
                                    ...backgroundOfPricePart,
                                    borderRadius: '166px',
                                    '--image-url': `url(${imageUrl})`,
                                }}></div>
                        ) : null}
                        <div className='background-wrapper' style={{}}>
                            {menuItem.image && menuItem.image.url ? (
                                <img
                                    alt={menuItem.name}
                                    src={SERVER_URL + menuItem.image.url.split('?')[0]}
                                />
                            ) : null}

                            <div className='divider-for-gap-12px-horizontal'></div>
                            {/* Пиво закончилось либо цена */}
                            <div className="beer-item-price-container">
                                {crane.is_product_end ? (
                                    <span className='beer-item-beer-ended'>закончилось</span>
                                ) : (
                                    <span className='beer-item-price'>{menuItem.price} ₽</span>
                                )}
                                <span className='beer-item-volume'>0.4л</span>
                            </div>
                        </div>
                    </div>

                    {/* Название пива, фильтр/нефильтр */}
                    <div className='beer-item-name-container'>
                        <span className='beer-item-name'>{menuItem.name}</span>

                        <div className='divider-for-gap-12px-horizontal'></div>

                        {menuItem.filtered === false ? (
                            <div className='beer-item-unfiltered-wrapper'>
                                <UnfilteredBeerIcon/>
                                <span className='beer-item-unfiltered-typography'>
                  Нефильтр
                </span>
                            </div>
                        ) : null}
                    </div>
                </div>

                <div className='divider-for-gap-12px-horizontal'></div>

                {/* тип пива @example: Темное, Светлое */}
                <div className='beer-item-color'>
                    <div className={beerType}/>
                    {beerLabel ? (
                        <span className='beer-item-color-typography'>
              {beerLabel[0].toUpperCase() + beerLabel.slice(1)}
            </span>
                    ) : null}
                </div>

                <div className='divider-for-gap-16px-horizontal'></div>

                {/* Алкоголь, IBU, плотность */}
                <div className='beer-item-middle'>
                    <div className='beer-item-percentage-left'>
            <span className='beer-item-percentage-main'>
              {menuItem.alcohol_degrees
                  ? `${menuItem.alcohol_degrees}%`
                  : 'Б/А'}
            </span>

                        <span className='beer-item-percentage-description'>алкоголь</span>
                    </div>

                    <div className='divider-for-gap-8px-horizontal'></div>
                    <div className='vertical-divider'></div>
                    <div className='divider-for-gap-8px-horizontal'></div>

                    {menuItem.bitterness_scale ? (
                        <div className='beer-item-ibu-wrapper'>
                            <span>IBU</span>
                            <span>{menuItem.bitterness_scale}</span>
                        </div>
                    ) : null}

                    {menuItem.bitterness_scale ? (
                        <>
                            <div className='divider-for-gap-8px-horizontal'></div>
                            <div className='vertical-divider'></div>
                            <div className='divider-for-gap-8px-horizontal'></div>
                        </>
                    ) : null}

                    <div className='beer-item-percentage-right'>
                            <span className='beer-item-percentage-main'>
                              {menuItem.alcohol_density}%
                            </span>

                        <span className='beer-item-percentage-description'>плотность</span>
                    </div>
                </div>

                <div className='divider-for-gap-16px-horizontal'></div>

                {/* Всякая оставшаяся дрочь */}
                <div className='beer-item-sort-and-brewery-wrapper'>
                    <span className='beer-item-sort'>{menuItem.beer_sort}</span>

                    <span className='beer-item-beervarny'>{menuItem.brewery}</span>
                </div>

                <div className='divider-for-gap-16px-horizontal'></div>

                {/* Блок с тэгами */}
                <div className='beer-item-tags'>
                    {Array.from(tags).map((tag, index) => {
                        let style = {backgroundColor: tag.color};
                        if (tag.color !== 'null') {
                            style.display = 'flex';
                        }
                        return (
                            <div key={index} className='beer-item-tag' style={style}>
                                {tag.name}
                            </div>
                        );
                    })}
                </div>
            </div>

            <div className='divider-for-gap-3px-vertical'></div>
        </div>
    );
}

export default BeerItemTable;
