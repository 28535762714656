import React from "react";
import '../style/adminEditor.css';
import API from "../api";
import {SERVER_URL} from "../config";
import {FormControl, IconButton, InputLabel, MenuItem, OutlinedInput, Pagination, Select} from "@mui/material";
import Utils, {safetyInput} from "./utils";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import {styled} from "@mui/material/styles";

import {ReactComponent as LogoIcon} from "../images/logo.svg";

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const PAGES = {
    'Пиво': 'Пиво',
    'Пивоварни': 'Пивоварни',
    'Сорта': 'Сорта',
    'Цвета': 'Цвета',
    'Фильтрация': 'Фильтрация',
    'Страны': 'Страны'
}

class AdminEditor extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            currentPage: 'Пиво',

            search_query: '',
            contentPage: 1,
            perPage: 20,
            totalPages: 0,

            products: [],
            breweries: [],
            styles: [],
            colors: [],
            filters: [],
            countries: [],

            newItem: {},
            editableItem: {},
            objectItem: {},
            editProductsOpen: false,
            addProductsOpen: false,
            editObjectOpen: false,
            isEditObjectNew: false,
            editObjectType: null,

            filteredBeerValues: {true: 'Да', false: 'Нет', null: '—'},
            filteredBeer: true,
        }

        this.onUniversalInput = (e, obj, key, objName) => {
            obj = JSON.parse(JSON.stringify(obj));
            let value = e.target.value;

            if (key === 'alcohol_degrees') {
                if (value >= 100) value = 100;
                if (value <= 0) value = 0;
            }

            if (key === 'alcohol_density') {
                if (value >= 100) value = 100;
                if (value <= 0) value = 0;
            }

            if (key === 'bitterness_scale') {
                if (value >= 200) value = 200;
                if (value <= 0) value = 0;
            }

            if (key === 'price') {
                if (value <= 0) value = 0;
            }

            if (key === 'tap_number') {
                if (value <= 0) value = 0;
            }

            obj[key] = value;
            this.setState({[objName]: obj});
        };

        this.onUniversalValueInput = (value, obj, key, objName) => {
            obj[key] = value;
            this.setState({[objName]: obj});
        };

        this.updateProducts = () => {
            API.adminBaseEditorNewList(this.state.search_query, this.state.contentPage, this.state.perPage)
                .then(data => {
                    this.setState({products: data.products, totalPages: data.total})
                })
        }

        this.updateObjects = () => {
            API.adminBaseEditorNewObjects()
                .then(data => {
                    this.setState({
                        breweries: data.breweries,
                        styles: data.styles,
                        colors: data.colors,
                        filters: data.filters,
                        countries: data.countries,
                    })
                })
        }

        this.sendChanges = () => {
            API.saveAdminEditorChangedMenuItem(this.state.editableItem)
                .then((data) => {
                    this.setEditProductsOpen(false);
                    this.updateProducts();
                })
                .catch((error) => {
                    console.error(error);
                    this.setEditProductsOpen(false);
                    this.updateProducts();
                });
        };

        this.deleteChanges = () => {
            API.deleteAdminEditorMenuItem({
                menuItemId: this.state.editableItem.id,
            })
                .then((data) => {
                    this.setEditProductsOpen(false);
                    this.updateProducts();
                })
                .catch((error) => {
                    console.error(error);
                    this.setEditProductsOpen(false);
                    this.updateProducts();
                });
        };

        this.cancelChanges = () => {
            this.setEditProductsOpen(false);
            this.updateProducts();
        };

        this.sendCreating = () => {
            API.saveAdminEditorCreatingMenuItem(this.state.newItem)
                .then((data) => {
                    this.setAddProductsOpen(false);
                    this.updateProducts();
                })
                .catch((error) => {
                    console.error(error);
                    this.setAddProductsOpen(false);
                    this.updateProducts();
                });
        };

        this.sendObjectSave = () => {
            API.saveAdminEditorObjectSave(this.state.objectItem, this.state.isEditObjectNew, this.state.editObjectType)
                .then((data) => {
                    this.setEditObjectOpen(false, false, null, {})
                    this.updateObjects();
                })
                .catch((error) => {
                    console.error(error);
                    this.setEditObjectOpen(false, false, null, {})
                    this.updateObjects();
                });
        }

        this.deleteObject = () => {
            API.deleteAdminEditorObjectSave(this.state.objectItem.id, this.state.editObjectType)
                .then((data) => {
                    this.setEditObjectOpen(false, false, null, {})
                    this.updateObjects();
                })
                .catch((error) => {
                    console.error(error);
                    this.setEditObjectOpen(false, false, null, {})
                    this.updateObjects();
                });
        }

        this.fileInputChange = (e, callback) => {
            let target = e.target;
            if (target.files && target.files[0]) {
                let file = target.files[0];
                let reader = new FileReader();
                reader.onload = (event) => {
                    let base64String = event.target.result;
                    document.getElementById('image').src = base64String;
                    let payload = {imageBase64: base64String};
                    this.setState(payload, () => callback(base64String));
                };
                reader.readAsDataURL(file);
            }
        };

        this.filePreviewHandler = (b64str) => {
            let item = this.state.editableItem;
            if (item.image) {
                item.image.image = b64str;
            } else {
                item.image = {
                    image: b64str,
                };
            }
            this.setState({editableItem: item});
        };

        this.fileUploadHandler = (b64str) => {
            let item = this.state.newItem;
            item.image = {
                image: b64str,
            };
            this.setState({newItem: item});
        };

        this.setEditProductsOpen = (status) =>
            this.setState({editProductsOpen: status});

        this.setAddProductsOpen = (status) =>
            this.setState({addProductsOpen: status})

        this.setEditObjectOpen = (status, isNew, objectType, objectItem) =>
            this.setState({
                editObjectOpen: status,
                isEditObjectNew: isNew,
                editObjectType: objectType,
                objectItem: objectItem
            })
    }

    componentDidMount() {
        this.updateProducts()

        this.updateObjects()
    }

    renderBeer(page) {
        return <div className='admin-editor-content'>
            <h3>{page}</h3>
            <div className='admin-editor-header'>
                <div className='admin-editor-button-add' onClick={() => this.setAddProductsOpen(true)}>
                    Добавить позицию
                </div>
                <div className='admin-editor-search'>
                    <input placeholder='Поиск' onChange={(event) => this.setState({search_query: event.target.value})}/>
                </div>
                <div className='admin-editor-button-add' onClick={this.updateProducts}>
                    Поиск
                </div>
            </div>
            <div className='admin-editor-items'>
                {
                    this.state.products.map(product => {
                        return <div className='admin-editor-item'>
                            <img src={product.image ? SERVER_URL + product.image.url : null}/>
                            <div className='admin-editor-item-name'>
                                {product.name}
                            </div>
                            <div className='admin-editor-item-edit' onClick={() => {
                                this.setState({editProductsOpen: true, editableItem: product})
                            }}>
                                Редактировать
                            </div>
                        </div>
                    })
                }
            </div>
            <Pagination
                count={parseInt(this.state.totalPages / 20) + 1}
                page={this.state.contentPage}
                onChange={(event, page) => this.setState({
                    contentPage: page
                }, this.updateProducts)}
                color='primary'
                sx={{display: 'flex', justifyContent: 'center', marginTop: '20px'}}
            />
        </div>
    }

    renderObject(page, objectType, items) {
        return <div className='admin-editor-content'>
            <h3>{page}</h3>
            <div className='admin-editor-header'>
                <div className='admin-editor-button-add'
                     onClick={() => this.setEditObjectOpen(true, true, objectType, {})}>
                    Добавить позицию
                </div>
            </div>
            <div className='admin-editor-items'>
                {
                    items.map(product => {
                        return <div className='admin-editor-item'>
                            <div className='admin-editor-item-name'>
                                {product.name}
                            </div>
                            <div className='admin-editor-item-edit'
                                 onClick={() => this.setEditObjectOpen(true, false, objectType, product)}>
                                Редактировать
                            </div>
                        </div>
                    })
                }
            </div>
        </div>
    }

    render() {
        let page;

        if (this.state.currentPage === 'Пиво') page = this.renderBeer(this.state.currentPage)
        else if (this.state.currentPage === 'Пивоварни') page = this.renderObject(this.state.currentPage, 'brewery', this.state.breweries)
        else if (this.state.currentPage === 'Сорта') page = this.renderObject(this.state.currentPage, 'style', this.state.styles)
        else if (this.state.currentPage === 'Цвета') page = this.renderObject(this.state.currentPage, 'color', this.state.colors)
        else if (this.state.currentPage === 'Фильтрация') page = this.renderObject(this.state.currentPage, 'filtered', this.state.filters)
        else if (this.state.currentPage === 'Страны') page = this.renderObject(this.state.currentPage, 'country', this.state.countries)

        return <div className='admin-editor-container'>
            <div className='admin-editor-left'>
                <div style={{margin: 8}}>
                    <a href='/admin/'>
                        <LogoIcon/>
                    </a>
                </div>
                {
                    Object.keys(PAGES).map(page => {
                        let customClass = ['admin-editor-left-page']
                        if (this.state.currentPage === page) {
                            customClass.push('admin-editor-left-page-active')
                        }
                        return <div className={customClass.join(' ')}
                                    onClick={() => this.setState({currentPage: page})}>
                            {page}
                        </div>
                    })
                }
            </div>
            <div className='admin-editor-right'>
                {page}
            </div>

            <Dialog
                scroll={Utils.isDeviceMobile() ? 'paper' : 'body'}
                classes={{root: 'customer-dashboard-modal-add-product'}}
                open={this.state.addProductsOpen}
                onClose={() => this.setAddProductsOpen(false)}
                maxWidth={'sm'}
                fullWidth={false}
                PaperProps={{
                    component: 'form',
                }}>
                <div className='modal-edit-product-title-container'>
                    <div className='modal-edit-product-title'>
                        Добавить позицию
                    </div>
                    <IconButton onClick={() => this.setAddProductsOpen(false)}>
                        <CloseRoundedIcon/>
                    </IconButton>
                </div>
                <div className='modal-edit-product-inputs-container'>
                    <TextField
                        margin='dense'
                        id='name'
                        name='name'
                        label='Название'
                        type='text'
                        fullWidth
                        variant='outlined'
                        value={safetyInput(this.state.newItem.name)}
                        onInput={(e) =>
                            this.onUniversalInput(e, this.state.newItem, 'name', 'newItem')
                        }
                    />
                    <TextField
                        margin='dense'
                        id='description'
                        name='description'
                        label='Описание'
                        type='text'
                        fullWidth
                        variant='outlined'
                        value={safetyInput(this.state.newItem.description)}
                        onInput={(e) =>
                            this.onUniversalInput(
                                e,
                                this.state.newItem,
                                'description',
                                'newItem'
                            )
                        }
                    />
                    <FormControl sx={{width: '100%'}}>
                        <InputLabel>Пивоварня</InputLabel>
                        <Select
                            label
                            id="demo-multiple-name"
                            // multiple
                            value={safetyInput(this.state.newItem.brewery_id)}
                            onChange={(event, child) => {
                                this.onUniversalValueInput(event.target.value, this.state.newItem, 'brewery_id', 'newItem')
                            }}
                            input={<OutlinedInput label="Name"/>}
                            MenuProps={MenuProps}
                        >
                            <MenuItem
                                key={null}
                                value={null}
                            >
                                Пусто
                            </MenuItem>
                            {this.state.breweries.map((brewery) => (
                                <MenuItem
                                    key={brewery.id}
                                    value={brewery.id}
                                >
                                    {brewery.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>

                    <FormControl sx={{width: '100%'}}>
                        <InputLabel>Стиль</InputLabel>
                        <Select
                            value={safetyInput(this.state.newItem.style_id)}
                            onChange={(event, child) => {
                                this.onUniversalValueInput(event.target.value, this.state.newItem, 'style_id', 'newItem')
                            }}
                            input={<OutlinedInput label="Name"/>}
                            MenuProps={MenuProps}
                        >
                            <MenuItem
                                key={null}
                                value={null}
                            >
                                Пусто
                            </MenuItem>
                            {this.state.styles.map((item) => (
                                <MenuItem
                                    key={item.id}
                                    value={item.id}
                                >
                                    {item.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>

                    <FormControl sx={{width: '100%'}}>
                        <InputLabel>Цвет</InputLabel>
                        <Select
                            value={safetyInput(this.state.newItem.color_id)}
                            onChange={(event, child) => {
                                this.onUniversalValueInput(event.target.value, this.state.newItem, 'color_id', 'newItem')
                            }}
                            input={<OutlinedInput label="Name"/>}
                            MenuProps={MenuProps}
                        >
                            <MenuItem
                                key={null}
                                value={null}
                            >
                                Пусто
                            </MenuItem>
                            {this.state.colors.map((item) => (
                                <MenuItem
                                    key={item.id}
                                    value={item.id}
                                >
                                    {item.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>

                    <FormControl sx={{width: '100%'}}>
                        <InputLabel>Фильтрация</InputLabel>
                        <Select
                            value={safetyInput(this.state.newItem.filtered_id)}
                            onChange={(event, child) => {
                                this.onUniversalValueInput(event.target.value, this.state.newItem, 'filtered_id', 'newItem')
                            }}
                            input={<OutlinedInput label="Name"/>}
                            MenuProps={MenuProps}
                        >
                            <MenuItem
                                key={null}
                                value={null}
                            >
                                Пусто
                            </MenuItem>
                            {this.state.filters.map((item) => (
                                <MenuItem
                                    key={item.id}
                                    value={item.id}
                                >
                                    {item.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>

                    <FormControl sx={{width: '100%'}}>
                        <InputLabel>Страна</InputLabel>
                        <Select
                            value={safetyInput(this.state.newItem.country_id)}
                            onChange={(event, child) => {
                                this.onUniversalValueInput(event.target.value, this.state.newItem, 'country_id', 'newItem')
                            }}
                            input={<OutlinedInput label="Name"/>}
                            MenuProps={MenuProps}
                        >
                            <MenuItem
                                key={null}
                                value={null}
                            >
                                Пусто
                            </MenuItem>
                            {this.state.countries.map((item) => (
                                <MenuItem
                                    key={item.id}
                                    value={item.id}
                                >
                                    {item.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </div>
                <div className='modal-edit-product-horizontal-cells'>
                    <div className='modal-edit-product-horizontal-cell'>
                        <div className='modal-edit-product-horizontal-cell-card'>
                            <div className='modal-edit-product-horizontal-cell-card-title'>
                                Алкоголь
                            </div>
                        </div>
                        <div className='modal-edit-product-horizontal-cell-input'>
                            <TextField
                                margin='dense'
                                id='alcohol'
                                name='alcohol'
                                type='number'
                                fullWidth
                                variant='outlined'
                                step='0.1'
                                value={safetyInput(this.state.newItem.alcohol_degrees)}
                                onInput={(e) =>
                                    this.onUniversalInput(
                                        e,
                                        this.state.newItem,
                                        'alcohol_degrees',
                                        'newItem'
                                    )
                                }
                            />
                        </div>
                    </div>
                    <div className='modal-edit-product-horizontal-cell'>
                        <div className='modal-edit-product-horizontal-cell-card'>
                            <div className='modal-edit-product-horizontal-cell-card-title'>
                                Плотность
                            </div>
                        </div>
                        <div className='modal-edit-product-horizontal-cell-input'>
                            <TextField
                                margin='dense'
                                id='density'
                                name='density'
                                type='number'
                                fullWidth
                                variant='outlined'
                                step='0.1'
                                value={safetyInput(this.state.newItem.alcohol_density)}
                                onInput={(e) =>
                                    this.onUniversalInput(
                                        e,
                                        this.state.newItem,
                                        'alcohol_density',
                                        'newItem'
                                    )
                                }
                            />
                        </div>
                    </div>
                    <div className='modal-edit-product-horizontal-cell'>
                        <div className='modal-edit-product-horizontal-cell-card'>
                            <div className='modal-edit-product-horizontal-cell-card-title'>
                                IBU
                            </div>
                        </div>
                        <div className='modal-edit-product-horizontal-cell-input'>
                            <TextField
                                margin='dense'
                                id='bitterness_scale'
                                name='bitterness_scale'
                                type='number'
                                fullWidth
                                variant='outlined'
                                step='0.1'
                                value={safetyInput(this.state.newItem.bitterness_scale)}
                                onInput={(e) =>
                                    this.onUniversalInput(
                                        e,
                                        this.state.newItem,
                                        'bitterness_scale',
                                        'newItem'
                                    )
                                }
                            />
                        </div>
                    </div>
                </div>
                <div className='modal-edit-product-image-container'>
                    <InputLabel>Изображение</InputLabel>
                    <div className='customer-dashboard-modal-add-product-file-preview'>
                        {this.state.newItem.image ? (
                            <img
                                alt='preview'
                                src={
                                    this.state.newItem.image.image
                                        ? this.state.newItem.image.image
                                        : SERVER_URL + this.state.newItem.image.url
                                }
                            />
                        ) : null}
                    </div>
                    <Button
                        component='label'
                        role={undefined}
                        variant='contained'
                        tabIndex={-1}>
                        Загрузить изображение
                        <VisuallyHiddenInput
                            id='image'
                            name='image'
                            type='file'
                            accept='image/*'
                            onChange={(e) =>
                                this.fileInputChange(e, this.fileUploadHandler)
                            }
                        />
                    </Button>
                </div>
                <div className='modal-edit-product-bottom-buttons'>
                    <Button
                        classes={{root: 'modal-edit-product-bottom-button-save'}}
                        component='label'
                        role={undefined}
                        variant='contained'
                        tabIndex={-1}
                        onClick={() => this.sendCreating()}>
                        Добавить
                    </Button>
                    <Button
                        classes={{root: 'modal-edit-product-bottom-button-cancel'}}
                        component='label'
                        role={undefined}
                        variant='contained'
                        tabIndex={-1}
                        onClick={() => this.cancelCreating()}>
                        Отменить
                    </Button>
                </div>
            </Dialog>

            <Dialog
                scroll={'body'}
                classes={{root: 'customer-dashboard-modal-edit-product'}}
                open={this.state.editProductsOpen}
                onClose={() => this.setEditProductsOpen(false)}
                maxWidth={'sm'}
                fullWidth={false}
                PaperProps={{
                    component: 'form',
                }}>
                <div className='modal-edit-product-title-container'>
                    <div className='modal-edit-product-title'>
                        ID {this.state.editableItem.id}
                    </div>
                    <IconButton onClick={() => this.setEditProductsOpen(false)}>
                        <CloseRoundedIcon/>
                    </IconButton>
                </div>
                <div className='modal-edit-product-inputs-container'>
                    <TextField
                        margin='dense'
                        id='name'
                        name='name'
                        label='Название'
                        type='text'
                        fullWidth
                        variant='outlined'
                        value={safetyInput(this.state.editableItem.name)}
                        onInput={(e) =>
                            this.onUniversalInput(
                                e,
                                this.state.editableItem,
                                'name',
                                'editableItem'
                            )
                        }
                    />
                    <TextField
                        margin='dense'
                        id='description'
                        name='description'
                        label='Описание'
                        type='text'
                        fullWidth
                        variant='outlined'
                        value={safetyInput(this.state.editableItem.description)}
                        onInput={(e) =>
                            this.onUniversalInput(
                                e,
                                this.state.editableItem,
                                'description',
                                'editableItem'
                            )
                        }
                    />
                    <FormControl sx={{width: '100%'}}>
                        <InputLabel>Пивоварня</InputLabel>
                        <Select
                            label
                            id="demo-multiple-name"
                            // multiple
                            value={safetyInput(this.state.editableItem.brewery_id)}
                            onChange={(event, child) => {
                                this.onUniversalValueInput(event.target.value, this.state.editableItem, 'brewery_id', 'editableItem')
                            }}
                            input={<OutlinedInput label="Name"/>}
                            MenuProps={MenuProps}
                        >
                            <MenuItem
                                key={null}
                                value={null}
                            >
                                Пусто
                            </MenuItem>
                            {this.state.breweries.map((brewery) => (
                                <MenuItem
                                    key={brewery.id}
                                    value={brewery.id}
                                >
                                    {brewery.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>

                    <FormControl sx={{width: '100%'}}>
                        <InputLabel>Стиль</InputLabel>
                        <Select
                            value={safetyInput(this.state.editableItem.style_id)}
                            onChange={(event, child) => {
                                this.onUniversalValueInput(event.target.value, this.state.editableItem, 'style_id', 'editableItem')
                            }}
                            input={<OutlinedInput label="Name"/>}
                            MenuProps={MenuProps}
                        >
                            <MenuItem
                                key={null}
                                value={null}
                            >
                                Пусто
                            </MenuItem>
                            {this.state.styles.map((item) => (
                                <MenuItem
                                    key={item.id}
                                    value={item.id}
                                >
                                    {item.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>

                    <FormControl sx={{width: '100%'}}>
                        <InputLabel>Цвет</InputLabel>
                        <Select
                            value={safetyInput(this.state.editableItem.color_id)}
                            onChange={(event, child) => {
                                this.onUniversalValueInput(event.target.value, this.state.editableItem, 'color_id', 'editableItem')
                            }}
                            input={<OutlinedInput label="Name"/>}
                            MenuProps={MenuProps}
                        >
                            <MenuItem
                                key={null}
                                value={null}
                            >
                                Пусто
                            </MenuItem>
                            {this.state.colors.map((item) => (
                                <MenuItem
                                    key={item.id}
                                    value={item.id}
                                >
                                    {item.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>

                    <FormControl sx={{width: '100%'}}>
                        <InputLabel>Фильтрация</InputLabel>
                        <Select
                            value={safetyInput(this.state.editableItem.filtered_id)}
                            onChange={(event, child) => {
                                this.onUniversalValueInput(event.target.value, this.state.editableItem, 'filtered_id', 'editableItem')
                            }}
                            input={<OutlinedInput label="Name"/>}
                            MenuProps={MenuProps}
                        >
                            <MenuItem
                                key={null}
                                value={null}
                            >
                                Пусто
                            </MenuItem>
                            {this.state.filters.map((item) => (
                                <MenuItem
                                    key={item.id}
                                    value={item.id}
                                >
                                    {item.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>

                    <FormControl sx={{width: '100%'}}>
                        <InputLabel>Страна</InputLabel>
                        <Select
                            value={safetyInput(this.state.editableItem.country_id)}
                            onChange={(event, child) => {
                                this.onUniversalValueInput(event.target.value, this.state.editableItem, 'country_id', 'editableItem')
                            }}
                            input={<OutlinedInput label="Name"/>}
                            MenuProps={MenuProps}
                        >
                            <MenuItem
                                key={null}
                                value={null}
                            >
                                Пусто
                            </MenuItem>
                            {this.state.countries.map((item) => (
                                <MenuItem
                                    key={item.id}
                                    value={item.id}
                                >
                                    {item.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>

                </div>
                <div className='modal-edit-product-horizontal-cells'>
                    <div className='modal-edit-product-horizontal-cell'>
                        <div className='modal-edit-product-horizontal-cell-card'>
                            <div className='modal-edit-product-horizontal-cell-card-title'>
                                Алкоголь
                            </div>
                        </div>
                        <div className='modal-edit-product-horizontal-cell-input'>
                            <TextField
                                margin='dense'
                                id='alcohol'
                                name='alcohol'
                                type='number'
                                step='0.1'
                                fullWidth
                                variant='outlined'
                                value={safetyInput(this.state.editableItem.alcohol_degrees)}
                                onInput={(e) =>
                                    this.onUniversalInput(
                                        e,
                                        this.state.editableItem,
                                        'alcohol_degrees',
                                        'editableItem'
                                    )
                                }
                            />
                        </div>
                    </div>
                    <div className='modal-edit-product-horizontal-cell'>
                        <div className='modal-edit-product-horizontal-cell-card'>
                            <div className='modal-edit-product-horizontal-cell-card-title'>
                                Плотность
                            </div>
                        </div>
                        <div className='modal-edit-product-horizontal-cell-input'>
                            <TextField
                                margin='dense'
                                id='density'
                                name='density'
                                type='number'
                                step='0.1'
                                fullWidth
                                variant='outlined'
                                value={safetyInput(this.state.editableItem.alcohol_density)}
                                onInput={(e) =>
                                    this.onUniversalInput(
                                        e,
                                        this.state.editableItem,
                                        'alcohol_density',
                                        'editableItem'
                                    )
                                }
                            />
                        </div>
                    </div>
                    <div className='modal-edit-product-horizontal-cell'>
                        <div className='modal-edit-product-horizontal-cell-card'>
                            <div className='modal-edit-product-horizontal-cell-card-title'>
                                IBU
                            </div>
                        </div>
                        <div className='modal-edit-product-horizontal-cell-input'>
                            <TextField
                                margin='dense'
                                id='bitterness_scale'
                                name='bitterness_scale'
                                step='0.1'
                                type='number'
                                fullWidth
                                variant='outlined'
                                value={safetyInput(this.state.editableItem.bitterness_scale)}
                                onInput={(e) =>
                                    this.onUniversalInput(
                                        e,
                                        this.state.editableItem,
                                        'bitterness_scale',
                                        'editableItem'
                                    )
                                }
                            />
                        </div>
                    </div>
                </div>
                <div className='modal-edit-product-image-container'>
                    <InputLabel>Изображение</InputLabel>
                    <div className='customer-dashboard-modal-add-product-file-preview'>
                        {this.state.editableItem.image ? (
                            <img
                                alt='preview'
                                src={
                                    this.state.editableItem.image.image
                                        ? this.state.editableItem.image.image
                                        : SERVER_URL + this.state.editableItem.image.url
                                }
                            />
                        ) : null}
                    </div>
                    <Button
                        component='label'
                        role={undefined}
                        variant='contained'
                        tabIndex={-1}>
                        Загрузить изображение
                        <VisuallyHiddenInput
                            id='image'
                            name='image'
                            type='file'
                            accept='image/*'
                            onChange={(e) =>
                                this.fileInputChange(e, this.filePreviewHandler)
                            }
                        />
                    </Button>
                </div>
                <div className='modal-edit-product-bottom-buttons'>
                    <Button
                        classes={{root: 'modal-edit-product-bottom-button-save'}}
                        component='label'
                        role={undefined}
                        variant='contained'
                        tabIndex={-1}
                        onClick={() => this.sendChanges()}>
                        Сохранить
                    </Button>
                    <Button
                        classes={{root: 'modal-edit-product-bottom-button-cancel'}}
                        component='label'
                        role={undefined}
                        variant='contained'
                        tabIndex={-1}
                        onClick={() => this.cancelChanges()}>
                        Отменить
                    </Button>
                </div>
                <div
                    className='modal-edit-product-bottom-last'
                    onClick={() => this.deleteChanges()}>
                    <Button variant='text'>Удалить</Button>
                </div>
            </Dialog>

            <Dialog
                scroll={Utils.isDeviceMobile() ? 'paper' : 'body'}
                classes={{root: 'customer-dashboard-modal-add-product'}}
                open={this.state.editObjectOpen}
                onClose={() => this.setEditObjectOpen(false, false, null, {})}
                maxWidth={'sm'}
                fullWidth={true}
                PaperProps={{
                    component: 'form',
                }}>
                <div className='modal-edit-product-title-container'>
                    <div className='modal-edit-product-title'>
                        {this.state.isEditObjectNew ? 'Добавить' : 'Изменить'}
                    </div>
                    <IconButton onClick={() => this.setEditObjectOpen(false, false, null, {})}>
                        <CloseRoundedIcon/>
                    </IconButton>
                </div>
                <div className='modal-edit-product-inputs-container'>
                    <TextField
                        margin='dense'
                        id='name'
                        name='name'
                        label='Название'
                        type='text'
                        fullWidth
                        variant='outlined'
                        value={safetyInput(this.state.objectItem.name)}
                        onInput={(e) =>
                            this.onUniversalInput(e, this.state.objectItem, 'name', 'objectItem')
                        }
                    />
                    <TextField
                        margin='dense'
                        id='plural_name'
                        name='plural_name'
                        label='Название в множественном числе'
                        type='text'
                        fullWidth
                        variant='outlined'
                        value={safetyInput(this.state.objectItem.plural_name)}
                        onInput={(e) =>
                            this.onUniversalInput(e, this.state.objectItem, 'plural_name', 'objectItem')
                        }
                    />

                    {
                        this.state.editObjectType === 'style' ? <FormControl sx={{width: '100%'}}>
                            <InputLabel>Родительский стиль</InputLabel>
                            <Select
                                value={safetyInput(this.state.objectItem.parent_id)}
                                onChange={(event, child) => {
                                    this.onUniversalValueInput(event.target.value, this.state.objectItem, 'parent_id', 'objectItem')
                                }}
                                input={<OutlinedInput label="Name"/>}
                                MenuProps={MenuProps}
                            >
                                <MenuItem
                                    key={null}
                                    value={null}
                                >
                                    Пусто
                                </MenuItem>
                                {this.state.styles.map((item) => (
                                    <MenuItem
                                        key={item.id}
                                        value={item.id}
                                    >
                                        {item.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl> : null
                    }
                </div>
                <div className='modal-edit-product-bottom-buttons'>
                    <Button
                        classes={{root: 'modal-edit-product-bottom-button-save'}}
                        component='label'
                        role={undefined}
                        variant='contained'
                        tabIndex={-1}
                        onClick={() => this.sendObjectSave()}>
                        Добавить
                    </Button>
                    <Button
                        classes={{root: 'modal-edit-product-bottom-button-cancel'}}
                        component='label'
                        role={undefined}
                        variant='contained'
                        tabIndex={-1}
                        onClick={() => this.setEditObjectOpen(false, false, null, {})}>
                        Отменить
                    </Button>
                </div>
                {
                    this.state.isEditObjectNew ? null : <div
                        className='modal-edit-product-bottom-last'
                        onClick={() => this.deleteObject()}>
                        <Button variant='text'>Удалить</Button>
                    </div>
                }
            </Dialog>
        </div>
    }
}

export default AdminEditor;