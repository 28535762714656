import React, {useEffect, useState} from 'react';
import cl from './beer-db-editor.module.css';
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  FormControl,
  IconButton,
  InputLabel,
  ListItemText,
  MenuItem,
  Pagination,
  Select,
  styled,
  TextField,
} from '@mui/material';
import BeerInfoService from './services/beer-info.service';
import {SERVER_URL} from '../../../../../config';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import Utils, {safetyInput} from '../../../../utils';
import API from '../../../../../api';
import MultiRadioButton from '../../../multiRadioButton';
import BeerActionsService from './services/beer-actions';

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

const beerColorValues = {
  light: 'Светлое',
  dark: 'Темное',
  sweet: 'Сладкое',
  red: 'Красное',
};

const filteredBeerValues = {true: 'Да', false: 'Нет', null: '—'};

const levenshteinDistance = (a, b) => {
  const c = a.length + 1;
  const d = b.length + 1;
  const r = Array(c);
  for (let i = 0; i < c; ++i) r[i] = Array(d);
  for (let i = 0; i < c; ++i) r[i][0] = i;
  for (let j = 0; j < d; ++j) r[0][j] = j;
  for (let i = 1; i < c; ++i) {
    for (let j = 1; j < d; ++j) {
      const s = a[i - 1] === b[j - 1] ? 0 : 1;
      r[i][j] = Math.min(r[i - 1][j] + 1, r[i][j - 1] + 1, r[i - 1][j - 1] + s);
    }
  }
  return r[a.length][b.length];
};

function BeerCard({beer, openBindDialog, onOpenBeerEditorDialog}) {
  const beerLabels = {
    dark: 'тёмное',
    light: 'светлое',
    sweet: 'сладкое',
    red: 'красное',
  };

  return (
    <div className={cl.beerCardWrapper}>
      <div className={cl.cardHeader}>
        <div
          className={cl.beerImg}
          style={beer.image ?{
            backgroundImage: `url(${SERVER_URL + beer.image.url.split('?')[0]})`,
          } : null}
        ></div>

        <span className={cl.cardTitle}>{beer.name}</span>
      </div>

      <div className={cl.flexbox}>
        <div className={cl.infoWrapper}>
          <span className={cl.infoTtitle}>Алкоголь</span>
          <span className={cl.info}>{beer.alcohol_degrees}</span>
        </div>

        <div className={cl.infoWrapper}>
          <span className={cl.infoTtitle}>Плотность</span>
          <span className={cl.info}>{beer.alcohol_density}</span>
        </div>
      </div>

      <div className={cl.flexbox}>
        <div className={cl.infoWrapper}>
          <span className={cl.infoTtitle}>Цвет пива</span>
          <span className={cl.info}>{beerLabels[beer.beer_color]}</span>
        </div>

        <div className={cl.infoWrapper}>
          <span className={cl.infoTtitle}>Сорт пива</span>
          <span
            style={{color: `${beer.beer_sort ? '#222222' : '#FF564E'}`}}
            title={beer.beer_sort}
            className={cl.info}
          >
            {beer.beer_sort || 'Не указан'}
          </span>
        </div>
      </div>

      <div className={cl.actions}>
        <div className={cl.actionsWrapper1}>
          <button
            onClick={() => onOpenBeerEditorDialog(beer)}
            className={cl.beerAction}
          >
            Редактировать
          </button>

          <button
            disabled={true}
            style={{opacity: '0.3'}}
            className={cl.beerAction}
          >
            SEO
          </button>
        </div>

        <div className={cl.actionsWrapper2}>
          <button
            disabled={true}
            style={{opacity: '0.3'}}
            onClick={() => openBindDialog(Math.floor(Math.random() * 10))}
            className={cl.beerAction}
          >
            Привяз. клиенты
          </button>
        </div>
      </div>
    </div>
  );
}

function BindClientsModal({open, onClose}) {
  const initialData = [
    {
      id: 321,
      label: 'Пивная корова',
      address: 'Улица адреса, дом адреса',
      isBinded: false,
    },
    {
      id: 322,
      label: 'Пивная корова',
      address: 'Улица адреса, дом адреса',
      isBinded: true,
    },
    {
      id: 323,
      label: 'Пивная корова',
      address: 'Улица адреса, дом адреса',
      isBinded: true,
    },
    {
      id: 324,
      label: 'Пивная корова',
      address: 'Улица адреса, дом адреса',
      isBinded: false,
    },
    {
      id: 325,
      label: 'Пивная корова',
      address: 'Улица адреса, дом адреса',
      isBinded: false,
    },
  ];

  const [data, setData] = useState(initialData);

  const handleSelectAll = () => {
    const allSelected = data.every((item) => item.isBinded);
    setData(
      data.map((item) => ({
        ...item,
        isBinded: !allSelected,
      }))
    );
  };

  const handleChange = (id) => {
    setData(
      data.map((item) =>
        item.id === id ? {...item, isBinded: !item.isBinded} : item
      )
    );
  };

  return (
    <Dialog
      maxWidth={'sm'}
      fullWidth={false}
      scroll={Utils.isDeviceMobile() ? 'paper' : 'body'}
      classes={{root: 'modal-styles-root'}}
      onClose={onClose}
      open={open}
    >
      <div className={cl.modalWrapper}>
        <div className={cl.modalHeader}>
          <div className={cl.modalTitle}>Привяз-клие</div>

          <IconButton onClick={() => onClose()}>
            <CloseRoundedIcon />
          </IconButton>
        </div>

        <div className={cl.modalBody}>
          <FormControl fullWidth>
            {data.filter((item) => item.isBinded).length === 0 && (
              <InputLabel>Нет привязанных клиентов</InputLabel>
            )}

            <Select
              multiple
              value={data
                .filter((item) => item.isBinded)
                .map((item) => item.label)}
              renderValue={(selected) =>
                `Привязано ${selected.length} клиентов`
              }
              sx={{
                borderRadius: '16px',
                border: '1px solid #ededed',
                '& fieldset': {
                  border: 'transparent',
                },
                '&:hover fieldset': {
                  border: 'transparent',
                },
                '&.Mui-focused fieldset': {
                  border: 'transparent',
                },
              }}
            >
              <MenuItem value='all'>
                <ListItemText
                  primary='Выбрать / отменить все'
                  onClick={handleSelectAll}
                />
              </MenuItem>

              {data.map((item) => (
                <MenuItem key={item.id} value={item.label}>
                  <Checkbox
                    checked={item.isBinded}
                    onChange={() => handleChange(item.id)}
                  />
                  <ListItemText primary={item.label} secondary={item.address} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <button className={cl.actionRebindAll}>Отвязать всех</button>
        </div>

        <div className={cl.modalAction}>
          <button className={cl.modalSaveAction}>Сохранить привязки</button>
        </div>
      </div>
    </Dialog>
  );
}

function CreateBeerCardModal({open, onClose, onSubmit}) {
  const [searchLibraries, setSearchLibraries] = useState('');
  const [libraryItems, setLibraryItems] = useState(null);

  //Стейты для инпутов
  const [name, setName] = useState(null);
  const [brewery, setBrewery] = useState(null);
  const [beer_sort, setBeer_sort] = useState(null);
  const [alcohol_degrees, setAlcohol_degrees] = useState(null);
  const [alcohol_density, setAlcohol_density] = useState(null);
  const [bitterness_scale, setBitterness_scale] = useState(null);
  const [price, setPrice] = useState(null);
  const [beer_color, setBeer_color] = useState(null);
  const [filtered, setFiltered] = useState(null);
  const [image, setImage] = useState({image: null});

  //Хуй знает почему это не переопределяется, но в бд есть
  const [pasteurized, setPasteurized] = useState(null);
  const [attributes, setAtributes] = useState(null);
  const [description, setDescription] = useState(null);

  const fileInputChange = (e) => {
    const target = e.target;

    if (target.files && target.files[0]) {
      const file = target.files[0];
      const reader = new FileReader();

      reader.onload = (event) => {
        const base64String = event.target.result;

        document.getElementById('image').src = base64String;

        setImage({image: base64String});
      };

      reader.readAsDataURL(file);
    }
  };

  const getFilteredLibraryItems = () => {
    if (searchLibraries) {
      const names = Object.keys(libraryItems);

      const recs = names
        .map((item) => ({
          item,
          distance: levenshteinDistance(
            searchLibraries.toLowerCase(),
            item.toLowerCase()
          ),
          match: item.toLowerCase().includes(searchLibraries.toLowerCase()),
        }))
        .sort((a, b) => a.distance - b.distance)
        .sort((a, b) => b.match - a.match)
        .map((item) => item.item);

      return recs;
    }

    return Object.keys(libraryItems);
  };

  const setTemplateData = (menuItemId) => {
    API.libraryData({templateId: menuItemId}).then((data) => {
      console.log(data);
      // todo обновить все стейты инпутов + убрать значение из поиска searchLibraries
      setName(data.template.name);
      setBrewery(data.template.brewery);
      setBeer_sort(data.template.beer_sort);
      setAlcohol_degrees(data.template.alcohol_degrees);
      setAlcohol_density(data.template.alcohol_density);
      setBitterness_scale(data.template.bitterness_scale);
      setPrice(data.template.price);
      setBeer_color(data.template.beer_color);
      setFiltered(data.template.filtered);
      setImage(data.template.image);
      setPasteurized(data.template.pasteurized);
      setAtributes(data.template.attributes);
      setDescription(data.template.description);
    });
  };

  useEffect(() => {
    API.libraryInstances().then((data) => {
      const names = {};

      data.templates.forEach((element) => {
        names[element.name] = element.id;
      });

      setLibraryItems(names);
    });
  }, []);

  return (
    <Dialog
      scroll={Utils.isDeviceMobile() ? 'paper' : 'body'}
      classes={{root: 'customer-dashboard-modal-add-product'}}
      open={open}
      onClose={() => onClose()}
      maxWidth={'sm'}
      fullWidth={false}
    >
      <div className='modal-edit-product-title-container'>
        <div className='modal-edit-product-title'>Добавить позицию</div>

        <IconButton onClick={() => onClose()}>
          <CloseRoundedIcon />
        </IconButton>
      </div>

      <div className='modal-edit-product-inputs-container'>
        <TextField
          margin='dense'
          id='searchLibraryValue'
          name='searchLibraryValue'
          label='Поиск товара в библиотеке'
          type='text'
          fullWidth
          variant='outlined'
          value={safetyInput(searchLibraries)}
          onInput={(e) => setSearchLibraries(e.target.value)}
        />

        <div className='modal-edit-product-recs'>
          {libraryItems
            ? getFilteredLibraryItems().map((name, index) => {
                return (
                  <div
                    key={index}
                    className='modal-edit-product-recs-element'
                    onClick={() => setTemplateData(libraryItems[name])}
                  >
                    {name}
                  </div>
                );
              })
            : ''}
        </div>
      </div>

      <div className='modal-edit-product-inputs-container'>
        <TextField
          margin='dense'
          id='name'
          name='name'
          label='Название'
          type='text'
          fullWidth
          variant='outlined'
          value={safetyInput(name)}
          onInput={(e) => setName(e.target.value)}
        />

        <TextField
          margin='dense'
          id='beerBrewery'
          name='beerBrewery'
          label='Пивоварня'
          type='text'
          fullWidth
          variant='outlined'
          value={safetyInput(brewery)}
          onInput={(e) => setBrewery(e.target.value)}
        />

        <TextField
          margin='dense'
          id='beerSort'
          name='beerSort'
          label='Сорт'
          type='text'
          fullWidth
          variant='outlined'
          value={safetyInput(beer_sort)}
          onInput={(e) => setBeer_sort(e.target.value)}
        />
      </div>

      <div className='modal-edit-product-horizontal-cells'>
        <div className='modal-edit-product-horizontal-cell'>
          <div className='modal-edit-product-horizontal-cell-card'>
            <div className='modal-edit-product-horizontal-cell-card-title'>
              Алкоголь
            </div>
          </div>

          <div className='modal-edit-product-horizontal-cell-input'>
            <TextField
              margin='dense'
              id='alcohol'
              name='alcohol'
              type='number'
              fullWidth
              variant='outlined'
              value={safetyInput(alcohol_degrees)}
              onInput={(e) => setAlcohol_degrees(e.target.value)}
            />
          </div>
        </div>

        <div className='modal-edit-product-horizontal-cell'>
          <div className='modal-edit-product-horizontal-cell-card'>
            <div className='modal-edit-product-horizontal-cell-card-title'>
              Плотность
            </div>
          </div>

          <div className='modal-edit-product-horizontal-cell-input'>
            <TextField
              margin='dense'
              id='density'
              name='density'
              type='number'
              fullWidth
              variant='outlined'
              value={safetyInput(alcohol_density)}
              onInput={(e) => setAlcohol_density(e.target.value)}
            />
          </div>
        </div>

        <div className='modal-edit-product-horizontal-cell'>
          <div className='modal-edit-product-horizontal-cell-card'>
            <div className='modal-edit-product-horizontal-cell-card-title'>
              IBU
            </div>
          </div>

          <div className='modal-edit-product-horizontal-cell-input'>
            <TextField
              margin='dense'
              id='bitterness_scale'
              name='bitterness_scale'
              type='number'
              fullWidth
              variant='outlined'
              value={safetyInput(bitterness_scale)}
              onInput={(e) => setBitterness_scale(e.target.value)}
            />
          </div>
        </div>
      </div>

      <div className='modal-edit-product-inputs-container'>
        <TextField
          margin='dense'
          id='price'
          name='price'
          label='Рублей за литр'
          type='number'
          fullWidth
          variant='outlined'
          value={safetyInput(price)}
          onInput={(e) => setPrice(e.target.value)}
        />
      </div>

      <div className='modal-edit-product-radio-buttons-container'>
        <MultiRadioButton
          title='Цвет пива'
          values={beerColorValues}
          selected={beer_color}
          selectValue={(value) => setBeer_color(value)}
        />

        <MultiRadioButton
          title='Фильтрованное'
          values={filteredBeerValues}
          selected={filtered}
          selectValue={(value) => setFiltered(value)}
        />
      </div>

      <div className='modal-edit-product-image-container'>
        <InputLabel>Изображение</InputLabel>

        <div className='customer-dashboard-modal-add-product-file-preview'>
          {image.image ? (
            <img
              alt='preview'
              src={image.url ? SERVER_URL + image.url : image.image}
            />
          ) : null}
        </div>

        <Button
          component='label'
          role={undefined}
          variant='contained'
          tabIndex={-1}
        >
          Загрузить изображение
          <VisuallyHiddenInput
            id='image'
            name='image'
            type='file'
            accept='image/*'
            onChange={(e) => fileInputChange(e)}
          />
        </Button>
      </div>

      <div className='modal-edit-product-bottom-buttons'>
        <Button
          classes={{root: 'modal-edit-product-bottom-button-save'}}
          component='label'
          role={undefined}
          variant='contained'
          tabIndex={-1}
          onClick={() =>
            onSubmit({
              name,
              brewery,
              beer_sort,
              attributes,
              description,
              beer_color,
              image,
              alcohol_degrees: isNaN(alcohol_degrees) ? null : +alcohol_degrees,
              alcohol_density: isNaN(alcohol_density) ? null : +alcohol_density,
              bitterness_scale: isNaN(alcohol_density)
                ? null
                : +alcohol_density,
              price: isNaN(price) ? null : +price,
              filtered: ['false', 'true'].includes(filtered)
                ? JSON.parse(filtered)
                : null,
              pasteurized: ['false', 'true'].includes(pasteurized)
                ? JSON.parse(pasteurized)
                : null,
            })
          }
        >
          Добавить
        </Button>

        <Button
          classes={{root: 'modal-edit-product-bottom-button-cancel'}}
          component='label'
          role={undefined}
          variant='contained'
          tabIndex={-1}
          onClick={() => onClose()}
        >
          Отменить
        </Button>
      </div>
    </Dialog>
  );
}

function BeerCardEditorModal({open, onClose, beerData, onSubmit, updateBeersData}) {
  //Стейты для инпутов
  const [position_id, setId] = useState('');
  const [name, setName] = useState('');
  const [brewery, setBrewery] = useState('');
  const [beer_sort, setBeer_sort] = useState('');
  const [alcohol_degrees, setAlcohol_degrees] = useState('');
  const [alcohol_density, setAlcohol_density] = useState('');
  const [bitterness_scale, setBitterness_scale] = useState('');
  const [price, setPrice] = useState('');
  const [beer_color, setBeer_color] = useState(null);
  const [filtered, setFiltered] = useState(null);
  const [image, setImage] = useState({image: null});

  const fileInputChange = (e) => {
    const target = e.target;

    if (target.files && target.files[0]) {
      const file = target.files[0];
      const reader = new FileReader();

      reader.onload = (event) => {
        const base64String = event.target.result;

        document.getElementById('image').src = base64String;

        setImage({image: base64String});
      };

      reader.readAsDataURL(file);
    }
  };

  const onRemoveBeer = () => {
    BeerActionsService.onRemoveBeer(beerData.id)
      .then(() => {
        updateBeersData();
        onClose();
      })
      .catch((e) => alert(`Вместо того чтобы удалить, бакенд сказал это ${e}`));
  };

  useEffect(() => {
    setId(beerData?.id ?? '')
    setName(beerData?.name ?? '');
    setBrewery(beerData?.brewery ?? '');
    setBeer_sort(beerData?.beer_sort ?? '');
    setAlcohol_degrees(beerData?.alcohol_degrees ?? '');
    setAlcohol_density(beerData?.alcohol_density ?? '');
    setBitterness_scale(beerData?.bitterness_scale ?? '');
    setPrice(beerData?.price ?? '');
    setBeer_color(beerData?.beer_color ?? null);
    setFiltered(beerData?.filtered ?? null);
    setImage(beerData?.image ?? {image: null});
  }, [beerData]);

  return (
    <Dialog
      scroll={Utils.isDeviceMobile() ? 'paper' : 'body'}
      classes={{root: 'customer-dashboard-modal-add-product'}}
      open={open}
      onClose={() => onClose()}
      maxWidth={'sm'}
      fullWidth={false}
    >
      <div className='modal-edit-product-title-container'>
        <div className='modal-edit-product-title'>Редактор позиции</div>

        <IconButton onClick={() => onClose()}>
          <CloseRoundedIcon />
        </IconButton>
      </div>

      <div className='modal-edit-product-inputs-container'>
        <TextField
          margin='dense'
          id='name'
          name='name'
          label='Название'
          type='text'
          fullWidth
          variant='outlined'
          value={safetyInput(name)}
          onInput={(e) => setName(e.target.value)}
        />

        <TextField
          margin='dense'
          id='beerBrewery'
          name='beerBrewery'
          label='Пивоварня'
          type='text'
          fullWidth
          variant='outlined'
          value={safetyInput(brewery)}
          onInput={(e) => setBrewery(e.target.value)}
        />

        <TextField
          margin='dense'
          id='beerSort'
          name='beerSort'
          label='Сорт'
          type='text'
          fullWidth
          variant='outlined'
          value={safetyInput(beer_sort)}
          onInput={(e) => setBeer_sort(e.target.value)}
        />
      </div>

      <div className='modal-edit-product-horizontal-cells'>
        <div className='modal-edit-product-horizontal-cell'>
          <div className='modal-edit-product-horizontal-cell-card'>
            <div className='modal-edit-product-horizontal-cell-card-title'>
              Алкоголь
            </div>
          </div>

          <div className='modal-edit-product-horizontal-cell-input'>
            <TextField
              margin='dense'
              id='alcohol'
              name='alcohol'
              type='number'
              fullWidth
              variant='outlined'
              value={safetyInput(alcohol_degrees)}
              onInput={(e) => setAlcohol_degrees(e.target.value)}
            />
          </div>
        </div>

        <div className='modal-edit-product-horizontal-cell'>
          <div className='modal-edit-product-horizontal-cell-card'>
            <div className='modal-edit-product-horizontal-cell-card-title'>
              Плотность
            </div>
          </div>

          <div className='modal-edit-product-horizontal-cell-input'>
            <TextField
              margin='dense'
              id='density'
              name='density'
              type='number'
              fullWidth
              variant='outlined'
              value={safetyInput(alcohol_density)}
              onInput={(e) => setAlcohol_density(e.target.value)}
            />
          </div>
        </div>

        <div className='modal-edit-product-horizontal-cell'>
          <div className='modal-edit-product-horizontal-cell-card'>
            <div className='modal-edit-product-horizontal-cell-card-title'>
              IBU
            </div>
          </div>

          <div className='modal-edit-product-horizontal-cell-input'>
            <TextField
              margin='dense'
              id='bitterness_scale'
              name='bitterness_scale'
              type='number'
              fullWidth
              variant='outlined'
              value={safetyInput(bitterness_scale)}
              onInput={(e) => setBitterness_scale(e.target.value)}
            />
          </div>
        </div>
      </div>

      <div className='modal-edit-product-inputs-container'>
        <TextField
          margin='dense'
          id='price'
          name='price'
          label='Рублей за литр'
          type='number'
          fullWidth
          variant='outlined'
          value={safetyInput(price)}
          onInput={(e) => setPrice(e.target.value)}
        />
      </div>

      <div className='modal-edit-product-radio-buttons-container'>
        <MultiRadioButton
          title='Цвет пива'
          values={beerColorValues}
          selected={beer_color}
          selectValue={(value) => setBeer_color(value)}
        />

        <MultiRadioButton
          title='Фильтрованное'
          values={filteredBeerValues}
          selected={filtered}
          selectValue={(value) => setFiltered(value)}
        />
      </div>

      <div className='modal-edit-product-image-container'>
        <InputLabel>Изображение</InputLabel>

        <div className='customer-dashboard-modal-add-product-file-preview'>
          {image ? (
            <img
              alt='preview'
              src={image.url ? SERVER_URL + image.url.split('?')[0] : image.image}
            />
          ) : null}
        </div>

        <Button
          component='label'
          role={undefined}
          variant='contained'
          tabIndex={-1}
        >
          Загрузить изображение
          <VisuallyHiddenInput
            id='image'
            name='image'
            type='file'
            accept='image/*'
            onChange={(e) => fileInputChange(e)}
          />
        </Button>
      </div>

      <div className='modal-edit-product-bottom-buttons'>
        <Button
          classes={{root: 'modal-edit-product-bottom-button-save'}}
          component='label'
          role={undefined}
          variant='contained'
          tabIndex={-1}
          onClick={() =>
            onSubmit({
              id: position_id,
              name,
              brewery,
              beer_sort,
              alcohol_degrees: isNaN(alcohol_degrees) ? null : +alcohol_degrees,
              alcohol_density: isNaN(alcohol_density) ? null : +alcohol_density,
              bitterness_scale: isNaN(alcohol_density)
                ? null
                : +alcohol_density,
              price: isNaN(price) ? null : +price,
              filtered: ['false', 'true'].includes(filtered)
                ? JSON.parse(filtered)
                : null,
              beer_color,
              image,
            })
          }
        >
          Сохранить
        </Button>

        <Button
          classes={{root: 'modal-edit-product-bottom-button-cancel'}}
          component='label'
          role={undefined}
          variant='contained'
          tabIndex={-1}
          onClick={() => onClose()}
        >
          Отменить
        </Button>
      </div>

      <div
        className='modal-edit-product-bottom-last'
        onClick={() => onRemoveBeer()}
      >
        <Button variant='text'>Удалить</Button>
      </div>
    </Dialog>
  );
}

function BeerDbEditorComponent() {
  const [beers, setBeers] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');
  //   const [selectedBeerInfo, setSelectedBeerInfo] = useState(null); нахуй надо уже не помню
  const [selectedBindInfo, setSelectedBindInfo] = useState(null);
  const [beerData, setBeerData] = useState(null);

  //Modals
  const [openBind, setOpenBind] = useState(false);
  const [openNewPositionDialog, setOpenNewPositionDialog] = useState(false);
  const [openBeerEditor, setOpenBeerEditor] = useState(false);

  const onOpenBeerEditorDialog = (data) => {
    setBeerData(data);
    setOpenBeerEditor(true);
  };

  const onCloseBeerEditorDialog = () => {
    setOpenBeerEditor(false);
    setBeerData(null);
  };

  const onOpenNewPositionDialog = () => {
    setOpenNewPositionDialog(true);
  };

  const onCloseNewPositionDialog = () => {
    setOpenNewPositionDialog(false);
  };

  const openBindDialog = (someBeerInfo) => {
    setSelectedBindInfo(someBeerInfo);
    setOpenBind(true);
  };

  const onCloseBindDialog = () => {
    setOpenBind(false);
  };

  const sendCreating = (form) => {
    BeerActionsService.createBeer(form).then(() => {
      updateBeersData();
      onCloseNewPositionDialog();
    });
  };

  const sendUpdating = (data) => {
    BeerActionsService.saveChangedMenuItem(data).then(() => {
      updateBeersData();
      onCloseBeerEditorDialog();
    });
  };

  const updateBeersData = () => {
    BeerInfoService.getBeers(currentPage, searchTerm).then((res) => {
      if (res.total > 0 && res.menu_items.length > 0) {
        console.log(currentPage, searchTerm, res);
        setTotalPages(Math.ceil(res.total / res.per_page));
        setBeers(res.menu_items);
      } else {
        setTotalPages(1);
        setBeers([]);
      }
    });
  };

  useEffect(() => {
    updateBeersData();
  }, [currentPage, searchTerm]);

  return (
    <div className={cl.pageWrapper}>
      <CreateBeerCardModal
        open={openNewPositionDialog}
        onClose={onCloseNewPositionDialog}
        onSubmit={sendCreating}
      />

      <BeerCardEditorModal
        open={openBeerEditor}
        onClose={onCloseBeerEditorDialog}
        beerData={beerData}
        onSubmit={sendUpdating}
        updateBeersData={updateBeersData}
      />

      <BindClientsModal open={openBind} onClose={onCloseBindDialog} />

      <div className={cl.header}>
        <span className={cl.headerTittle}>Редактор базы пива</span>

        <button disabled={true} className={cl.mainAction}>
          Синхронизировать привязки
        </button>
      </div>

      <div className={cl.searchWrapper}>
        <button
          onClick={() => onOpenNewPositionDialog()}
          className={cl.actionAddPosirion}
        >
          Добавить позицию
        </button>

        <TextField
          label='Поиск'
          variant='outlined'
          fullWidth
          value={searchTerm}
          onChange={(e) => {
            setCurrentPage(1);
            setSearchTerm(e.target.value);
          }}
          sx={{
            backgroundColor: '#ffffff',
            borderRadius: '16px',
            border: 'transparent',
            '& fieldset': {
              border: 'transparent',
            },
            '&:hover fieldset': {
              border: 'transparent',
            },
            '&.Mui-focused fieldset': {
              border: 'transparent',
            },
          }}
        />
      </div>

      <div className={cl.beersWrapper}>
        {beers.length > 0 ? (
          beers.map((beer, index) => {
            return (
              <BeerCard
                key={index}
                beer={beer}
                openBindDialog={openBindDialog}
                onOpenBeerEditorDialog={onOpenBeerEditorDialog}
              />
            );
          })
        ) : (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginTop: '20px',
            }}
          >
            <CircularProgress />
          </Box>
        )}
      </div>

      <Box sx={{display: 'flex', justifyContent: 'center', marginTop: 4}}>
        <Pagination
          count={totalPages}
          page={currentPage}
          onChange={(_, value) => setCurrentPage(value)}
          color='primary'
        />
      </Box>
    </div>
  );
}

export default BeerDbEditorComponent;
