import {delete_, get, post, url} from '../../../../../../utils/request';

export default class RequestsService {
  static async getRequests(search_query, page, per_page = 5) {
    const response = await get(
      url(
        `/api/admin/requests/list/?search_query=${search_query}&page=${page}&per_page=${per_page}`
      )
    );

    return response;
    // return Promise.resolve(MOCK_DATA);
  }

  static async getStatuses() {
    const response = await get(url('/api/admin/requests/statuses/'));

    return response;
  }

  static async setStatusForRequest(id, status_id) {
    const params = {id, status_id};

    const resolve = await post(
      url('/api/admin/requests/entity/status/'),
      params
    );

    return resolve;
  }

  static async addCommentById(id, comment) {
    const params = {id, comment};

    const resolve = await post(
      url('/api/admin/requests/entity/comment/add/'),
      params
    );

    return resolve;
  }

  static async createNewRequest(store_name, shops, contact) {
    const params = {store_name, shops, contact};

    const resolve = await post(url('/api/admin/requests/entity/add/'), params);

    return resolve;
  }

  static async deleteCommentById(comment_id) {
    const resolve = await delete_(
      url(`/api/admin/requests/entity/comment/delete/?comment_id=${comment_id}`)
    );

    return resolve;
  }
}
