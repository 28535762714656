import {get, url} from '../../../../../../utils/request';

export default class BeerInfoService {
  static async getBeers(page = 1, search_query = '', per_page = 20) {
    const response = await get(
      url('/api/admin/base/editor/list/', {search_query, page, per_page})
    );

    return response;
  }
}
