import {post, url} from '../../../../../../utils/request';

export default class BeerActionsService {
  static async createBeer(newBeer) {
    let genericSelect = {
      true: true,
      false: false,
      null: null,
    };

    newBeer.pasteurized = genericSelect[newBeer.pasteurized];
    newBeer.filtered = genericSelect[newBeer.filtered];

    const response = await post(
      url('/api/admin/base/editor/menuitem/creating/save/'),
      {menu_item: newBeer}
    );

    return response;
  }

  static async onRemoveBeer(menuItemId) {
    const data = {
      menu_item_id: menuItemId,
    };

    const response = await post(
      url('/api/admin/base/editor/menuitem/entity/delete/'),
      data
    );

    return response;
  }

  static async saveChangedMenuItem(menuItem) {
    const response = await post(
      url('/api/admin/base/editor/menuitem/changed/save/'),
      {
        menu_item: menuItem,
      }
    );

    return response;
  }
}
