import React from 'react';
import '../style/modalEditProduct.css';
import '../style/customerDashboard.css';
import {
    Avatar,
    ButtonGroup,
    CircularProgress,
    FormControl,
    IconButton,
    InputBase,
    InputLabel,
    Menu,
    MenuItem,
    Select,
    Switch,
} from '@mui/material';
import {ReactComponent as SettingsIcon} from '../images/settings.svg';
import {ReactComponent as ArrowSelectIcon} from '../images/arrowSelect.svg';
import {ReactComponent as AddPlusGreenIcon} from '../images/addPlusGreen.svg';
import {ReactComponent as CardsTypeIcon} from '../images/cardsType.svg';
import {ReactComponent as ListTypeIcon} from '../images/listType.svg';
import {ReactComponent as MissingIcon} from '../images/missing.svg';
import {ReactComponent as ClockIcon} from '../images/clock.svg';
import SearchIcon from '@mui/icons-material/Search';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import {styled} from '@mui/material/styles';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import API from '../api';
import Storage from '../utils/storage';
import Utils, {getComparator, safetyInput, stableSort} from './utils';
import ButtonComponent from './components/buttonComponent';
import MultiRadioButton from './components/multiRadioButton';
import HeaderMenu from './components/headerMenu';
import MenuSelectElement from './components/menuSelectElement';
import {SERVER_URL} from '../config';
import PositionDialogComponent from './components/position-dialog/position-dialog.component';
import BeerEndedDialogComponent from './components/beer-ended-dialog/beer-ended-dialog.component';

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});

const levenshteinDistance = (a, b) => {
    const c = a.length + 1;
    const d = b.length + 1;
    const r = Array(c);
    for (let i = 0; i < c; ++i) r[i] = Array(d);
    for (let i = 0; i < c; ++i) r[i][0] = i;
    for (let j = 0; j < d; ++j) r[0][j] = j;
    for (let i = 1; i < c; ++i) {
        for (let j = 1; j < d; ++j) {
            const s = a[i - 1] === b[j - 1] ? 0 : 1;
            r[i][j] = Math.min(r[i - 1][j] + 1, r[i][j - 1] + 1, r[i - 1][j - 1] + s);
        }
    }
    return r[a.length][b.length];
};

function renderWaitLabel(contacts) {
    return (
        <div className='customer-dashboard-container'>
            <div className='customer-dashboard-wait-label-layer'>
                <div className='customer-dashboard-wait-label-container'>
                    <h1 className='customer-dashboard-logo'>
                        PubHub <span className='styled-label'>Menus</span>
                    </h1>
                    <div className='customer-dashboard-wait-label-title'>
                        Магазин в процессе создания
                    </div>
                    <div className='customer-dashboard-wait-label-text'>
                        Создание вашего магазина уже находится в процессе.
                        <br/>
                        Мы делаем все возможное, чтобы все было готово как можно скорее.
                        <br/>
                        Спасибо за ваше терпение и поддержку.
                        <br/>
                        Мы обязательно сообщим вам, как только все будет готово к запуску.
                        <br/>
                    </div>
                    <div className='customer-dashboard-wait-label-help'>
                        <div className='customer-dashboard-wait-label-help-title'>
                            Контакты:
                        </div>
                        <div className='customer-dashboard-wait-label-help-field'>
                            Email: <a href={`mailto:${contacts.email}`}>{contacts.email}</a>
                        </div>
                        <div className='customer-dashboard-wait-label-help-field'>
                            Телефон: <a href={`tel:${contacts.phone}`}>{contacts.phone}</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

function renderShop(
    owner,
    setAddProductsOpen,
    setShopSettingsOpen,
    setManyMenuOpen,
    manyMenuOpen,
    stores,
    profileMenuOpen,
    setProfileMenuOpen,
    setContentSettingsOpen,
    setCranesOpen,
    isBlocked
) {
    let channelUrl = (windowNumber) => {
        return `/store/window/${windowNumber}/channel/${owner.store.web_key}/`;
    };

    let elementHandler = (store) => {
        Storage.set('selectedStoreId', store.id);
        window.location.reload();
    };

    stores = stores.filter((element) => element.id !== owner.store.id);
    stores = stores.map((store) => {
        store.title = store.name;
        store.label = store.address;
        return store;
    });

    let user = Storage.get('user', {});

    let buttons = [
        {
            id: 'account',
            title: 'Аккаунт и подписка',
            label: 'Перейти в раздел настроек аккаунта',
            href: '/account/',
        },
        {
            id: 'manager',
            title: 'Связаться с менеджером',
            label: 'Контакт с менеджером по любым вопросам',
            href: 'https://t.me/blackllyy',
        },
        // {
        //     id: 'support', title: 'Поддержка', label: 'Раздел с вопросами и ответами',
        //     href: '/support/'
        // },
        {
            id: 'logout',
            title: 'Выйти',
            label: 'Выйти из аккаунта',
            href: '/logout/',
        },
    ];

    let buttonHandler = (button) => {
        window.location.href = button.href;
    };

    return (
        <div id='shops-element' className='customer-dashboard-content-shop'>
            <MenuSelectElement
                isMenuOpen={manyMenuOpen}
                mainTitle={owner.store.name}
                mainLabel={owner.store.address}
                setMenuOpen={setManyMenuOpen}
                icon={<SettingsIcon/>}
                iconHandler={() => setShopSettingsOpen(true)}
                simpleElementsText={'Другие точки'}
                elements={stores}
                elementHandler={elementHandler}
                extraElement={
                    <a
                        className='customer-dashboard-content-shop-left-add-new-shop customer-dashboard-content-shop-left-other-shop'
                        href='https://t.me/blackllyy'
                        target='_blank'
                        rel='noreferrer'>
                        <div className='customer-dashboard-content-shop-left-add-new-shop-avatar'>
                            <AddPlusGreenIcon/>
                        </div>
                        <div className='customer-dashboard-content-shop-left-content'>
                            <div className='customer-dashboard-content-shop-name'>
                                Хотите добавить ещё точку?
                            </div>
                            <div className='customer-dashboard-content-shop-address'>
                                Написать менеджеру в телеграм
                            </div>
                        </div>
                    </a>
                }
            />
            <div className='customer-dashboard-content-shop-right'>
                {/*<ButtonComponent*/}
                {/*  className='customer-header-button-component'*/}
                {/*  label='Настроить краны'*/}
                {/*  onClick={() => setCranesOpen(true)}*/}
                {/*/>*/}
                <ButtonComponent
                    className='customer-header-button-component'
                    label='Пиво закончилось!'
                    onClick={() => setContentSettingsOpen(true)}
                    disabled={isBlocked}
                />
                <ButtonComponent
                    className='customer-header-button-component'
                    label='Конструктор отображения'
                    href="/editor"
                    target={null}
                    disabled={isBlocked}
                />
                <div className='customer-dashboard-content-shop-right-windows'>
                    <ButtonComponent
                        className='customer-header-button-component'
                        href={channelUrl(1)}
                        label='1'
                    />
                    <ButtonComponent
                        className='customer-header-button-component'
                        href={channelUrl(2)}
                        label='2'
                    />
                    <ButtonComponent
                        className='customer-header-button-component'
                        href={channelUrl(3)}
                        label='3'
                    />
                </div>
                <MenuSelectElement
                    isMenuOpen={profileMenuOpen}
                    mainTitle={`${user.first_name} ${user.last_name}`}
                    mainLabel={'Профиль'}
                    setMenuOpen={setProfileMenuOpen}
                    elements={buttons}
                    elementHandler={buttonHandler}
                    width={280}
                    arrowSide='right'
                />
            </div>
        </div>
    );
}

const SortingFields = [
    {value: 'name', label: 'Название'},
    {value: 'tap_number', label: 'Номер крана'},
    {value: 'beer_color', label: 'Цвет пива'},
    {value: 'alcohol_degrees', label: 'Алкоголь'},
    {value: 'alcohol_density', label: 'Плотность'},
    {value: 'price', label: 'Рублей за литр'},
    {value: 'filtered', label: 'Фильтрованное'},
];

function renderNavigation(
    displayType,
    setDisplayType,
    setFilter,
    orderBy,
    order,
    setFiltersOpen,
    filtersOpen,
    filtersElement,
    setSortingOpen,
    sortingOpen,
    sortingElement,
    setAddProductsOpen,
    setEditAll,
    editAll,
    dashboardSortType,
    setDashboardSortType,
    searchValue,
    setSearchValue
) {
    const activeClassName = 'customer-dashboard-navigation-display-type-active';
    const isCards = displayType === 'cards';
    const isList = displayType === 'list';
    let classList = [
        'customer-dashboard-navigation-button-component',
        'customer-dashboard-navigation-button-edit',
    ];
    if (editAll) classList.push('customer-dashboard-navigation-button-edit-all');
    let classNameButtonEdit = classList.join(' ');

    return (
        <div className='customer-dashboard-navigation-container'>
            <div className='customer-dashboard-navigation-row'>
                <ButtonComponent
                    className='customer-header-button-component customer-header-button-add'
                    label='Добавить позицию'
                    onClick={() => setAddProductsOpen(true)}
                />
                <div className='customer-dashboard-navigation-search-container'>
                    <IconButton sx={{p: '10px'}} aria-label='search'>
                        <SearchIcon/>
                    </IconButton>
                    <InputBase
                        sx={{ml: 1, flex: 1}}
                        placeholder='Поиск'
                        inputProps={{'aria-label': 'search google maps'}}
                        value={searchValue}
                        onInput={(e) => setSearchValue(e.target.value)}
                    />
                </div>
                {isCards ? (
                    <>
                        {/*<div className="customer-dashboard-navigation-filters-container">*/}
                        {/*    <div>*/}
                        {/*        <Button*/}
                        {/*            id="filters-button"*/}
                        {/*            aria-controls={filtersOpen ? 'filters-menu' : undefined}*/}
                        {/*            aria-haspopup="true"*/}
                        {/*            aria-expanded={filtersOpen ? 'true' : undefined}*/}
                        {/*            onClick={(event) => setFiltersOpen(true, event.currentTarget)}*/}
                        {/*            endIcon={<FilterIcon/>}*/}
                        {/*        >*/}
                        {/*            Фильтры*/}
                        {/*        </Button>*/}
                        {/*        <Menu*/}
                        {/*            id="filters-menu"*/}
                        {/*            aria-labelledby="filters-button"*/}
                        {/*            anchorEl={filtersElement}*/}
                        {/*            open={filtersOpen}*/}
                        {/*            onClose={() => setFiltersOpen(false)}*/}
                        {/*            anchorOrigin={{*/}
                        {/*                vertical: 'top',*/}
                        {/*                horizontal: 'center',*/}
                        {/*            }}*/}
                        {/*            transformOrigin={{*/}
                        {/*                vertical: 'top',*/}
                        {/*                horizontal: 'center',*/}
                        {/*            }}*/}
                        {/*        >*/}
                        {/*            <MenuItem onClick={() => setFiltersOpen(false)}>Светлое</MenuItem>*/}
                        {/*            <MenuItem onClick={() => setFiltersOpen(false)}>Темное</MenuItem>*/}
                        {/*            <MenuItem onClick={() => setFiltersOpen(false)}>Крепкое</MenuItem>*/}
                        {/*            <MenuItem onClick={() => setFiltersOpen(false)}>Фильтрованное</MenuItem>*/}
                        {/*        </Menu>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                        <div className='customer-dashboard-navigation-sorting-mini-container'>
                            <div>
                                <Button
                                    id='sorting-button'
                                    aria-controls={sortingOpen ? 'sorting-menu' : undefined}
                                    aria-haspopup='true'
                                    aria-expanded={sortingOpen ? 'true' : undefined}
                                    onClick={(event) => setSortingOpen(true, event.currentTarget)}
                                    endIcon={
                                        <ArrowSelectIcon style={{transform: 'rotate(180deg)'}}/>
                                    }>
                                    {dashboardSortType === 'number'
                                        ? 'По номеру кранов'
                                        : 'По типу пива'}
                                </Button>
                                <Menu
                                    id='sorting-menu'
                                    aria-labelledby='sorting-button'
                                    anchorEl={sortingElement}
                                    open={sortingOpen}
                                    onClose={() => setSortingOpen(false)}
                                    anchorOrigin={{
                                        vertical: 'top',
                                        horizontal: 'center',
                                    }}
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'center',
                                    }}>
                                    <MenuItem
                                        onClick={() => {
                                            setDashboardSortType('number');
                                            setSortingOpen(false);
                                        }}>
                                        По номеру кранов
                                    </MenuItem>
                                    <MenuItem
                                        onClick={() => {
                                            setDashboardSortType('beer_type');
                                            setSortingOpen(false);
                                        }}>
                                        По типу пива
                                    </MenuItem>
                                </Menu>
                            </div>
                        </div>
                    </>
                ) : null}
                <div className='customer-dashboard-navigation-display-types'>
                    <ButtonGroup variant='contained' aria-label='Disabled button group'>
                        <Button
                            classes={{root: isCards ? activeClassName : ''}}
                            onClick={() => setDisplayType('cards')}>
                            <CardsTypeIcon/>
                        </Button>
                        <Button
                            classes={{root: isList ? activeClassName : ''}}
                            onClick={() => setDisplayType('list')}>
                            <ListTypeIcon/>
                        </Button>
                    </ButtonGroup>
                </div>
            </div>
            {isList ? (
                <div className='customer-dashboard-navigation-row'>
                    <div className='customer-dashboard-navigation-sorting-container'>
                        {/*<div className="customer-dashboard-navigation-sorting-title">Сортировка</div>*/}
                        {SortingFields.map((field, index) => {
                            let classList = ['customer-dashboard-navigation-sorting-field'];
                            const isActive = orderBy === field.value;
                            if (isActive)
                                classList.push(
                                    'customer-dashboard-navigation-sorting-field-active'
                                );
                            let className = classList.join(' ');
                            let arrowElement = null;
                            if (order === 'asc')
                                arrowElement = (
                                    <div style={{transform: 'rotate(180deg)'}}>
                                        <ArrowSelectIcon/>
                                    </div>
                                );
                            else if (order === 'desc')
                                arrowElement = (
                                    <div>
                                        <ArrowSelectIcon/>
                                    </div>
                                );

                            return (
                                <Button
                                    key={index}
                                    onClick={() => setFilter(field.value)}
                                    classes={{root: className}}>
                                    {field.label}
                                    {isActive ? arrowElement : null}
                                </Button>
                            );
                        })}
                    </div>
                    <ButtonComponent
                        className={classNameButtonEdit}
                        label={editAll ? 'Сохранить' : 'Редактировать все'}
                        onClick={() => setEditAll(!editAll)}
                    />
                </div>
            ) : null}
            <div className='customer-dashboard-navigation-main-title'>Все товары</div>
        </div>
    );
}

function RenderProduct(
    setPositionEditOpen,
    positionEditOpen,
    positionEditItemId,
    setPositionEditItemId,
    screensPosition,
    index,
    menuItem,
    category,
    startEditItem,
    setTagsSettingsOpen,
    setChooseCranesOpen,
    cranesItemsUnion,
    cranesItemsNumbers
) {
    const handleClickOpen = () => {
        return setPositionEditOpen(true);
    };

    let positionNumber;

    let logicalDict = {true: 'Да', false: 'Нет', null: '—'};
    let beerColorValues = {
        light: 'Светлое',
        dark: 'Темное',
        sweet: 'Сладкое',
        red: 'Красное',
    };

    const attrs = [
        {name: 'Плотность', value: menuItem.alcohol_density},
        {name: 'Алкоголь', value: menuItem.alcohol_degrees},
        // {name: 'IBU', value: menuItem.bitterness_scale},
        // {name: 'Номер крана', value: menuItem.tap_number},
        // {name: 'Пастеризац', value: logicalDict[menuItem.pasteurized]},
        {name: 'Фильтрованное', value: logicalDict[menuItem.filtered]},
        {name: 'Рублей за литр', value: menuItem.price},
        {name: 'Цвет пива', value: beerColorValues[menuItem.beer_color]},
        {name: 'Сорт', value: menuItem.beer_sort},
    ];

    let craneIds = cranesItemsUnion[menuItem.id];
    const cranes = craneIds ? Array.from(craneIds) : [];

    let numbers = cranes.map((craneId) => cranesItemsNumbers[craneId]);

    const isFilled = Boolean(cranesItemsUnion[menuItem.id]);

    const classList = [];
    if (isFilled) classList.push('button-component-cranes-product-title-filled');
    let className = classList.join(' ');

    let tags = [
        {
            color: menuItem.tag1_color,
            name: menuItem.tag1_name,
        },
        {
            color: menuItem.tag2_color,
            name: menuItem.tag2_name,
        },
        {
            color: menuItem.tag3_color,
            name: menuItem.tag3_name,
        },
        {
            color: menuItem.tag4_color,
            name: menuItem.tag4_name,
        },
    ];

    return (
        <div key={index} className='customer-dashboard-content-component-menu-item'>
            <div className='customer-dashboard-content-component-menu-item-numbers-container'>
                {numbers.map((craneId) => (
                    <div className='customer-dashboard-content-component-menu-item-numbers'>
                        {craneId}
                    </div>
                ))}
            </div>

            <div className='customer-dashboard-content-component-menu-item-tags-container'>
                {tags.map((tag) =>
                    tag.name ? (
                        <div
                            className='customer-dashboard-content-component-menu-item-tag'
                            style={{backgroundColor: tag.color, opacity: 1}}>
                            {tag.name}
                        </div>
                    ) : null
                )}
            </div>

            <div className='customer-dashboard-content-component-menu-item-avatar'>
                {menuItem.image && menuItem.image.url ? (
                    <img alt={menuItem.name} src={SERVER_URL + menuItem.image.url.split('?')[0]}/>
                ) : null}
            </div>
            <div className='customer-dashboard-content-component-menu-item-text'>
                <div className='customer-dashboard-content-component-menu-item-text-name'>
                    {menuItem.name}
                </div>
                {/*<div className='customer-dashboard-content-component-menu-item-text-description'>*/}
                {/*  {menuItem.description}*/}
                {/*</div>*/}
            </div>
            <div className='customer-dashboard-content-component-menu-item-attrs-container'>
                {attrs.map((attr, index) => {
                    return (
                        <div
                            key={index}
                            className='customer-dashboard-content-component-menu-item-attr-container'>
                            <div className='customer-dashboard-content-component-menu-item-attr-name'>
                                {attr.name}
                            </div>
                            <div className='customer-dashboard-content-component-menu-item-attr-value'>
                                {attr.value}
                            </div>
                        </div>
                    );
                })}
            </div>
            <div className='customer-dashboard-content-component-menu-item-buttons'>
                <div className='customer-dashboard-content-component-menu-item-buttons-row-box'>
                    <ButtonComponent
                        label='Редактировать'
                        onClick={() => startEditItem(menuItem)}
                    />
                    <ButtonComponent
                        label='Теги'
                        onClick={() => setTagsSettingsOpen(true, menuItem)}
                    />
                </div>
                <div className='dashboard-actions'>
                    <ButtonComponent
                        className={className}
                        label={
                            isFilled ? (
                                <div className='modal-cranes-product-title modal-cranes-product-title-filled'>
                                    Кран {numbers.map((craneId) => String(craneId)).join(', ')}
                                    <span>Можно переназначить</span>
                                </div>
                            ) : (
                                <div className='modal-cranes-product-title'>
                                    Назначить на кран
                                </div>
                            )
                        }
                        onClick={() => setChooseCranesOpen(true, menuItem)}
                    />
                    {/*<button*/}
                    {/*  className={*/}
                    {/*    !positionNumber*/}
                    {/*      ? 'dashboard-action-item-position-no-number'*/}
                    {/*      : 'dashboard-action-item-position'*/}
                    {/*  }*/}
                    {/*  onClick={() => {*/}
                    {/*    setPositionEditItemId(menuItem.id);*/}
                    {/*    handleClickOpen();*/}
                    {/*  }}>*/}
                    {/*  {!positionNumber ? null : (*/}
                    {/*    <div className='dashboard-action-item-position-number'>*/}
                    {/*      {positionNumber}*/}
                    {/*    </div>*/}
                    {/*  )}*/}
                    {/*  <div*/}
                    {/*    className={*/}
                    {/*      !positionNumber*/}
                    {/*        ? 'dashboard-action-item-position-label-no-number'*/}
                    {/*        : 'dashboard-action-item-position-label'*/}
                    {/*    }>*/}
                    {/*    Позиция*/}
                    {/*  </div>*/}
                    {/*</button>*/}
                </div>
            </div>
        </div>
    );
}

function renderRow(
    index,
    menuItem,
    category,
    startEditItem,
    setTagsSettingsOpen,
    editAll,
    setInputMenuItemField,
    cranesItemsUnion,
    cranesItemsNumbers
) {
    let logicalDict = {true: 'Да', false: 'Нет', null: '—'};
    let beerColorValues = {
        light: 'Светлое',
        dark: 'Темное',
        sweet: 'Сладкое',
        red: 'Красное',
    };

    let craneIds = cranesItemsUnion[menuItem.id];
    const cranes = craneIds ? Array.from(craneIds) : [];

    let numbers = cranes.map((craneId) => cranesItemsNumbers[craneId]);

    const attrs = [
        {
            name: 'Номер крана',
            value: numbers.join(', '),
            source: menuItem.tap_number,
            field: 'tap_number',
        },

        {
            name: 'Цвет пива',
            value: beerColorValues[menuItem.beer_color],
            source: menuItem.beer_color,
            field: 'beer_color',
        },
        {
            name: 'Алкоголь',
            value: menuItem.alcohol_degrees,
            source: menuItem.alcohol_degrees,
            field: 'alcohol_degrees',
        },
        {
            name: 'Плотность',
            value: menuItem.alcohol_density,
            source: menuItem.alcohol_density,
            field: 'alcohol_density',
        },
        {
            name: 'Рублей за литр',
            value: menuItem.price,
            source: menuItem.price,
            field: 'price',
        },
        {
            name: 'Фильтрованное',
            value: logicalDict[menuItem.filtered],
            source: menuItem.filtered,
            field: 'filtered',
        },
    ];

    const forEdit = [
        'Цвет пива',
        'Алкоголь',
        'Плотность',
        'Рублей за литр',
        'Фильтрованное',
    ];
    const forSelect = {
        'Цвет пива': Object.keys(beerColorValues).map((key) => {
            return {id: key, name: beerColorValues[key]};
        }),
        Фильтрованное: Object.keys(logicalDict).map((key) => {
            return {id: key, name: logicalDict[key]};
        }),
    };
    const forSelectFields = ['Цвет пива', 'Фильтрованное'];

    return (
        <div
            key={index}
            className='customer-dashboard-content-component-menu-item-row'>
            <div className='customer-dashboard-content-component-menu-item-avatar-row'>
                <Avatar
                    alt={menuItem.name}
                    sx={{width: 60, height: 60}}
                    src={
                        menuItem.image
                            ? menuItem.image.image
                                ? menuItem.image.image
                                : SERVER_URL + menuItem.image.url.split('?')[0]
                            : null
                    }
                />
            </div>
            <div className='customer-dashboard-content-component-menu-item-text-row'>
                <div
                    className='customer-dashboard-content-component-menu-item-text-name-row customer-dashboard-content-component-menu-item-text-name-row-name'>
                    {menuItem.name}
                </div>
            </div>
            {attrs.map((attr, index) => {
                if (editAll && forEdit.includes(attr.name)) {
                    if (forSelectFields.includes(attr.name)) {
                        return (
                            <div
                                key={index}
                                className={[
                                    'customer-dashboard-content-component-menu-item-attr-container-row',
                                    'customer-dashboard-content-component-menu-item-attr-container-row-select',
                                ].join(' ')}>
                                <FormControl>
                                    <InputLabel>{attr.name}</InputLabel>
                                    <Select
                                        MenuProps={{
                                            className: 'modal-cranes-item-header-beer-select-menu',
                                        }}
                                        value={safetyInput(attr.source)}
                                        onChange={(e) =>
                                            setInputMenuItemField(e.target.value, attr, menuItem)
                                        }
                                        label={attr.name}>
                                        {forSelect[attr.name].map((item, index) => {
                                            return (
                                                <MenuItem key={index} value={item.id}>
                                                    {item.name}
                                                </MenuItem>
                                            );
                                        })}
                                    </Select>
                                </FormControl>
                            </div>
                        );
                    }
                    return (
                        <div
                            key={index}
                            className='customer-dashboard-content-component-menu-item-attr-container-row'>
                            <TextField
                                margin='dense'
                                id={`attribute-${attr.name}-${index}`}
                                name={`attribute-${attr.name}-${index}`}
                                type='text'
                                fullWidth
                                variant='outlined'
                                label={attr.name}
                                value={safetyInput(attr.value)}
                                onInput={(e) =>
                                    setInputMenuItemField(e.target.value, attr, menuItem)
                                }
                            />
                        </div>
                    );
                }

                return (
                    <div
                        key={index}
                        className='customer-dashboard-content-component-menu-item-attr-container-row'>
                        <div className='customer-dashboard-content-component-menu-item-attr-name-row'>
                            {attr.name}
                        </div>
                        <div className='customer-dashboard-content-component-menu-item-attr-value-row'>
                            {attr.value}
                        </div>
                    </div>
                );
            })}
            <div className='customer-dashboard-content-component-menu-item-buttons-row'>
                {!editAll ? (
                    <ButtonComponent
                        label='Редактировать'
                        onClick={() => startEditItem(menuItem)}
                    />
                ) : null}
                <ButtonComponent
                    label='Теги'
                    onClick={() => setTagsSettingsOpen(true, menuItem)}
                />
            </div>
        </div>
    );
}

function renderProducts(
    setPositionEditOpen,
    positionEditOpen,
    positionEditItemId,
    setPositionEditItemId,
    screensPosition,
    displayType,
    owner,
    action,
    setAddProductsOpen,
    startEditItem,
    setTagsSettingsOpen,
    order,
    orderBy,
    dashboardSortType,
    editAll,
    setInputMenuItemField,
    setChooseCranesOpen,
    cranesItemsUnion,
    cranesItemsNumbers,
    searchValue
) {
    let categoriesHistory = new Set();
    let categories = Utils.listToDict(owner.categories, 'id');
    // menuItems.sort((a, b) => (a.category ? a.category.length : 0) - (b.category ? b.category.length : 0))

    let menuItems = owner.menu_items;
    if (searchValue) {
        searchValue = searchValue.toLowerCase();
        menuItems = menuItems.filter(
            (item) => item.name.toLowerCase().indexOf(searchValue) >= 0
        );
    }

    if (menuItems.length === 0)
        return (
            <div className='customer-dashboard-content-menu-items-empty-container'>
                <div className='customer-dashboard-content-menu-items-empty'>
                    <Avatar alt='Remy Sharp' sx={{width: 120, height: 120}}>
                        <ClockIcon/>
                    </Avatar>
                    <div className='customer-dashboard-content-menu-items-empty-title'>
                        Здесь пока ничего нет
                    </div>
                    <div className='customer-dashboard-content-menu-items-empty-description'>
                        <a onClick={() => setAddProductsOpen(true)}>
                            Добавьте какую-нибудь позицию
                        </a>
                        <br/>
                        {/*или загляните в <a href="/support">Помощь</a>*/}
                    </div>
                </div>
            </div>
        );

    if (displayType === 'list') {
        menuItems = stableSort(menuItems, getComparator(order, orderBy));
        return (
            <div className='customer-dashboard-content-menu-items-row'>
                {menuItems.map((menuItem, index) => {
                    let category = null;
                    if (!categoriesHistory.has(menuItem.category_id)) {
                        category = categories[menuItem.category_id];
                        categoriesHistory.add(menuItem.category_id);
                    }
                    return renderRow(
                        index,
                        menuItem,
                        category,
                        startEditItem,
                        setTagsSettingsOpen,
                        editAll,
                        setInputMenuItemField,
                        cranesItemsUnion,
                        cranesItemsNumbers
                    );
                })}
            </div>
        );
    }

    if (dashboardSortType === 'number') {
        let cranes = owner.cranes;
        let cranesIds = {};
        cranes.forEach((item) => {
            if (item.menu_item_id) {
                cranesIds[item.menu_item_id] = item.number;
            }
        });
        menuItems = menuItems.map((item) => {
            item.craneNumber = cranesIds[item.id];
            return item;
        });
        menuItems = stableSort(menuItems, getComparator(order, 'craneNumber'));
    } else {
        menuItems = stableSort(menuItems, getComparator(order, 'beer_color'));
    }

    return (
        <div className='customer-dashboard-content-menu-items'>
            {menuItems.map((menuItem, index) => {
                let category = null;
                if (!categoriesHistory.has(menuItem.category_id)) {
                    category = categories[menuItem.category_id];
                    categoriesHistory.add(menuItem.category_id);
                }
                return RenderProduct(
                    setPositionEditOpen,
                    positionEditOpen,
                    positionEditItemId,
                    setPositionEditItemId,
                    screensPosition,
                    index,
                    menuItem,
                    category,
                    startEditItem,
                    setTagsSettingsOpen,
                    setChooseCranesOpen,
                    cranesItemsUnion,
                    cranesItemsNumbers
                );
            })}
        </div>
    );
}

class CustomerDashboard extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            store: null,
            stores: [],
            contacts: {},
            waitLabel: null,
            isMenuOpen: false,
            owner: null,
            cranesItemsUnion: {},
            cranesItemsNumbers: {},
            menuItemsIds: {},

            editAll: false,
            positionEditOpen: false,
            positionEditItemId: null,
            screensPosition: [],
            addProductsOpen: false,
            editProductsOpen: false,
            shopSettingsOpen: false,
            tagsSettingsOpen: false,
            contentSettingsOpen: false,

            selectedCranes: new Set(),

            manyMenuElement: null,
            manyMenuOpen: false,
            profileMenuOpen: false,
            filtersOpen: false,
            filtersElement: null,
            sortingOpen: false,
            sortingElement: null,
            cranesOpen: false,
            craneSelected: null,
            chooseCranesOpen: false,
            chooseCranesItem: null,
            searchValue: '',

            libraryLoading: false,
            libraryTemplate: null,

            cranesShowFull: false,

            libraryItems: [],

            category: null,
            categories: [],

            imageBase64: null,

            editableItem: {},
            newItem: {},
            tagsMenuItem: {},

            screenPosition: {
                positions: [],
                additional: []
            },

            contentSettingsTypes: {tv: 'TV', layouts: 'Макеты'},
            contentSettingsType: 'tv',

            pasteurizedValues: {true: 'Да', false: 'Нет', null: '—'},
            pasteurized: true,

            beerColorValues: {
                light: 'Светлое',
                dark: 'Темное',
                sweet: 'Сладкое',
                red: 'Красное',
            },
            beerColor: 'light',

            filteredBeerValues: {true: 'Да', false: 'Нет', null: '—'},
            filteredBeer: true,

            shopBackgroundValues: {image: 'Картинка', color: 'Цвет'},
            shopBackground: 'image',

            televisions: [],
            layouts: [],

            shopBackgroundColorValues: {
                '#000000': '#000000',
                '#D0D0D0': '#D0D0D0',
                '#FFFFFF': '#FFFFFF',
                '#E95E2A': '#E95E2A',
                '#6930C3': '#6930C3',
            },
            shopBackgroundColor: '#000000',

            tagsColorValues: {
                null: 'missing',
                '#f95724': '#f95724',
                '#0b9400': '#0b9400',
                '#55bcff': '#55bcff',
                '#232220': '#232220',
            },
            tagsColor: 'missing',

            shopProductAvatarResizeValues: {
                contain: 'Contain',
                cover: 'Cover',
                fill: 'Fill',
                'scale-down': 'Scale-down',
                none: '—',
            },
            shopProductAvatarResize: 'contain',

            shopSortValues: {number: 'По номеру крана', item_type: 'По типу пива'},
            shopSort: 'number',

            displayTypeSettingValues: {cards: 'Карточки', table: 'Таблица'},
            displayTypeSetting: 'cards',

            dashboardSortType: 'number',

            displayType: Storage.get('InterfaceSettingsDisplayType', 'cards'),
            order: 'asc',
            orderBy: null,

            isBlocked: false,
            notification: null,
        };

        this.createCranesItemsUnion = (owner) => {
            let results = {};
            owner.menu_items.forEach((menuItem) => {
                owner.cranes.forEach((crane) => {
                    if (crane.menu_item_id === menuItem.id) {
                        if (results[crane.menu_item_id])
                            results[crane.menu_item_id].push(crane.id);
                        else results[crane.menu_item_id] = [crane.id];
                    }
                });
            });
            return results;
        };

        this.createCranesItemsNumbers = (owner) => {
            let results = {};
            owner.cranes.forEach((crane) => {
                if (results[crane.id]) results[crane.id].push(crane.number);
                else results[crane.id] = [crane.number];
            });
            return results;
        };

        this.createMenuItemsIds = (owner) => {
            let results = {};
            owner.menu_items.forEach((menu_item) => {
                results[menu_item.id] = menu_item;
            });
            return results;
        };

        this.cranActionHandler = (craneId) => {
            let selectedCranes = this.state.selectedCranes;
            if (selectedCranes.has(craneId)) selectedCranes.delete(craneId);
            else {
                selectedCranes = new Set();
                selectedCranes.add(craneId);
            }
            this.setState({selectedCranes: selectedCranes});
        };

        this.cranItemSave = () => {
            API.cranElementSave({
                storeId: this.state.owner.store.id,
                craneIds: Array.from(this.state.selectedCranes),
                menuItemId: this.state.chooseCranesItem.id,
            }).then((data) => {
                this.setState(
                    {
                        owner: data,
                        cranesItemsUnion: this.createCranesItemsUnion(data),
                        cranesItemsNumbers: this.createCranesItemsNumbers(data),
                        menuItemsIds: this.createMenuItemsIds(data),
                    },
                    () => {
                        this.setChooseCranesOpen(false);
                    }
                );
            });
        };

        this.cancelCranItem = () => {
            this.setChooseCranesOpen(false);
            this.updateOwnership();
        };

        this.setFilter = (value) => {
            let order = 'asc';
            if (this.state.order === 'asc' && this.state.orderBy === value) {
                order = 'desc';
            } else if (this.state.order === 'desc' && this.state.orderBy === value) {
                order = null;
            }
            this.setState({orderBy: order ? value : null, order: order});
        };

        this.onClickSwitch = () => {
        };

        this.setMenu = (status) => () => {
            this.setState({isMenuOpen: status});
        };

        this.modelAction = (model, actionName) => {
            console.log([model, actionName]);
        };

        this.categorySelectChange = (e) => {
            this.setState({category: e.target.value});
        };

        this.categoryEditSelectChange = (e) => {
            let editableItem = this.state.editableItem;
            editableItem.category_id = e.target.value;
            this.setState({editableItem: editableItem});
        };

        this.fileInputChange = (e, callback) => {
            let target = e.target;
            if (target.files && target.files[0]) {
                let file = target.files[0];
                let reader = new FileReader();
                reader.onload = (event) => {
                    let base64String = event.target.result;
                    document.getElementById('image').src = base64String;
                    let payload = {imageBase64: base64String};
                    this.setState(payload, () => callback(base64String));
                };
                reader.readAsDataURL(file);
            }
        };

        this.filePreviewHandler = (b64str) => {
            let item = this.state.editableItem;
            if (item.image) {
                item.image.image = b64str;
            } else {
                item.image = {
                    image: b64str,
                };
            }
            this.setState({editableItem: item});
        };

        this.fileUploadHandler = (b64str) => {
            let item = this.state.newItem;
            item.image = {
                image: b64str,
            };
            this.setState({newItem: item});
        };

        this.fileBackgroundInputChange = (e, callback) => {
            let target = e.target;
            if (target.files && target.files[0]) {
                let file = target.files[0];
                let reader = new FileReader();
                reader.onload = (event) => {
                    let base64String = event.target.result;
                    document.getElementById('background_image').src = base64String;
                    let payload = {imageBase64: base64String};
                    this.setState(payload, () => callback(base64String));
                };
                reader.readAsDataURL(file);
            }
        };

        this.fileBackgroundPreviewHandler = (b64str) => {
            let item = this.state.store;
            if (item.background_image) {
                item.background_image.image = b64str;
            } else {
                item.background_image = {
                    image: b64str,
                };
            }
            this.setState({store: item});
        };

        this.updateOwnership = (callback) => {
            API.profileOwnership(Storage.get('selectedStoreId')).then((data) => {
                this.setState(
                    {
                        owner: data,
                        cranesItemsUnion: this.createCranesItemsUnion(data),
                        cranesItemsNumbers: this.createCranesItemsNumbers(data),
                        menuItemsIds: this.createMenuItemsIds(data),
                        // screensPosition: data.screens,
                    },
                    callback
                );
            });
        };

        this.updateShop = () => {
            API.profileStore(Storage.get('selectedStoreId')).then((data) => {
                Storage.set('store', data.store);
                this.setState({
                    store: data.store,
                    waitLabel: Boolean(data.store === null),
                });
                // API.getItemsPosition(data.store.id).then((res) => {
                //   this.setState({screensPosition: res.screens});
                // });
                this.setState({screenPosition: {positions: [], additional: []}})
                this.updateOwnership();
            });
        };

        this.startEditItem = (menuItem) => {
            this.setState({editableItem: menuItem});
            this.setEditProductsOpen(true);
        };

        this.onUniversalInput = (e, obj, key, objName, callback) => {
            obj = JSON.parse(JSON.stringify(obj));
            let value = e.target.value;

            if (key === 'alcohol_degrees') {
                if (value >= 100) value = 100;
                if (value <= 0) value = 0;
            }

            if (key === 'alcohol_density') {
                if (value >= 100) value = 100;
                if (value <= 0) value = 0;
            }

            if (key === 'bitterness_scale') {
                if (value >= 200) value = 200;
                if (value <= 0) value = 0;
            }

            if (key === 'price') {
                if (value <= 0) value = 0;
            }

            if (key === 'tap_number') {
                if (value <= 0) value = 0;
            }

            obj[key] = value;
            this.setState({[objName]: obj}, callback);
        };

        this.setDeviceLayout = (layoutId, device) => {
            let owner = this.state.owner;
            let layouts = owner.layouts;
            layouts = layouts.map((obj) => {
                if (obj.id === layoutId) {
                    obj.device_id = device.id;
                } else if (obj.id === device.layout_id) {
                    obj.device_id = null;
                }
                return obj;
            });
            owner.layouts = layouts;
            let devices = owner.devices;
            devices = devices.map((obj) => {
                if (obj.id === device.id) {
                    obj.layout_id = layoutId;
                }
                return obj;
            });
            owner.devices = devices;
            this.setState({
                owner: owner,
                cranesItemsUnion: this.createCranesItemsUnion(owner),
                cranesItemsNumbers: this.createCranesItemsNumbers(owner),
                menuItemsIds: this.createMenuItemsIds(owner),
            });
        };

        this.setLayoutDevice = (deviceId, layout) => {
            let owner = this.state.owner;
            let devices = owner.devices;
            devices = devices.map((obj) => {
                if (obj.id === deviceId) {
                    obj.layout_id = layout.id;
                } else if (obj.id === layout.device_id) {
                    obj.layout_id = null;
                }
                return obj;
            });
            owner.devices = devices;
            let layouts = owner.layouts;
            layouts = layouts.map((obj) => {
                if (obj.id === layout.id) {
                    obj.device_id = deviceId;
                }
                return obj;
            });
            owner.layouts = layouts;
            this.setState({
                owner: owner,
                cranesItemsUnion: this.createCranesItemsUnion(owner),
                cranesItemsNumbers: this.createCranesItemsNumbers(owner),
                menuItemsIds: this.createMenuItemsIds(owner),
            });
        };

        this.changeDeviceAttribute = (e, device, field) => {
            let activeDeviceId = device.id;
            let value = e.target.value;

            let owner = this.state.owner;
            let devices = owner.devices;
            devices = devices.map((deviceElement) => {
                if (activeDeviceId === deviceElement.id) {
                    deviceElement[field] = value;
                }
                return deviceElement;
            });
            owner.devices = devices;
            this.setState({
                owner: owner,
                cranesItemsUnion: this.createCranesItemsUnion(owner),
                cranesItemsNumbers: this.createCranesItemsNumbers(owner),
                menuItemsIds: this.createMenuItemsIds(owner),
            });
        };

        this.changeLayoutAttribute = (e, layout, field) => {
            let activeLayoutId = layout.id;
            let value = e.target.value;

            let owner = this.state.owner;
            let layouts = owner.layouts;
            layouts = layouts.map((layoutElement) => {
                if (activeLayoutId === layoutElement.id) {
                    layoutElement[field] = value;
                }
                return layoutElement;
            });
            owner.layouts = layouts;
            this.setState({
                owner: owner,
                cranesItemsUnion: this.createCranesItemsUnion(owner),
                cranesItemsNumbers: this.createCranesItemsNumbers(owner),
                menuItemsIds: this.createMenuItemsIds(owner),
            });
        };

        this.onUniversalEdit = (value, obj, key, objName) => {
            obj = JSON.parse(JSON.stringify(obj));
            obj[key] = value;
            this.setState({[objName]: obj});
        };

        this.setEditAll = (status) =>
            this.setState({editAll: status}, () => {
                if (!status) {
                    API.shopMassEditSave({
                        storeId: this.state.owner.store.id,
                        menuItems: this.state.owner.menu_items,
                    }).then((data) => {
                        console.log(data);
                    });
                }
            });
        this.setPositionEditOpen = (status) =>
            this.setState({positionEditOpen: status});
        this.setPositionEditItemId = (itemId) =>
            this.setState({positionEditItemId: itemId});
        this.setAddProductsOpen = (status) =>
            this.setState({addProductsOpen: status});
        this.setEditProductsOpen = (status) =>
            this.setState({editProductsOpen: status});
        this.setShopSettingsOpen = (status) =>
            this.setState({shopSettingsOpen: status});
        this.setFiltersOpen = (status, element) =>
            this.setState({filtersOpen: status, filtersElement: element});
        this.setSortingOpen = (status, element) =>
            this.setState({sortingOpen: status, sortingElement: element});
        this.setDisplayType = (displayType) =>
            this.setState({displayType: displayType}, () =>
                Storage.set('InterfaceSettingsDisplayType', displayType)
            );
        this.setManyMenuOpen = (status) =>
            this.setState({
                manyMenuOpen: status,
            });
        this.setProfileMenuOpen = (status) =>
            this.setState({
                profileMenuOpen: status,
            });
        this.setTagsSettingsOpen = (status, menuItem) =>
            this.setState({
                tagsSettingsOpen: status,
                tagsMenuItem: menuItem ? menuItem : {},
            });
        this.setContentSettingsOpen = (status) => {
            this.setState({contentSettingsOpen: status});
        };
        this.setCranesOpen = (status) => {
            this.setState({cranesOpen: status});
        };
        this.setChooseCranesOpen = (status, menuItem) => {
            let selected = new Set();

            this.state.owner.cranes.forEach((crane) => {
                if (menuItem && crane.menu_item_id === menuItem.id)
                    selected.add(crane.id);
            });
            this.setState({
                chooseCranesOpen: status,
                chooseCranesItem: menuItem ? menuItem : null,
                selectedCranes: selected,
            });
        };
        this.setDashboardSortType = (status) => {
            this.setState({dashboardSortType: status});
        };

        this.setSearchValue = (value) => {
            this.setState({searchValue: value});
        };

        this.shopCranesAddHandler = (event) => {
            API.shopCranesAdd({storeId: this.state.store.id})
                .then((data) => {
                    this.setState(
                        {
                            owner: data,
                            cranesItemsUnion: this.createCranesItemsUnion(data),
                            cranesItemsNumbers: this.createCranesItemsNumbers(data),
                            menuItemsIds: this.createMenuItemsIds(data),
                        },
                        () => {
                            this.updateOwnership(() => {
                                let elements = document.getElementsByClassName(
                                    'MuiDialog-container'
                                );
                                if (elements) {
                                    let element = elements[0];
                                    element.scroll({
                                        top: 10000000,
                                        left: 0,
                                        behavior: 'smooth',
                                    });
                                }
                            });
                        }
                    );
                })
                .catch((error) => {
                    console.error(error);
                    this.setEditProductsOpen(false);
                    this.updateOwnership();
                });
        };

        this.sendChanges = () => {
            API.saveChangedMenuItem(this.state.editableItem)
                .then((data) => {
                    this.setEditProductsOpen(false);
                    this.updateOwnership();
                })
                .catch((error) => {
                    console.error(error);
                    this.setEditProductsOpen(false);
                    this.updateOwnership();
                });
        };

        this.deleteChanges = () => {
            API.deleteMenuItem({
                storeId: this.state.owner.store.id,
                menuItemId: this.state.editableItem.id,
            })
                .then((data) => {
                    this.setEditProductsOpen(false);
                    this.updateOwnership();
                })
                .catch((error) => {
                    console.error(error);
                    this.setEditProductsOpen(false);
                    this.updateOwnership();
                });
        };

        this.cancelChanges = () => {
            this.setEditProductsOpen(false);
            this.updateOwnership();
        };

        this.sendCreating = () => {
            API.saveCreatingMenuItem(this.state.newItem, this.state.store.id)
                .then((data) => {
                    this.setAddProductsOpen(false);
                    this.updateOwnership();
                })
                .catch((error) => {
                    console.error(error);
                    this.setAddProductsOpen(false);
                    this.updateOwnership();
                });
        };

        this.cancelCreating = () => {
            this.setAddProductsOpen(false);
            this.updateOwnership();
        };

        this.sendShopSettings = () => {
            API.saveShopSettings(this.state.store)
                .then((data) => {
                    this.setShopSettingsOpen(false);
                    this.updateShop();
                })
                .catch((error) => {
                    console.error(error);
                    this.setShopSettingsOpen(false);
                    this.updateShop();
                });
        };

        this.cancelShopSettings = () => {
            this.setShopSettingsOpen(false);
            this.updateShop();
        };

        this.sendTagsSettings = () => {
            API.saveTagsSettings(this.state.tagsMenuItem)
                .then((data) => {
                    this.setTagsSettingsOpen(false);
                    this.updateOwnership();
                })
                .catch((error) => {
                    console.error(error);
                    this.setTagsSettingsOpen(false);
                    this.updateOwnership();
                });
        };

        this.cancelTagsSettings = () => {
            this.setTagsSettingsOpen(false);
            this.updateOwnership();
        };

        this.cancelLayoutsDevices = () => {
            this.setContentSettingsOpen(false);
            this.updateOwnership();
        };

        this.saveCranes = (callback) => {
            API.shopCranesSave({
                storeId: this.state.owner.store.id,
                cranes: this.state.owner.cranes,
            }).then((data) =>
                this.setState(
                    {
                        owner: data,
                        cranesItemsUnion: this.createCranesItemsUnion(data),
                        cranesItemsNumbers: this.createCranesItemsNumbers(data),
                        menuItemsIds: this.createMenuItemsIds(data),
                    },
                    () => {
                        this.setCranesOpen(false);
                        if (callback) callback();
                    }
                )
            );
        };

        this.cancelCranes = () => {
            this.setCranesOpen(false);
            this.updateOwnership();
        };

        this.craneEndProduct = (activeCrane) => {
            let owner = this.state.owner;
            let cranes = owner.cranes;
            cranes = cranes.map((crane) => {
                if (crane.id === activeCrane.id) {
                    crane.is_product_end = !crane.is_product_end;
                }
                return crane;
            });
            owner.cranes = cranes;
            this.setState({
                owner: owner,
                cranesItemsUnion: this.createCranesItemsUnion(owner),
                cranesItemsNumbers: this.createCranesItemsNumbers(owner),
                menuItemsIds: this.createMenuItemsIds(owner),
            });
        };

        this.setCranePosition = (value, activeCrane) => {
            let owner = this.state.owner;
            let cranes = owner.cranes;
            cranes = cranes.map((crane) => {
                if (crane.id === activeCrane.id) {
                    crane.menu_item_id = value;
                }
                return crane;
            });
            owner.cranes = cranes;
            this.setState({
                owner: owner,
                cranesItemsUnion: this.createCranesItemsUnion(owner),
                cranesItemsNumbers: this.createCranesItemsNumbers(owner),
                menuItemsIds: this.createMenuItemsIds(owner),
            });
        };

        this.setInputMenuItemField = (value, attr, menuItem) => {
            let owner = this.state.owner;
            let menuItems = owner.menu_items;
            menuItems = menuItems.map((item) => {
                if (menuItem.id === item.id) {
                    item[attr.field] = value;
                }
                return item;
            });
            owner.menuItems = menuItems;
            this.setState({
                owner: owner,
                cranesItemsUnion: this.createCranesItemsUnion(owner),
                cranesItemsNumbers: this.createCranesItemsNumbers(owner),
                menuItemsIds: this.createMenuItemsIds(owner),
            });
        };

        this.setItemsPosition = (body) => {
            // API.setItemsPosition(body).then((res) => {
            //   this.setState({screensPosition: res.screens});
            // });
        };

        this.setBeerEnded = (body) => {
            API.setBeerEnded(body).then((res) => this.setState({owner: res}));
        };

        this.setTemplateData = (menuItemId) => {
            this.setState({libraryLoading: true}, () => {
                API.libraryEntity(menuItemId).then((data) => {
                    let newItem = this.state.newItem;
                    let columns = {
                        'alcohol_degrees': 'alcohol_degrees',
                        'alcohol_density': 'alcohol_density',
                        'attributes': 'attributes',
                        'color_label': 'beer_color',
                        'full_style_label': 'beer_sort',
                        'beer_type': 'beer_type',
                        'bitterness_scale': 'bitterness_scale',
                        'description': 'description',
                        'filtered_label': 'filtered',
                        'name': 'name',
                        'pasteurized': 'pasteurized',
                        'brewery_label': 'brewery'
                    };

                    Object.keys(columns).forEach((columnName) => {
                        newItem[columns[columnName]] = data.product[columnName];
                    });

                    if (data.product.image) {
                        newItem.image = data.product.image;
                    }

                    this.setState({
                        libraryItems: [],
                        libraryTemplate: data.product,
                        libraryLoading: false,
                        newItem: newItem,
                    });
                });
            });
        };

        this.renderInternal = this.renderInternal.bind(this);
    }

    componentDidMount() {
        API.contacts().then((data) => {
            this.setState({contacts: data.contacts});
        });

        API.categories().then((data) => {
            this.setState({categories: data.categories});
        });

        API.profileStores().then((data) => {
            this.setState({stores: data.stores});
        });

        API.userNotifications({storeId: Storage.get('selectedStoreId')})
            .then(data => {
                if (data.notification) {
                    this.setState({isBlocked: data.notification.blocked, notification: data.notification})
                }
            })
            .catch((e) => {
                console.error(e)
            })

        this.updateShop();
    }

    renderInternal(products) {
        let menuItemsIds = {};
        let recs = [];
        if (this.state.owner && this.state.owner.menu_items) {
            this.state.owner.menu_items.forEach((menu_item) => {
                menuItemsIds[parseInt(menu_item.id)] = menu_item;
            });

            if (this.state.newItem.searchLibraryValue) {
                // let ids = Object.keys();
                // recs = names
                //     .map((item) => ({
                //         item: item,
                //         distance: levenshteinDistance(
                //             this.state.newItem.searchLibraryValue.toLowerCase(),
                //             item.toLowerCase()
                //         ),
                //         match: item
                //             .toLowerCase()
                //             .includes(this.state.newItem.searchLibraryValue.toLowerCase()),
                //     }))
                //     .sort((a, b) => a.distance - b.distance);
                // recs = recs.sort((a, b) => b.match - a.match);
                recs = this.state.libraryItems;
            }
        }

        return (
            <div
                className='customer-dashboard-container'
                style={{
                    '--avatar-resize-method': this.state.store.product_avatar_resize,
                }}>
                {
                    this.state.isBlocked ?
                        <div className="customer-dashboard-content-blocked"/> : null
                }
                <div className='customer-dashboard-content'>
                    <div className='customer-dashboard-content-container'>
                        <div className='customer-dashboard-content-column'>
                            {renderNavigation(
                                this.state.displayType,
                                this.setDisplayType,
                                this.setFilter,
                                this.state.orderBy,
                                this.state.order,
                                this.setFiltersOpen,
                                this.state.filtersOpen,
                                this.state.filtersElement,
                                this.setSortingOpen,
                                this.state.sortingOpen,
                                this.state.sortingElement,
                                this.setAddProductsOpen,
                                this.setEditAll,
                                this.state.editAll,
                                this.state.dashboardSortType,
                                this.setDashboardSortType,
                                this.state.searchValue,
                                this.setSearchValue
                            )}
                            {products}
                        </div>
                    </div>
                </div>
                <PositionDialogComponent
                    open={this.state.positionEditOpen}
                    onClose={() => {
                        this.setPositionEditOpen(false);
                    }}
                    onSubmit={(data) => {
                        this.setItemsPosition(data.data);
                        this.setPositionEditOpen(false);
                    }}
                    storeId={this.state.store.id}
                    menuItemId={this.state.positionEditItemId}
                    currentPosition={this.state.screensPosition}
                />
                <Dialog
                    scroll={Utils.isDeviceMobile() ? 'paper' : 'body'}
                    classes={{root: 'customer-dashboard-modal-add-product'}}
                    open={this.state.addProductsOpen}
                    onClose={() => this.setAddProductsOpen(false)}
                    maxWidth={'sm'}
                    fullWidth={false}
                    PaperProps={{
                        component: 'form',
                        onSubmit: (event) => {
                            event.preventDefault();
                            const formData = new FormData(event.currentTarget);
                            const formJson = Object.fromEntries(formData.entries());
                            API.storeItemAdd(
                                formJson.name,
                                formJson.description,
                                formJson.category,
                                formJson.price,
                                this.state.imageBase64,
                                this.state.store.id
                            ).then((data) => {
                                this.updateOwnership();
                            });
                            this.setAddProductsOpen(false);
                        },
                    }}>
                    <div className='modal-edit-product-title-container'>
                        <div className='modal-edit-product-title'>
                            {this.state.libraryLoading ? (
                                <div className='modal-edit-product-progress'>
                                    <CircularProgress/>
                                </div>
                            ) : (
                                'Добавить позицию'
                            )}
                        </div>
                        <IconButton onClick={() => this.setAddProductsOpen(false)}>
                            <CloseRoundedIcon/>
                        </IconButton>
                    </div>
                    <div className='modal-edit-product-inputs-container'>
                        <TextField
                            margin='dense'
                            id='searchLibraryValue'
                            name='searchLibraryValue'
                            label='Поиск товара в библиотеке'
                            type='text'
                            fullWidth
                            variant='outlined'
                            value={safetyInput(this.state.newItem.searchLibraryValue)}
                            onInput={(e) =>
                                this.onUniversalInput(
                                    e,
                                    this.state.newItem,
                                    'searchLibraryValue',
                                    'newItem',
                                    () => API.librarySearch(this.state.newItem.searchLibraryValue)
                                        .then(data => {
                                            this.setState({libraryItems: data.templates})
                                        })
                                )
                            }
                        />
                        <div className='modal-edit-product-recs'>
                            {recs.map((item, index) => {
                                return (
                                    <div
                                        key={index}
                                        className='modal-edit-product-recs-element'
                                        onClick={() =>
                                            this.setTemplateData(item.id)
                                        }>
                                        {item.name}
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                    <div className='modal-edit-product-inputs-container'>
                        <TextField
                            margin='dense'
                            id='name'
                            name='name'
                            label='Название'
                            type='text'
                            fullWidth
                            variant='outlined'
                            value={safetyInput(this.state.newItem.name)}
                            onInput={(e) =>
                                this.onUniversalInput(e, this.state.newItem, 'name', 'newItem')
                            }
                        />
                        {/*<TextField*/}
                        {/*  margin='dense'*/}
                        {/*  id='description'*/}
                        {/*  name='description'*/}
                        {/*  label='Описание'*/}
                        {/*  type='text'*/}
                        {/*  fullWidth*/}
                        {/*  variant='outlined'*/}
                        {/*  value={safetyInput(this.state.newItem.description)}*/}
                        {/*  onInput={(e) =>*/}
                        {/*    this.onUniversalInput(*/}
                        {/*      e,*/}
                        {/*      this.state.newItem,*/}
                        {/*      'description',*/}
                        {/*      'newItem'*/}
                        {/*    )*/}
                        {/*  }*/}
                        {/*/>*/}
                        <TextField
                            margin='dense'
                            id='beerBrewery'
                            name='beerBrewery'
                            label='Пивоварня'
                            type='text'
                            fullWidth
                            variant='outlined'
                            value={safetyInput(this.state.newItem.brewery)}
                            onInput={(e) =>
                                this.onUniversalInput(
                                    e,
                                    this.state.newItem,
                                    'brewery',
                                    'newItem'
                                )
                            }
                        />
                        <TextField
                            margin='dense'
                            id='beerSort'
                            name='beerSort'
                            label='Сорт'
                            type='text'
                            fullWidth
                            variant='outlined'
                            value={safetyInput(this.state.newItem.beer_sort)}
                            onInput={(e) =>
                                this.onUniversalInput(
                                    e,
                                    this.state.newItem,
                                    'beer_sort',
                                    'newItem'
                                )
                            }
                        />
                    </div>
                    <div className='modal-edit-product-horizontal-cells'>
                        <div className='modal-edit-product-horizontal-cell'>
                            <div className='modal-edit-product-horizontal-cell-card'>
                                <div className='modal-edit-product-horizontal-cell-card-title'>
                                    Алкоголь
                                </div>
                            </div>
                            <div className='modal-edit-product-horizontal-cell-input'>
                                <TextField
                                    margin='dense'
                                    id='alcohol'
                                    name='alcohol'
                                    type='number'
                                    fullWidth
                                    variant='outlined'
                                    step='0.1'
                                    value={safetyInput(this.state.newItem.alcohol_degrees)}
                                    onInput={(e) =>
                                        this.onUniversalInput(
                                            e,
                                            this.state.newItem,
                                            'alcohol_degrees',
                                            'newItem'
                                        )
                                    }
                                />
                            </div>
                        </div>
                        <div className='modal-edit-product-horizontal-cell'>
                            <div className='modal-edit-product-horizontal-cell-card'>
                                <div className='modal-edit-product-horizontal-cell-card-title'>
                                    Плотность
                                </div>
                            </div>
                            <div className='modal-edit-product-horizontal-cell-input'>
                                <TextField
                                    margin='dense'
                                    id='density'
                                    name='density'
                                    type='number'
                                    fullWidth
                                    variant='outlined'
                                    step='0.1'
                                    value={safetyInput(this.state.newItem.alcohol_density)}
                                    onInput={(e) =>
                                        this.onUniversalInput(
                                            e,
                                            this.state.newItem,
                                            'alcohol_density',
                                            'newItem'
                                        )
                                    }
                                />
                            </div>
                        </div>
                        <div className='modal-edit-product-horizontal-cell'>
                            <div className='modal-edit-product-horizontal-cell-card'>
                                <div className='modal-edit-product-horizontal-cell-card-title'>
                                    IBU
                                </div>
                            </div>
                            <div className='modal-edit-product-horizontal-cell-input'>
                                <TextField
                                    margin='dense'
                                    id='bitterness_scale'
                                    name='bitterness_scale'
                                    type='number'
                                    fullWidth
                                    variant='outlined'
                                    step='0.1'
                                    value={safetyInput(this.state.newItem.bitterness_scale)}
                                    onInput={(e) =>
                                        this.onUniversalInput(
                                            e,
                                            this.state.newItem,
                                            'bitterness_scale',
                                            'newItem'
                                        )
                                    }
                                />
                            </div>
                        </div>
                    </div>
                    <div className='modal-edit-product-inputs-container'>
                        <TextField
                            margin='dense'
                            id='price'
                            name='price'
                            label='Цена за указанный объем'
                            type='number'
                            fullWidth
                            variant='outlined'
                            value={safetyInput(this.state.newItem.price)}
                            onInput={(e) =>
                                this.onUniversalInput(e, this.state.newItem, 'price', 'newItem')
                            }
                        />
                        <TextField
                            margin='dense'
                            id='beerVolume'
                            name='beerVolume'
                            label='Объем (например 0.5)'
                            type="number"
                            step='0.01'
                            fullWidth
                            variant='outlined'
                            value={safetyInput(this.state.newItem.volume)}
                            onInput={(e) =>
                                this.onUniversalInput(
                                    e,
                                    this.state.newItem,
                                    'volume',
                                    'newItem'
                                )
                            }
                        />
                    </div>
                    <div className='modal-edit-product-radio-buttons-container'>
                        {/*<MultiRadioButton*/}
                        {/*  title='Пастеризованное'*/}
                        {/*  values={this.state.pasteurizedValues}*/}
                        {/*  selected={this.state.newItem.pasteurized}*/}
                        {/*  selectValue={(value) =>*/}
                        {/*    this.onUniversalEdit(*/}
                        {/*      value,*/}
                        {/*      this.state.newItem,*/}
                        {/*      'pasteurized',*/}
                        {/*      'newItem'*/}
                        {/*    )*/}
                        {/*  }*/}
                        {/*/>*/}
                        <MultiRadioButton
                            title='Цвет пива'
                            values={this.state.beerColorValues}
                            selected={this.state.newItem.beer_color}
                            selectValue={(value) =>
                                this.onUniversalEdit(
                                    value,
                                    this.state.newItem,
                                    'beer_color',
                                    'newItem'
                                )
                            }
                        />
                        <MultiRadioButton
                            title='Фильтрованное'
                            values={this.state.filteredBeerValues}
                            selected={this.state.newItem.filtered}
                            selectValue={(value) =>
                                this.onUniversalEdit(
                                    value,
                                    this.state.newItem,
                                    'filtered',
                                    'newItem'
                                )
                            }
                        />
                    </div>
                    <div className='modal-edit-product-image-container'>
                        <InputLabel>Изображение</InputLabel>
                        <div className='customer-dashboard-modal-add-product-file-preview'>
                            {this.state.newItem.image ? (
                                <img
                                    alt='preview'
                                    src={
                                        this.state.newItem.image.image
                                            ? this.state.newItem.image.image
                                            : SERVER_URL + this.state.newItem.image.url
                                    }
                                />
                            ) : null}
                        </div>
                        <Button
                            component='label'
                            role={undefined}
                            variant='contained'
                            tabIndex={-1}>
                            Загрузить изображение
                            <VisuallyHiddenInput
                                id='image'
                                name='image'
                                type='file'
                                accept='image/*'
                                onChange={(e) =>
                                    this.fileInputChange(e, this.fileUploadHandler)
                                }
                            />
                        </Button>
                    </div>
                    <div className='modal-edit-product-bottom-buttons'>
                        <Button
                            classes={{root: 'modal-edit-product-bottom-button-save'}}
                            component='label'
                            role={undefined}
                            variant='contained'
                            tabIndex={-1}
                            onClick={() => this.sendCreating()}>
                            Добавить
                        </Button>
                        <Button
                            classes={{root: 'modal-edit-product-bottom-button-cancel'}}
                            component='label'
                            role={undefined}
                            variant='contained'
                            tabIndex={-1}
                            onClick={() => this.cancelCreating()}>
                            Отменить
                        </Button>
                    </div>
                </Dialog>

                <Dialog
                    scroll={Utils.isDeviceMobile() ? 'paper' : 'body'}
                    classes={{root: 'customer-dashboard-modal-edit-product'}}
                    open={this.state.editProductsOpen}
                    onClose={() => this.setEditProductsOpen(false)}
                    maxWidth={'sm'}
                    fullWidth={false}
                    PaperProps={{
                        component: 'form',
                        onSubmit: (event) => {
                            event.preventDefault();
                            const formData = new FormData(event.currentTarget);
                            const formJson = Object.fromEntries(formData.entries());
                            API.storeItemEdit(
                                formJson.name,
                                formJson.description,
                                formJson.category,
                                formJson.price,
                                this.state.imageBase64,
                                this.state.store.id,
                                this.state.editableItem.id
                            ).then((data) => {
                                this.updateOwnership();
                            });
                            this.setEditProductsOpen(false);
                        },
                    }}>
                    <div className='modal-edit-product-title-container'>
                        <div className='modal-edit-product-title'>
                            ID {this.state.editableItem.id}
                        </div>
                        <IconButton onClick={() => this.setEditProductsOpen(false)}>
                            <CloseRoundedIcon/>
                        </IconButton>
                    </div>
                    <div className='modal-edit-product-inputs-container'>
                        <TextField
                            margin='dense'
                            id='name'
                            name='name'
                            label='Название'
                            type='text'
                            fullWidth
                            variant='outlined'
                            value={safetyInput(this.state.editableItem.name)}
                            onInput={(e) =>
                                this.onUniversalInput(
                                    e,
                                    this.state.editableItem,
                                    'name',
                                    'editableItem'
                                )
                            }
                        />
                        {/*<TextField*/}
                        {/*  margin='dense'*/}
                        {/*  id='description'*/}
                        {/*  name='description'*/}
                        {/*  label='Описание'*/}
                        {/*  type='text'*/}
                        {/*  fullWidth*/}
                        {/*  variant='outlined'*/}
                        {/*  value={safetyInput(this.state.editableItem.description)}*/}
                        {/*  onInput={(e) =>*/}
                        {/*    this.onUniversalInput(*/}
                        {/*      e,*/}
                        {/*      this.state.editableItem,*/}
                        {/*      'description',*/}
                        {/*      'editableItem'*/}
                        {/*    )*/}
                        {/*  }*/}
                        {/*/>*/}
                        <TextField
                            margin='dense'
                            id='beerBrewery'
                            name='beerBrewery'
                            label='Пивоварня'
                            type='text'
                            fullWidth
                            variant='outlined'
                            value={safetyInput(this.state.editableItem.brewery)}
                            onInput={(e) =>
                                this.onUniversalInput(
                                    e,
                                    this.state.editableItem,
                                    'brewery',
                                    'editableItem'
                                )
                            }
                        />
                        <TextField
                            margin='dense'
                            id='beerSort'
                            name='beerSort'
                            label='Сорт'
                            type='text'
                            fullWidth
                            variant='outlined'
                            value={safetyInput(this.state.editableItem.beer_sort)}
                            onInput={(e) =>
                                this.onUniversalInput(
                                    e,
                                    this.state.editableItem,
                                    'beer_sort',
                                    'editableItem'
                                )
                            }
                        />
                    </div>
                    <div className='modal-edit-product-horizontal-cells'>
                        <div className='modal-edit-product-horizontal-cell'>
                            <div className='modal-edit-product-horizontal-cell-card'>
                                <div className='modal-edit-product-horizontal-cell-card-title'>
                                    Алкоголь
                                </div>
                            </div>
                            <div className='modal-edit-product-horizontal-cell-input'>
                                <TextField
                                    margin='dense'
                                    id='alcohol'
                                    name='alcohol'
                                    type='number'
                                    step='0.1'
                                    fullWidth
                                    variant='outlined'
                                    value={safetyInput(this.state.editableItem.alcohol_degrees)}
                                    onInput={(e) =>
                                        this.onUniversalInput(
                                            e,
                                            this.state.editableItem,
                                            'alcohol_degrees',
                                            'editableItem'
                                        )
                                    }
                                />
                            </div>
                        </div>
                        <div className='modal-edit-product-horizontal-cell'>
                            <div className='modal-edit-product-horizontal-cell-card'>
                                <div className='modal-edit-product-horizontal-cell-card-title'>
                                    Плотность
                                </div>
                            </div>
                            <div className='modal-edit-product-horizontal-cell-input'>
                                <TextField
                                    margin='dense'
                                    id='density'
                                    name='density'
                                    type='number'
                                    step='0.1'
                                    fullWidth
                                    variant='outlined'
                                    value={safetyInput(this.state.editableItem.alcohol_density)}
                                    onInput={(e) =>
                                        this.onUniversalInput(
                                            e,
                                            this.state.editableItem,
                                            'alcohol_density',
                                            'editableItem'
                                        )
                                    }
                                />
                            </div>
                        </div>
                        <div className='modal-edit-product-horizontal-cell'>
                            <div className='modal-edit-product-horizontal-cell-card'>
                                <div className='modal-edit-product-horizontal-cell-card-title'>
                                    IBU
                                </div>
                            </div>
                            <div className='modal-edit-product-horizontal-cell-input'>
                                <TextField
                                    margin='dense'
                                    id='bitterness_scale'
                                    name='bitterness_scale'
                                    step='0.1'
                                    type='number'
                                    fullWidth
                                    variant='outlined'
                                    value={safetyInput(this.state.editableItem.bitterness_scale)}
                                    onInput={(e) =>
                                        this.onUniversalInput(
                                            e,
                                            this.state.editableItem,
                                            'bitterness_scale',
                                            'editableItem'
                                        )
                                    }
                                />
                            </div>
                        </div>
                    </div>
                    <div className='modal-edit-product-inputs-container'>
                        <TextField
                            margin='dense'
                            id='price'
                            name='price'
                            label='Цена за указанный объем'
                            type='number'
                            fullWidth
                            variant='outlined'
                            value={safetyInput(this.state.editableItem.price)}
                            onInput={(e) =>
                                this.onUniversalInput(
                                    e,
                                    this.state.editableItem,
                                    'price',
                                    'editableItem'
                                )
                            }
                        />
                        <TextField
                            margin='dense'
                            id='beerVolume'
                            name='beerVolume'
                            label='Объем (например 0.5)'
                            type="number"
                            step='0.01'
                            fullWidth
                            variant='outlined'
                            value={safetyInput(this.state.editableItem.volume)}
                            onInput={(e) =>
                                this.onUniversalInput(
                                    e,
                                    this.state.editableItem,
                                    'volume',
                                    'editableItem'
                                )
                            }
                        />
                    </div>
                    <div className='modal-edit-product-radio-buttons-container'>
                        {/*<MultiRadioButton*/}
                        {/*  title='Пастеризованное'*/}
                        {/*  values={this.state.pasteurizedValues}*/}
                        {/*  selected={this.state.editableItem.pasteurized}*/}
                        {/*  selectValue={(value) =>*/}
                        {/*    this.onUniversalEdit(*/}
                        {/*      value,*/}
                        {/*      this.state.editableItem,*/}
                        {/*      'pasteurized',*/}
                        {/*      'editableItem'*/}
                        {/*    )*/}
                        {/*  }*/}
                        {/*/>*/}
                        <MultiRadioButton
                            title='Цвет пива'
                            values={this.state.beerColorValues}
                            selected={this.state.editableItem.beer_color}
                            selectValue={(value) =>
                                this.onUniversalEdit(
                                    value,
                                    this.state.editableItem,
                                    'beer_color',
                                    'editableItem'
                                )
                            }
                        />
                        <MultiRadioButton
                            title='Фильтрованное'
                            values={this.state.filteredBeerValues}
                            selected={this.state.editableItem.filtered}
                            selectValue={(value) =>
                                this.onUniversalEdit(
                                    value,
                                    this.state.editableItem,
                                    'filtered',
                                    'editableItem'
                                )
                            }
                        />
                    </div>
                    <div className='modal-edit-product-image-container'>
                        <InputLabel>Изображение</InputLabel>
                        <div className='customer-dashboard-modal-add-product-file-preview'>
                            {this.state.editableItem.image ? (
                                <img
                                    alt='preview'
                                    src={
                                        this.state.editableItem.image.image
                                            ? this.state.editableItem.image.image
                                            : SERVER_URL + this.state.editableItem.image.url
                                    }
                                />
                            ) : null}
                        </div>
                        <Button
                            component='label'
                            role={undefined}
                            variant='contained'
                            tabIndex={-1}>
                            Загрузить изображение
                            <VisuallyHiddenInput
                                id='image'
                                name='image'
                                type='file'
                                accept='image/*'
                                onChange={(e) =>
                                    this.fileInputChange(e, this.filePreviewHandler)
                                }
                            />
                        </Button>
                    </div>
                    <div className='modal-edit-product-bottom-buttons'>
                        <Button
                            classes={{root: 'modal-edit-product-bottom-button-save'}}
                            component='label'
                            role={undefined}
                            variant='contained'
                            tabIndex={-1}
                            onClick={() => this.sendChanges()}>
                            Сохранить
                        </Button>
                        <Button
                            classes={{root: 'modal-edit-product-bottom-button-cancel'}}
                            component='label'
                            role={undefined}
                            variant='contained'
                            tabIndex={-1}
                            onClick={() => this.cancelChanges()}>
                            Отменить
                        </Button>
                    </div>
                    <div
                        className='modal-edit-product-bottom-last'
                        onClick={() => this.deleteChanges()}>
                        <Button variant='text'>Удалить</Button>
                    </div>
                </Dialog>

                <Dialog
                    scroll={Utils.isDeviceMobile() ? 'paper' : 'body'}
                    classes={{root: 'customer-dashboard-modal-edit-product'}}
                    open={this.state.shopSettingsOpen}
                    onClose={() => this.setShopSettingsOpen(false)}
                    maxWidth={'sm'}
                    fullWidth={true}
                    PaperProps={{
                        component: 'form',
                        onSubmit: (event) => {
                            event.preventDefault();
                            // const formData = new FormData(event.currentTarget);
                            // const formJson = Object.fromEntries(formData.entries());
                            // API.storeItemEdit(formJson.name, formJson.description, formJson.category,
                            //     formJson.price, this.state.imageBase64, this.state.store.id, this.state.editableItem.id)
                            //     .then(data => {
                            //         this.updateOwnership()
                            //     })
                            this.setShopSettingsOpen(false);
                        },
                    }}>
                    <div className='modal-edit-product-title-container'>
                        <div className='modal-edit-product-title'>Настройки магазина</div>
                        <IconButton onClick={() => this.setShopSettingsOpen(false)}>
                            <CloseRoundedIcon/>
                        </IconButton>
                    </div>
                    <div className='modal-edit-product-inputs-container'>
                        <TextField
                            margin='dense'
                            id='name'
                            name='name'
                            label='Название'
                            type='text'
                            fullWidth
                            variant='outlined'
                            value={safetyInput(this.state.store.name)}
                            onInput={(e) =>
                                this.onUniversalInput(e, this.state.store, 'name', 'store')
                            }
                        />
                        <TextField
                            margin='dense'
                            id='address'
                            name='address'
                            label='Адрес'
                            type='text'
                            fullWidth
                            variant='outlined'
                            value={safetyInput(this.state.store.address)}
                            onInput={(e) =>
                                this.onUniversalInput(e, this.state.store, 'address', 'store')
                            }
                        />
                    </div>
                    <div className='modal-edit-product-bottom-buttons'>
                        <Button
                            classes={{root: 'modal-edit-product-bottom-button-save'}}
                            component='label'
                            role={undefined}
                            variant='contained'
                            tabIndex={-1}
                            onClick={() => this.sendShopSettings()}>
                            Сохранить
                        </Button>
                        <Button
                            classes={{root: 'modal-edit-product-bottom-button-cancel'}}
                            component='label'
                            role={undefined}
                            variant='contained'
                            tabIndex={-1}
                            onClick={() => this.cancelShopSettings()}>
                            Отменить
                        </Button>
                    </div>
                </Dialog>

                <Dialog
                    scroll={Utils.isDeviceMobile() ? 'paper' : 'body'}
                    classes={{root: 'customer-dashboard-modal-edit-product'}}
                    open={this.state.tagsSettingsOpen}
                    onClose={() => this.setTagsSettingsOpen(false)}
                    maxWidth={'sm'}
                    fullWidth={false}
                    PaperProps={{
                        component: 'form',
                        onSubmit: (event) => {
                            event.preventDefault();
                            // const formData = new FormData(event.currentTarget);
                            // const formJson = Object.fromEntries(formData.entries());
                            // API.storeItemEdit(formJson.name, formJson.description, formJson.category,
                            //     formJson.price, this.state.imageBase64, this.state.store.id, this.state.editableItem.id)
                            //     .then(data => {
                            //         this.updateOwnership()
                            //     })
                            this.setTagsSettingsOpen(false);
                        },
                    }}>
                    <div className='modal-edit-product-title-container'>
                        <div className='modal-edit-product-title'>
                            Теги
                        </div>
                        <IconButton onClick={() => this.setTagsSettingsOpen(false)}>
                            <CloseRoundedIcon/>
                        </IconButton>
                    </div>
                    <div className='modal-edit-product-inputs-container modal-tags-settings-tag-element'>
                        <TextField
                            margin='dense'
                            id='tag1_name'
                            name='tag1_name'
                            label='Тег 1'
                            type='text'
                            fullWidth
                            variant='outlined'
                            value={safetyInput(this.state.tagsMenuItem.tag1_name)}
                            onInput={(e) =>
                                this.onUniversalInput(
                                    e,
                                    this.state.tagsMenuItem,
                                    'tag1_name',
                                    'tagsMenuItem'
                                )
                            }
                        />
                        <div className='modal-edit-product-colors-container'>
                            <MultiRadioButton
                                values={this.state.tagsColorValues}
                                selected={this.state.tagsMenuItem.tag1_color}
                                colors={true}
                                selectValue={(value) =>
                                    this.onUniversalEdit(
                                        value,
                                        this.state.tagsMenuItem,
                                        'tag1_color',
                                        'tagsMenuItem'
                                    )
                                }
                            />
                        </div>
                    </div>

                    <div className='modal-edit-product-inputs-container modal-tags-settings-tag-element'>
                        <TextField
                            margin='dense'
                            id='tag2_name'
                            name='tag2_name'
                            label='Тег 2'
                            type='text'
                            fullWidth
                            variant='outlined'
                            value={safetyInput(this.state.tagsMenuItem.tag2_name)}
                            onInput={(e) =>
                                this.onUniversalInput(
                                    e,
                                    this.state.tagsMenuItem,
                                    'tag2_name',
                                    'tagsMenuItem'
                                )
                            }
                        />
                        <div className='modal-edit-product-colors-container'>
                            <MultiRadioButton
                                values={this.state.tagsColorValues}
                                selected={this.state.tagsMenuItem.tag2_color}
                                colors={true}
                                selectValue={(value) =>
                                    this.onUniversalEdit(
                                        value,
                                        this.state.tagsMenuItem,
                                        'tag2_color',
                                        'tagsMenuItem'
                                    )
                                }
                            />
                        </div>
                    </div>

                    <div className='modal-edit-product-inputs-container modal-tags-settings-tag-element'>
                        <TextField
                            margin='dense'
                            id='tag3_name'
                            name='tag3_name'
                            label='Тег 3'
                            type='text'
                            fullWidth
                            variant='outlined'
                            value={safetyInput(this.state.tagsMenuItem.tag3_name)}
                            onInput={(e) =>
                                this.onUniversalInput(
                                    e,
                                    this.state.tagsMenuItem,
                                    'tag3_name',
                                    'tagsMenuItem'
                                )
                            }
                        />
                        <div className='modal-edit-product-colors-container'>
                            <MultiRadioButton
                                values={this.state.tagsColorValues}
                                selected={this.state.tagsMenuItem.tag3_color}
                                colors={true}
                                selectValue={(value) =>
                                    this.onUniversalEdit(
                                        value,
                                        this.state.tagsMenuItem,
                                        'tag3_color',
                                        'tagsMenuItem'
                                    )
                                }
                            />
                        </div>
                    </div>

                    {/* <div className='modal-edit-product-inputs-container modal-tags-settings-tag-element'>
            <TextField
              margin='dense'
              id='tag4_name'
              name='tag4_name'
              label='Тег 4'
              type='text'
              fullWidth
              variant='outlined'
              value={safetyInput(this.state.tagsMenuItem.tag4_name)}
              onInput={(e) =>
                this.onUniversalInput(
                  e,
                  this.state.tagsMenuItem,
                  'tag4_name',
                  'tagsMenuItem'
                )
              }
            />
            <div className='modal-edit-product-colors-container'>
              <MultiRadioButton
                values={this.state.tagsColorValues}
                selected={this.state.tagsMenuItem.tag4_color}
                colors={true}
                selectValue={(value) =>
                  this.onUniversalEdit(
                    value,
                    this.state.tagsMenuItem,
                    'tag4_color',
                    'tagsMenuItem'
                  )
                }
              />
            </div>
          </div> */}

                    <div className='modal-edit-product-bottom-buttons'>
                        <Button
                            classes={{root: 'modal-edit-product-bottom-button-save'}}
                            component='label'
                            role={undefined}
                            variant='contained'
                            tabIndex={-1}
                            onClick={() => this.sendTagsSettings()}>
                            Сохранить
                        </Button>
                        <Button
                            classes={{root: 'modal-edit-product-bottom-button-cancel'}}
                            component='label'
                            role={undefined}
                            variant='contained'
                            tabIndex={-1}
                            onClick={() => this.cancelTagsSettings()}>
                            Отменить
                        </Button>
                    </div>
                </Dialog>

                <BeerEndedDialogComponent
                    onClose={() => this.setContentSettingsOpen(false)}
                    open={this.state.contentSettingsOpen}
                    owner={this.state.owner}
                    onSubmit={(data) => {
                        this.setBeerEnded(data);
                        this.setContentSettingsOpen(false);
                    }}
                />

                <Dialog
                    scroll={Utils.isDeviceMobile() ? 'paper' : 'body'}
                    classes={{root: 'customer-dashboard-modal-cranes'}}
                    open={this.state.cranesOpen}
                    onClose={() => this.setCranesOpen(false)}
                    maxWidth={'sm'}
                    fullWidth={false}
                    PaperProps={{
                        component: 'form',
                        onSubmit: (event) => {
                            event.preventDefault();

                            this.setCranesOpen(false);
                        },
                    }}>
                    <div className='modal-cranes-product-title-container'>
                        <div className='modal-cranes-product-title'>Назначить краны</div>
                        <IconButton onClick={() => this.setCranesOpen(false)}>
                            <CloseRoundedIcon/>
                        </IconButton>
                    </div>

                    <div className='modal-cranes-header-container'>
                        <div className='modal-edit-product-switch-container'>
                            Показывать полностью
                            <div className='modal-edit-product-horizontal-cell-card-switch'>
                                <Switch
                                    checked={this.state.cranesShowFull}
                                    onClick={() =>
                                        this.setState({
                                            cranesShowFull: !this.state.cranesShowFull,
                                        })
                                    }
                                />
                            </div>
                        </div>
                    </div>
                    <div className='modal-cranes-items-container'>
                        {this.state.owner
                            ? this.state.owner.cranes.map((crane, index) => {
                                let menuItem = crane.menu_item_id
                                    ? menuItemsIds[parseInt(crane.menu_item_id)]
                                    : {};
                                let beerColorValues = {
                                    null: null,
                                    light: 'Светлое',
                                    dark: 'Темное',
                                    sweet: 'Сладкое',
                                    red: 'Красное',
                                };
                                if (!menuItem) menuItem = {};

                                return (
                                    <div key={index} className='modal-cranes-item-container'>
                                        <div className='modal-cranes-item-header-container'>
                                            <div className='modal-cranes-item-header-number'>
                                                {crane.number}
                                            </div>
                                            <FormControl variant='outlined'>
                                                <div className='modal-cranes-item-header-select'>
                                                    <InputLabel
                                                        id={`modal-cranes-item-header-beer-${index}-label`}>
                                                        Позиция
                                                    </InputLabel>
                                                    <Select
                                                        MenuProps={{
                                                            className:
                                                                'modal-cranes-item-header-beer-select-menu',
                                                        }}
                                                        labelId={`modal-cranes-item-header-beer-${index}-label`}
                                                        id={`modal-cranes-item-header-beer-${index}`}
                                                        value={safetyInput(crane.menu_item_id)}
                                                        onChange={(e) =>
                                                            this.setCranePosition(e.target.value, crane)
                                                        }
                                                        label='Позиция'
                                                        autoWidth={true}>
                                                        <MenuItem key={-2} value=''></MenuItem>
                                                        <MenuItem key={-1} value='disable'>
                                                            Отключить
                                                        </MenuItem>
                                                        {this.state.owner.menu_items.map(
                                                            (menu_item, index) => {
                                                                return (
                                                                    <MenuItem key={index} value={menu_item.id}>
                                                                        {menuItem.image && menuItem.image.url ? (
                                                                            <img
                                                                                alt='menu'
                                                                                src={SERVER_URL + menuItem.image.url.split('?')[0]}
                                                                            />
                                                                        ) : null}
                                                                        {menu_item.name}
                                                                    </MenuItem>
                                                                );
                                                            }
                                                        )}
                                                    </Select>
                                                </div>
                                            </FormControl>
                                        </div>
                                        {this.state.cranesShowFull ||
                                        this.state.craneSelected === crane.id ? (
                                            <>
                                                <div className='modal-cranes-item-content-container'>
                                                    <div className='modal-cranes-item-content-field-container'>
                                                        <div className='modal-cranes-item-content-field-title'>
                                                            Алкоголь
                                                        </div>
                                                        <div className='modal-cranes-item-content-field-value'>
                                                            {menuItem.alcohol_degrees}
                                                        </div>
                                                    </div>
                                                    <div className='modal-cranes-item-content-field-container'>
                                                        <div className='modal-cranes-item-content-field-title'>
                                                            Плотность
                                                        </div>
                                                        <div className='modal-cranes-item-content-field-value'>
                                                            {menuItem.alcohol_density}
                                                        </div>
                                                    </div>
                                                    <div className='modal-cranes-item-content-field-container'>
                                                        <div className='modal-cranes-item-content-field-title'>
                                                            Цвет пива
                                                        </div>
                                                        <div className='modal-cranes-item-content-field-value'>
                                                            {menuItem.beer_color
                                                                ? beerColorValues[menuItem.beer_color]
                                                                : '—'}
                                                        </div>
                                                    </div>
                                                    <div className='modal-cranes-item-content-field-container'>
                                                        <div className='modal-cranes-item-content-field-title'>
                                                            Фильтрованное
                                                        </div>
                                                        <div className='modal-cranes-item-content-field-value'>
                                                            {menuItem.filtered === null
                                                                ? '—'
                                                                : menuItem.filtered
                                                                    ? 'Да'
                                                                    : 'Нет'}
                                                        </div>
                                                    </div>
                                                    <div className='modal-cranes-item-content-field-container'>
                                                        <div className='modal-cranes-item-content-field-title'>
                                                            Сорт
                                                        </div>
                                                        <div className='modal-cranes-item-content-field-value'>
                                                            {menuItem.beer_sort}
                                                        </div>
                                                    </div>
                                                    <div className='modal-cranes-item-content-field-container'>
                                                        <div className='modal-cranes-item-content-field-title'>
                                                            Рублей за литр
                                                        </div>
                                                        <div className='modal-cranes-item-content-field-value'>
                                                            {menuItem.price}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='modal-cranes-switch-container'>
                                                    Закончилось
                                                    <div className='modal-cranes-horizontal-cell-card-switch'>
                                                        <Switch
                                                            checked={crane.is_product_end}
                                                            onClick={() => this.craneEndProduct(crane)}
                                                        />
                                                    </div>
                                                </div>
                                            </>
                                        ) : null}
                                        {!this.state.cranesShowFull ? (
                                            <div
                                                className='modal-cranes-item-expand-button-container'
                                                onClick={() =>
                                                    this.setState({
                                                        craneSelected:
                                                            this.state.craneSelected === crane.id
                                                                ? null
                                                                : crane.id,
                                                    })
                                                }>
                                                <div
                                                    className={
                                                        this.state.craneSelected === crane.id
                                                            ? 'MenuSelectElement-left-arrow-opened'
                                                            : 'MenuSelectElement-left-arrow'
                                                    }>
                                                    <ArrowSelectIcon/>
                                                </div>
                                                {this.state.craneSelected === crane.id
                                                    ? 'Скрыть'
                                                    : 'Показать'}
                                            </div>
                                        ) : null}
                                    </div>
                                );
                            })
                            : null}
                    </div>
                    <div className='modal-edit-product-bottom-buttons'>
                        <div
                            className='modal-cranes-header-button'
                            onClick={this.shopCranesAddHandler}>
                            <div className='modal-cranes-header-button-icon'>+</div>
                            <div className='modal-cranes-header-button-content-container'>
                                <div className='modal-cranes-header-button-content-title'>
                                    Добавить кран
                                </div>
                                <div className='modal-cranes-header-button-content-label'>
                                    С конца, не более 60
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='modal-edit-product-bottom-buttons'>
                        <Button
                            classes={{root: 'modal-edit-product-bottom-button-save'}}
                            component='label'
                            role={undefined}
                            variant='contained'
                            tabIndex={-1}
                            onClick={() => this.saveCranes()}>
                            Сохранить
                        </Button>
                        <Button
                            classes={{root: 'modal-edit-product-bottom-button-cancel'}}
                            component='label'
                            role={undefined}
                            variant='contained'
                            tabIndex={-1}
                            onClick={() => this.cancelCranes()}>
                            Отменить
                        </Button>
                    </div>
                </Dialog>

                <Dialog
                    scroll={Utils.isDeviceMobile() ? 'paper' : 'body'}
                    classes={{root: 'customer-dashboard-modal-choose-crane'}}
                    open={this.state.chooseCranesOpen}
                    onClose={() => this.setChooseCranesOpen(false)}
                    maxWidth={'sm'}
                    fullWidth={true}
                    PaperProps={{
                        component: 'form',
                        onSubmit: (event) => {
                            event.preventDefault();

                            this.setChooseCranesOpen(false);
                        },
                    }}>
                    <div className='modal-cranes-product-title-container'>
                        <div className='modal-cranes-product-title'>Назначить на кран</div>
                        <IconButton onClick={() => this.setChooseCranesOpen(false)}>
                            <CloseRoundedIcon/>
                        </IconButton>
                    </div>

                    <div className='modal-choose-cranes-header-container'>
                        <div className='customer-dashboard-content-component-menu-item-avatar-row-full'>
                            {this.state.chooseCranesItem ? (
                                this.state.chooseCranesItem.image ? (
                                    <img
                                        alt='menu'
                                        src={SERVER_URL + this.state.chooseCranesItem.image.url.split('?')[0]}
                                    />
                                ) : null
                            ) : null}
                        </div>
                        <div
                            className='customer-dashboard-content-component-menu-item-text-row customer-dashboard-content-component-menu-item-text-row-full'>
                            <div
                                className='customer-dashboard-content-component-menu-item-text-name-row customer-dashboard-content-component-menu-item-text-name-row-full'>
                                {this.state.chooseCranesItem
                                    ? this.state.chooseCranesItem.name
                                    : null}
                            </div>
                        </div>
                    </div>

                    <div className='modal-choose-cranes-elements noSelect'>
                        <div
                            className='modal-choose-cranes-element-crane'
                            onClick={() => this.setState({selectedCranes: new Set()})}>
                            <MissingIcon/>
                        </div>
                        {this.state.owner
                            ? this.state.owner.cranes.map((crane, index) => {
                                const isFull = Boolean(
                                    typeof crane.menu_item_id !== 'undefined' &&
                                    crane.menu_item_id !== null
                                );
                                const isSelected = this.state.selectedCranes.has(crane.id);
                                const isDisabled = crane.menu_item_id === 'disable';

                                let classList = ['modal-choose-cranes-element-crane'];
                                if (isFull)
                                    classList.push('modal-choose-cranes-element-full');
                                if (isSelected)
                                    classList.push('modal-choose-cranes-element-selected');
                                if (isDisabled)
                                    classList.push('modal-choose-cranes-element-disabled');
                                let className = classList.join(' ');

                                return (
                                    <div
                                        key={index}
                                        className={className}
                                        onClick={() => {
                                            if (!isDisabled) this.cranActionHandler(crane.id);
                                        }}>
                                        {crane.number}
                                        {isFull && !isSelected && !isDisabled ? (
                                            <span>занят</span>
                                        ) : null}
                                    </div>
                                );
                            })
                            : null}
                    </div>
                    <div className='modal-edit-product-bottom-buttons'>
                        <div
                            className='modal-cranes-header-button'
                            onClick={this.shopCranesAddHandler}>
                            <div className='modal-cranes-header-button-icon'>+</div>
                            <div className='modal-cranes-header-button-content-container'>
                                <div className='modal-cranes-header-button-content-title'>
                                    Добавить кран
                                </div>
                                <div className='modal-cranes-header-button-content-label'>
                                    С конца, не более 60
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='modal-edit-product-bottom-buttons'>
                        <Button
                            classes={{root: 'modal-edit-product-bottom-button-save'}}
                            component='label'
                            role={undefined}
                            variant='contained'
                            tabIndex={-1}
                            onClick={() => this.cranItemSave()}>
                            Сохранить
                        </Button>
                        <Button
                            classes={{root: 'modal-edit-product-bottom-button-cancel'}}
                            component='label'
                            role={undefined}
                            variant='contained'
                            tabIndex={-1}
                            onClick={() => this.cancelCranItem()}>
                            Отменить
                        </Button>
                    </div>
                </Dialog>
            </div>
        );
    }

    render() {
        if (this.state.waitLabel) {
            return renderWaitLabel(this.state.contacts);
        } else if (this.state.waitLabel === null) {
            return null;
        }
        let products = this.state.owner
            ? renderProducts(
                this.setPositionEditOpen,
                this.state.positionEditOpen,
                this.state.positionEditItemId,
                this.setPositionEditItemId,
                this.state.screensPosition,
                this.state.displayType,
                this.state.owner,
                this.modelAction,
                this.setAddProductsOpen,
                this.startEditItem,
                this.setTagsSettingsOpen,
                this.state.order,
                this.state.orderBy,
                this.state.dashboardSortType,
                this.state.editAll,
                this.setInputMenuItemField,
                this.setChooseCranesOpen,
                this.state.cranesItemsUnion,
                this.state.cranesItemsNumbers,
                this.state.searchValue
            )
            : [];
        return (
            <HeaderMenu
                header={
                    <>
                        {this.state.owner
                            ? renderShop(
                                this.state.owner,
                                this.setAddProductsOpen,
                                this.setShopSettingsOpen,
                                this.setManyMenuOpen,
                                this.state.manyMenuOpen,
                                this.state.stores,
                                this.state.profileMenuOpen,
                                this.setProfileMenuOpen,
                                this.setContentSettingsOpen,
                                this.setCranesOpen,
                                this.state.isBlocked
                            )
                            : null}
                    </>
                }
                subNotification={this.state.notification}
                contentClass='customer-dashboard-container-main'
                content={this.renderInternal(products)}
            />
        );
    }
}

export default CustomerDashboard;
