import {get, post, url} from './utils/request';

class API {
  static csrf() {
    return get(url('/api/csrf/'));
  }

  static login(email, password) {
    const data = {
      email: email,
      password: password,
    };
    return post(url('/api/auth/login/'), data);
  }

  static storeChannel(webKey, needFullData) {
    return get(url(`/api/store/channel/${webKey}/`, {full_data: needFullData}));
  }

  static profile() {
    return get(url('/api/profile/'));
  }

  static userNotifications({storeId}) {
      const query = {store_id: storeId};
      return get(url('/api/user/notifications/', query))
  }

  static updateDeviceConnection(fingerprint) {
    return get(url('/api/connect/device/update/', {fingerprint: fingerprint}));
  }

  static profileStore(storeId) {
    return get(url('/api/profile/store/', {store_id: storeId}));
  }

  static profileStores() {
    return get(url('/api/profile/stores/'));
  }

  static profileOwnership(storeId) {
    let query = storeId ? {store_id: storeId} : {};
    return get(url('/api/profile/ownership/', query));
  }

  static connectDeviceRegister({
    fingerprint,
    storeId,
    screenNumber,
    deviceLabel,
  }) {
    const data = {
      fingerprint: fingerprint,
      store_id: storeId,
      screen_number: screenNumber,
      device_label: deviceLabel,
    };
    return post(url('/api/connect/device/register/'), data);
  }

  static sendScreenshot({screenshot, webKey, windowNumber}) {
    const data = {
      screenshot: screenshot,
      web_key: webKey,
      window_number: windowNumber,
    };
    return post(url('/api/device/screenshot/update/'), data);
  }

  static shopLayoutsDevicesSave({storeId, layouts, devices}) {
    const data = {
      store_id: storeId,
      layouts: layouts,
      devices: devices,
    };
    return post(url('/api/shop/layouts/devices/save/'), data);
  }

  static cranElementSave({storeId, craneIds, menuItemId}) {
    const data = {
      store_id: storeId,
      crane_ids: craneIds,
      menu_item_id: menuItemId,
    };
    return post(url('/api/shop/cran/entity/save/'), data);
  }

  static libraryInstances() {
    return get(url('/api/library/instances/'));
  }

  static librarySearch(searchValue) {
    let queryParams = {
      search_query: searchValue,
    };
    return get(url('/api/library/search/', queryParams));
  }

  static libraryEntity(entityId) {
    let queryParams = {entity_id: entityId};
    return get(url('/api/library/entity/', queryParams));
  }

  static libraryData({templateId}) {
    let queryParams = {
      template_id: templateId,
    };
    return get(url('/api/library/data/', queryParams));
  }

  static shopCranesSave({storeId, cranes}) {
    const data = {
      store_id: storeId,
      cranes: cranes,
    };
    return post(url('/api/shop/cranes/save/'), data);
  }

  static shopCranesAdd({storeId}) {
    const data = {
      store_id: storeId,
    };
    return post(url('/api/shop/cranes/add/'), data);
  }

  static shopMassEditSave({storeId, menuItems}) {
    const data = {
      store_id: storeId,
      menu_items: menuItems,
    };
    return post(url('/api/shop/mass/edit/save/'), data);
  }

  static contacts() {
    return get(url('/api/contacts/'));
  }

  static categories() {
    return get(url('/api/categories/'));
  }

  static saveChangedMenuItem(menuItem) {
    let genericSelect = {
      true: true,
      false: false,
      null: null,
    };

    menuItem.pasteurized = genericSelect[menuItem.pasteurized];
    menuItem.filtered = genericSelect[menuItem.filtered];

    const data = {
      menu_item: menuItem,
    };
    return post(url('/api/menuitem/changed/save/'), data);
  }

  static deleteMenuItem({storeId, menuItemId}) {
    const data = {
      store_id: storeId,
      menu_item_id: menuItemId,
    };
    return post(url('/api/menuitem/entity/delete/'), data);
  }

  static saveAdminEditorCreatingMenuItem(menuItem) {
    const data = {
      menu_item: menuItem,
    };
    return post(url('/api/admin/base/editor/new/menuitem/creating/save/'), data);
  }

  static saveAdminEditorChangedMenuItem(menuItem) {
    const data = {
      menu_item: menuItem,
    };
    return post(url('/api/admin/base/editor/new/menuitem/changed/save/'), data);
  }

  static deleteAdminEditorMenuItem({menuItemId}) {
    const data = {
      menu_item_id: menuItemId,
    };
    return post(url('/api/admin/base/editor/new/menuitem/entity/delete/'), data);
  }

  static saveAdminEditorObjectSave(entity, isNew, objectType) {
    const data = {
      entity: entity,
      is_new: isNew,
      object_type: objectType
    }
    return post(url('/api/admin/base/editor/new/object/entity/save/'), data)
  }

  static deleteAdminEditorObjectSave(entity_id, objectType) {
    const data = {
      entity_id: entity_id,
      object_type: objectType
    }
    return post(url('/api/admin/base/editor/new/object/entity/delete/'), data)
  }

  static listBanners({storeId}) {
      const query = {store_id: storeId};
      return get(url('/api/store/editor/banners/', query))
  }

  static addBanner({store_id, type, text, color_ndex, text_color, background_color, image, size}) {
      const data = {
        type: type,
        text: text,
        color_index: color_ndex,
        text_color: text_color,
        background_color: background_color,
        image: image,
        size: size,
        store_id: store_id,
      }
      return post(url('/api/store/editor/banners/create/'), data)
  }

  static deleteBanner({store_id, banner_id}) {
      const data = {
          store_id: store_id,
          banner_id: banner_id
      }
      return post(url('/api/store/editor/banners/delete/'), data)
  }

  static editBanner({store_id, type, text, color_index, text_color, background_color, image, size, id}) {
      const data = {
        store_id: store_id,
        banner_id: id,
        type: type,
        text: text,
        color_index: color_index,
        text_color: text_color,
        background_color: background_color,
        image: image,
        size: size,
      }
      return post(url('/api/store/editor/banners/edit/'), data)
  }

  static editorSave({storeId, screens}) {
      const data = {
        store_id: storeId,
        screens: screens
      }
      return post(url('/api/store/editor/save/'), data)
  }

  static saveShopSettings(shop) {
    const data = {
      shop: shop,
    };
    return post(url('/api/shop/settings/save/'), data);
  }

  static saveTagsSettings(menuItem) {
    const data = {
      menu_item: menuItem,
    };
    return post(url('/api/tags/settings/save/'), data);
  }

  static saveCreatingMenuItem(menuItem, storeId) {
    let genericSelect = {
      true: true,
      false: false,
      null: null,
    };

    menuItem.store_id = storeId;
    menuItem.pasteurized = genericSelect[menuItem.pasteurized];
    menuItem.filtered = genericSelect[menuItem.filtered];

    const data = {
      menu_item: menuItem,
    };
    return post(url('/api/menuitem/creating/save/'), data);
  }

  static storeItemAdd(name, description, category, price, image, storeId) {
    const data = {
      name: name,
      description: description,
      category: category,
      price: price,
      image: image,
      store_id: storeId,
    };
    return post(url('/api/store/item/add/'), data);
  }

  static storeItemEdit(
    name,
    description,
    category,
    price,
    image,
    storeId,
    productId
  ) {
    const data = {
      name: name,
      description: description,
      category: category,
      price: price,
      image: image,
      store_id: storeId,
      product_id: productId,
    };
    return post(url('/api/store/item/edit/'), data);
  }

  static adminInternal() {
    return get(url('/api/admin/internal/'));
  }

  static adminInternalModel(tableName, forEdit) {
    const query = {table_name: tableName, for_edit: forEdit};
    return get(url('/api/admin/internal/model/', query));
  }

  static adminInternalModelEntity(tableName, columnValue) {
    const query = {table_name: tableName, column_value: columnValue};
    return get(url('/api/admin/internal/model/entity/', query));
  }

  static adminInternalModelEntitySave(tableName, columnValue, entityData) {
    const data = {
      table_name: tableName,
      column_value: columnValue,
      entity_data: entityData,
    };
    return post(url('/api/admin/internal/model/entity/save/'), data);
  }

  static adminInternalModelEntityDelete(tableName, columnValue) {
    const data = {
      table_name: tableName,
      column_value: columnValue,
    };
    return post(url('/api/admin/internal/model/entity/delete/'), data);
  }

  static adminInternalModelEntityAdd(tableName, entityData) {
    const data = {
      table_name: tableName,
      entity_data: entityData,
    };
    return post(url('/api/admin/internal/model/entity/add/'), data);
  }

  static async getItemsPosition(storeId) {
    const response = await get(
      url('/api/store/positions/', {
        store_id: storeId,
      })
    );

    return response;
  }

  static async setItemsPosition(body) {
    const response = await post(url('/api/store/positions/set/'), body);

    return response;
  }

  static async setBeerEnded(body) {
    const response = await post(url('/api/shop/crane/change/'), body);

    return response;
  }

  static adminBaseEditorNewList(search_query, page, per_page) {
      let query = {search_query, page, per_page}
      return get(url('/api/admin/base/editor/new/list/', query))
  }

  static adminBaseEditorNewObjects() {
    return get(url('/api/admin/base/editor/new/objects/'))
  }
}

export default API;
