import React, {useState} from 'react';
import cl from './admin-page.module.css';
import RedesignHeader from '../../redesign-header/redesign-header.component';
import AdminMainPage from './admin-main-page/admin-main-page.component';
import BeerDbEditorComponent from './admin-beer-db-editor/beer-db-editor.component';
import AdminShopsClientsComponent from './admin-shops-clients/admin-shops-clients.component';

function AdminHeaderTools() {
  return <span className={cl.adminHeaderTitle}>Админка</span>;
}

function AdminHeaderBodyComponent({selectedTab, onChangeTab}) {
  return (
    <div className={cl.tabsComposition}>
      <button
        onClick={() => onChangeTab(0)}
        className={selectedTab === 0 ? cl.buttonsSelected : cl.buttons}
      >
        Главная
      </button>
      <button
        onClick={() => onChangeTab(1)}
        className={selectedTab === 1 ? cl.buttonsSelected : cl.buttons}
      >
        Магазины и&nbsp;клиенты
      </button>
      <button
        onClick={() => onChangeTab(2)}
        className={selectedTab === 2 ? cl.buttonsSelected : cl.buttons}
      >
        Редактор базы пива
      </button>
        <button
        onClick={() => window.location.href = '/admin/editor/'}
        className={selectedTab === 2 ? cl.buttonsSelected : cl.buttons}
      >
        Редактор новой базы пива
      </button>
    </div>
  );
}

function AdminPage() {
  const [selectedTab, setSelectedTab] = useState(0);

  return (
    <div className={cl.pageWrapper}>
      <RedesignHeader
        HeaderToolsComponent={AdminHeaderTools}
        BodyComponent={AdminHeaderBodyComponent}
        propsForBody={{selectedTab, onChangeTab: setSelectedTab}}
      />

      {selectedTab === 0 && <AdminMainPage />}

      {selectedTab === 1 && <AdminShopsClientsComponent />}

      {selectedTab === 2 && <BeerDbEditorComponent />}
    </div>
  );
}

export default AdminPage;
