import {get, url} from '../../../../../../utils/request';

const MOCK_DATA = {
  income: '14 000', // Мой доход
  currentMRR: {
    amount: '42 000', // Текущий MRR
    growth: '+10%', // Рост MRR
  },
  totalIncomeForMonth: '141 242', // Общий доход за месяц
  connectedClients: 100, // Подключенные клиенты
  unpaidClients: 200, // Не оплативших клиентов
  activeLocations: 200, // Активные точки
  unpaidAmount: '141 522', // Денег не оплачено
  connectionRequests: 200, // Заявки на подключение
};

// {
//   "connected_clients": 5, // Подключенные клиенты
//   "current_mrr": 4386.0, // Текущий MRR
//   "money_not_paid": 23086.0, // Денег не оплачено
//   "my_profit": 0.0, // Мой доход
//   "not_paid_shops": 5, // Не оплативших клиентов
//   "paid_shops": 1, // Подключенные клиенты
//   "status": true
// }

export default class IncomeDataService {
  static async getIncomeData() {
    const response = await get(url('/api/admin/public/profit/'));

    // return response;
    return response;
  }
}
