import React, {useEffect, useState} from 'react';
import cl from './admin-main-page.module.css';
import IncomeDataService from './services/income-data.service';
import HowAboutMansService from './services/how-about-mans.service';
import {
  Box,
  Button,
  CircularProgress,
  Collapse,
  Dialog,
  IconButton,
  MenuItem,
  Pagination,
  Paper,
  Select,
  Slider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import {ReactComponent as ReloadIcon} from '../../../../../images/customIconReload.svg';
import RequestsService from './services/requests.service';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';

function Income() {
  const [incomeData, setIncomeData] = useState(null);

  useEffect(() => {
    IncomeDataService.getIncomeData()
      .then((res) => setIncomeData(res))
      .catch((e) =>
        alert(
          `Бэкендер вместо инфы по блоку "Доход" решил отправить следующее: ${e}`
        )
      );
  }, []);

  return (
    <div className={cl.blockWrapper}>
      <div className={cl.blockTitle}>Доход 🤑</div>

      {incomeData && (
        <div className={cl.cardMeta}>
          <div className={cl.cardPoint}>
            <span className={cl.pointHeader}>Мой доход</span>
            <span className={cl.pointValue}>{incomeData.my_profit}</span>
          </div>

          <div className={cl.cardPoint}>
            <span className={cl.pointHeader}>Подключенные клиенты</span>
            <span className={cl.pointValue}>
              {incomeData.connected_clients}
            </span>
          </div>

          <div className={cl.cardPoint}>
            <span className={cl.pointHeader}>Магазинов не&nbsp;оплачено</span>
            <span className={cl.pointValue}>{incomeData.not_paid_shops}</span>
          </div>

          <div className={cl.cardPoint}>
            <span className={cl.pointHeader}>Магазинов оплачено</span>
            <span className={cl.pointValue}>{incomeData.paid_shops}</span>
          </div>

          <div className={cl.cardPoint}>
            <span className={cl.pointHeader}>Текущий MRR</span>
            <span className={cl.pointValue}>{incomeData.current_mrr}</span>
          </div>

          {/* <div className={cl.cardPoint}>
            <span className={cl.pointHeader}>Активные точки</span>
            <span className={cl.pointValue}>{incomeData.activeLocations}</span>
          </div> */}

          <div className={cl.cardPoint}>
            <span className={cl.pointHeader}>Денег не оплачено</span>
            <span
              className={cl.pointValue}
              style={
                parseInt(String(incomeData.money_not_paid).replace(/ /g, '')) >
                0
                  ? {color: '#FF3B30'}
                  : {}
              }
            >
              {incomeData.money_not_paid}
            </span>
          </div>

          {/* <div className={cl.cardPoint}>
            <span className={cl.pointHeader}>Общий доход за&nbsp;месяц</span>
            <span className={cl.pointValue}>
              {incomeData.totalIncomeForMonth}
            </span>
          </div> */}

          {/* <div className={cl.cardPoint}>
            <span className={cl.pointHeader}>Заявки на подключение</span>
            <span className={cl.pointValue}>
              {incomeData.connectionRequests}
            </span>
          </div> */}
        </div>
      )}
    </div>
  );
}

function HowAboutMans() {
  const [aboutMansData, setAboutMansData] = useState(null);
  const [rotate, setRotate] = useState(false);

  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const handleClick = () => {
    onResetStates();
    getData();

    setRotate(true);

    setTimeout(() => {
      setRotate(false);
    }, 1000);
  };

  const onResetStates = () => {
    setSearchTerm('');
    setCurrentPage(1);
  };

  const getData = () => {
    HowAboutMansService.getHowAreMans().then((res) => setAboutMansData(res));
  };

  useEffect(() => {
    getData();
  }, [currentPage, searchTerm]);

  return (
    <div className={cl.blockWrapper}>
      <div className={cl.blockHeader}>
        <div className={cl.titleWithAction}>
          <ReloadIcon
            id='reload-icon'
            className={`${cl.reload} ${rotate ? cl.rotate : ''}`}
            onClick={handleClick}
          />
          <span className={cl.blockTitle}>Чё&nbsp;там у&nbsp;мужиков 👨‍❤️‍👨</span>
        </div>

        <div className={cl.sortsForBlock}></div>
      </div>

      {aboutMansData && (
        <ActivityLog
          data={aboutMansData}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
          totalPages={totalPages}
          setTotalPages={setTotalPages}
        />
      )}
    </div>
  );
}

function ActivityLog({
  data,
  searchTerm,
  setSearchTerm,
  currentPage,
  setCurrentPage,
  totalPages,
  setTotalPages,
}) {
  // Обработчик смены страницы
  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  return (
    <Box sx={{maxWidth: '100%', padding: '20px'}}>
      {/* Поиск */}
      <TextField
        label='Поиск'
        variant='outlined'
        fullWidth
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        sx={{marginBottom: '20px'}}
      />

      {/* Список логов */}
      <Box className={cl.logList}>
        {data.length
          ? data.map((item) => (
              <Paper
                key={item.id}
                className={cl.logItem}
                sx={{padding: '10px', marginBottom: '10px'}}
              >
                <Box
                  className={cl.logTime}
                  sx={{color: '#888', fontSize: '12px'}}
                >
                  <Typography sx={{color: '#01ADFF'}}>{item.time}</Typography>
                  <Typography>{item.date}</Typography>
                </Box>
                <Box
                  className={cl.logContent}
                  sx={{marginLeft: '20px', flexGrow: 1}}
                >
                  <Typography sx={{color: '#808080'}} variant='subtitle2'>
                    {item.user}
                  </Typography>
                  <Typography variant='body2'>{item.action}</Typography>
                </Box>
                <Box
                  className={cl.logLabel}
                  sx={{
                    backgroundColor: '#f0f0f0',
                    padding: '5px 10px',
                    borderRadius: '5px',
                    fontSize: '12px',
                  }}
                >
                  {item.label}
                </Box>
              </Paper>
            ))
          : ''}
      </Box>

      {/* Пагинация */}
      <Pagination
        count={totalPages}
        page={currentPage}
        onChange={handlePageChange}
        color='primary'
        sx={{display: 'flex', justifyContent: 'center', marginTop: '20px'}}
      />
    </Box>
  );
}

const MARKS = [
  {
    value: 0,
    label: '1',
  },
  {
    value: 20,
    label: '2',
  },
  {
    value: 40,
    label: '3',
  },
  {
    value: 60,
    label: '4',
  },
  {
    value: 80,
    label: '5',
  },
  {
    value: 100,
    label: '5+',
  },
];

function RequestsBlock() {
  const [requests, setRequests] = useState(null);
  const [rotate, setRotate] = useState(false);

  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [openRows, setOpenRows] = useState({});
  const [newCommentText, setNewCommentText] = useState({});
  const [statuses, setStatuses] = useState(null);
  const [totalPages, setTotalPages] = useState(1);

  //form controls modal
  const [companyName, setCompanyName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [selectedPeriod, setSelectedPeriod] = useState(0);
  // const [firstComment, setFirstComment] = useState('');

  const [newRequestModalOpen, setNewRequestModalOpen] = useState(false);
  const onCloseNewRequestModal = () => {
    setNewRequestModalOpen(false);
  };

  const handleClick = () => {
    onResetStates();
    getData();

    setRotate(true);

    setTimeout(() => {
      setRotate(false);
    }, 1000);
  };

  const isFieldsEmpty = () => {
    return phoneNumber === '' || companyName === '';
  };

  const onResetStates = () => {
    setSearchTerm('');
    setCurrentPage(1);
  };

  const getData = () => {
    RequestsService.getRequests(searchTerm, currentPage).then((res) => {
      setRequests(res.requests);
      setTotalPages(Math.ceil(res.total / 5));
    });
    RequestsService.getStatuses().then((res) => setStatuses(res.statuses));
  };

  useEffect(() => {
    getData();
  }, [searchTerm, currentPage]);

  const onSaveRequest = () => {
    let shops;

    switch (selectedPeriod) {
      case 0:
        shops = 1;
        break;

      case 20:
        shops = 2;
        break;

      case 40:
        shops = 3;
        break;

      case 60:
        shops = 4;
        break;

      case 80:
        shops = 5;
        break;

      case 100:
        shops = 6;
        break;

      default:
        shops = 0;
        break;
    }

    RequestsService.createNewRequest(companyName, shops, phoneNumber)
      .then(() => {
        getData();
        onCloseNewRequestModal();
      })
      .catch((e) => alert(`Заявка не создана, ошибка ${e}`));
  };

  // Функция для добавления комментария
  const handleAddComment = (appId, newComment) => {
    RequestsService.addCommentById(appId, newComment).then(({comment}) => {
      setRequests((prevData) =>
        prevData.map((item) =>
          item.id === appId
            ? {
                ...item,
                comments:
                  item.comments !== null && item.comments.length > 0
                    ? [
                        ...item.comments,
                        {
                          hidden: comment.hidden,
                          created_at: comment.created_at,
                          id: comment.id,
                          request_id: comment.id,
                          text: comment.text,
                        },
                      ]
                    : [
                        {
                          hidden: comment.hidden,
                          created_at: comment.created_at,
                          id: comment.id,
                          request_id: comment.id,
                          text: comment.text,
                        },
                      ],
              }
            : item
        )
      );
    });
  };

  // Функция для удаления комментария
  const handleDeleteComment = (comment_id, appId) => {
    RequestsService.deleteCommentById(comment_id).then((updatedComments) => {
      setRequests((prevData) =>
        prevData.map((item) =>
          item.id === appId
            ? {
                ...item,
                comments:
                  item.comments !== null && item.comments.length > 0
                    ? [
                        ...item.comments.filter(
                          (comment) => comment.id !== comment_id
                        ),
                      ]
                    : null,
              }
            : item
        )
      );
    });
  };

  return (
    <div className={cl.blockWrapper}>
      <div className={cl.blockHeader}>
        <div className={cl.titleWithAction}>
          <ReloadIcon
            id='reload-icon'
            className={`${cl.reload} ${rotate ? cl.rotate : ''}`}
            onClick={handleClick}
          />
          <span className={cl.blockTitle}>Заявки</span>
        </div>

        <button
          onClick={() => setNewRequestModalOpen(true)}
          className={cl.onNewRequest}
        >
          Хуйнуть новую заявку
        </button>
      </div>

      <Dialog
        maxWidth={'sm'}
        fullWidth={false}
        scroll={'body'}
        classes={{root: 'modal-styles-root'}}
        onClose={onCloseNewRequestModal}
        open={newRequestModalOpen}
      >
        <div className={cl.modalWrapper}>
          <div className={cl.modalHeader}>
            <div className={cl.modalTitle}>Модалка бля</div>

            <IconButton onClick={() => onCloseNewRequestModal()}>
              <CloseRoundedIcon />
            </IconButton>
          </div>

          <div className={cl.modalBody}>
            <TextField
              label='Название компании, сети, заведения'
              variant='outlined'
              type='text'
              value={companyName}
              sx={{width: '100%'}}
              onInput={(e) => setCompanyName(e.target.value)}
            />

            <TextField
              label='Любой контакт'
              variant='outlined'
              type='text'
              value={phoneNumber}
              sx={{width: '100%'}}
              onInput={(e) => setPhoneNumber(e.target.value)}
            />

            <Slider
              label='Сколько точек у клиента'
              value={selectedPeriod}
              onChange={(e) => setSelectedPeriod(e.target.value)}
              defaultValue={20}
              step={20}
              marks={MARKS}
            />

            {/* <TextField
              label='Первый коммент, если нужен'
              variant='outlined'
              type='text'
              value={firstComment}
              onInput={(e) => setFirstComment(e.target.value)}
            /> */}
          </div>

          <button
            onClick={() => onSaveRequest()}
            disabled={isFieldsEmpty()}
            className={cl.modalSaveAction}
          >
            Добавить заявку
          </button>
        </div>
      </Dialog>

      {requests && (
        <Applications
          data={requests}
          onAddComment={handleAddComment}
          onDeleteComment={handleDeleteComment}
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          openRows={openRows}
          setOpenRows={setOpenRows}
          newCommentText={newCommentText}
          setNewCommentText={setNewCommentText}
          statuses={statuses}
          totalPages={totalPages}
          setStatuses={setStatuses}
        />
      )}
    </div>
  );
}

function Applications({
  data,
  onAddComment,
  onDeleteComment,
  searchTerm,
  setSearchTerm,
  currentPage,
  setCurrentPage,
  openRows,
  setOpenRows,
  newCommentText,
  setNewCommentText,
  statuses,
  totalPages,
  setStatuses,
}) {
  const handleToggleRow = (id) => {
    setOpenRows((prevState) => ({...prevState, [id]: !prevState[id]}));
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const handleStatusChange = (status_id, id) => {
    RequestsService.setStatusForRequest(id, status_id);
  };

  const handleDeleteComment = (comment_id, itemId) => {
    onDeleteComment(comment_id, itemId);
  };

  return (
    <Box sx={{padding: '20px'}}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: '20px',
        }}
      >
        <TextField
          label='Поиск'
          value={searchTerm}
          onChange={handleSearchChange}
          variant='outlined'
          size='small'
          fullWidth={true}
        />
      </Box>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell sx={{color: '#C1C1C1', fontSize: '12px'}}>
                Дата
              </TableCell>
              <TableCell sx={{color: '#C1C1C1', fontSize: '12px'}}>
                Название магазина
              </TableCell>
              <TableCell sx={{color: '#C1C1C1', fontSize: '12px'}}>
                Контакт
              </TableCell>
              <TableCell sx={{color: '#C1C1C1', fontSize: '12px'}}>
                Точек
              </TableCell>
              <TableCell sx={{color: '#C1C1C1', fontSize: '12px'}}>
                Статус
              </TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {data ? (
              data.map((item) => (
                <React.Fragment key={item.id}>
                  <TableRow>
                    <TableCell sx={{fontSize: '16px'}}>
                      {new Date(item.created_at).toLocaleDateString() +
                        ' ' +
                        new Date(item.created_at).toLocaleTimeString()}
                    </TableCell>
                    <TableCell sx={{fontSize: '16px'}}>
                      {item.store_name}
                    </TableCell>
                    <TableCell sx={{fontSize: '16px'}}>
                      {item.contact}
                    </TableCell>
                    <TableCell sx={{fontSize: '16px'}}>{item.shops}</TableCell>
                    <TableCell>
                      {statuses !== null && (
                        <Select
                          value={
                            statuses.find(
                              (status) => item.status_id === status.id
                            ).name
                          }
                          onChange={(e) => {
                            const statusId = statuses.find(
                              (status) => status.name === e.target.value
                            ).id;

                            handleStatusChange(statusId, item.id);
                            item.status_id = statusId;

                            // костыль чтобы апдейтить ебучее состояние
                            const curStatuses = [...statuses];
                            setStatuses(null);
                            setStatuses(curStatuses);
                          }}
                          size='small'
                          sx={{
                            color: `${
                              statuses.find(
                                (status) => item.status_id === status.id
                              ).color
                            }`,
                            fontWeight: 'bold',
                          }}
                        >
                          {statuses.map((status) => {
                            return (
                              <MenuItem
                                key={status.id}
                                sx={{color: `${status.color}`}}
                                value={status.name}
                              >
                                {status.name}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      )}
                    </TableCell>
                    <TableCell>
                      <IconButton onClick={() => handleToggleRow(item.id)}>
                        {openRows[item.id] ? (
                          <KeyboardArrowUpIcon />
                        ) : (
                          <KeyboardArrowDownIcon />
                        )}
                      </IconButton>
                    </TableCell>
                  </TableRow>

                  {/* Раскрывающаяся информация (comments) */}
                  <TableRow>
                    <TableCell
                      style={{paddingBottom: 0, paddingTop: 0}}
                      colSpan={6}
                    >
                      <Collapse
                        in={openRows[item.id]}
                        timeout='auto'
                        unmountOnExit
                      >
                        <Box margin={1}>
                          {item.comments !== null &&
                            item.comments.length > 0 &&
                            item.comments.map((comment, index) => (
                              <Box key={index} sx={{marginBottom: '10px'}}>
                                <div style={{display: 'flex', gap: '8px'}}>
                                  <Typography
                                    sx={{color: '#C1C1C1'}}
                                    variant='body2'
                                    gutterBottom
                                  >
                                    {new Date(
                                      comment.created_at
                                    ).toLocaleDateString() +
                                      ' ' +
                                      new Date(
                                        comment.created_at
                                      ).toLocaleTimeString()}
                                  </Typography>
                                  <Typography
                                    variant='body2'
                                    gutterBottom
                                    sx={{color: '#FF2D55'}}
                                    onClick={() =>
                                      handleDeleteComment(comment.id, item.id)
                                    }
                                  >
                                    <span style={{cursor: 'pointer'}}>
                                      Удалить коммент
                                    </span>
                                  </Typography>
                                </div>

                                <Typography variant='body2'>
                                  {comment.text}
                                </Typography>
                              </Box>
                            ))}
                          {/* Добавление нового комментария */}
                          <Box
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              marginTop: '10px',
                            }}
                          >
                            <TextField
                              label='Комментарий'
                              variant='outlined'
                              size='small'
                              fullWidth
                              value={newCommentText[item.id] || ''}
                              onChange={(e) =>
                                setNewCommentText({
                                  ...newCommentText,
                                  [item.id]: e.target.value,
                                })
                              }
                            />
                            <Button
                              variant='contained'
                              color='primary'
                              sx={{marginLeft: '10px'}}
                              onClick={() =>
                                onAddComment(item.id, newCommentText[item.id])
                              }
                            >
                              Добавить
                            </Button>
                          </Box>
                        </Box>
                      </Collapse>
                    </TableCell>
                  </TableRow>
                </React.Fragment>
              ))
            ) : (
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  marginTop: '20px',
                }}
              >
                <CircularProgress />
              </Box>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Пагинация */}
      <Box sx={{display: 'flex', justifyContent: 'center', marginTop: '20px'}}>
        <Pagination
          count={totalPages}
          page={currentPage}
          onChange={handlePageChange}
        />
      </Box>
    </Box>
  );
}

function AdminMainPage() {
  return (
    <div className={cl.pageWrapper}>
      <div className={cl.header}>Главная</div>

      <div className={cl.metaWrapper}>
        <Income />

        <HowAboutMans />

        <RequestsBlock />
      </div>
    </div>
  );
}

export default AdminMainPage;
