import React, {useEffect, useState} from 'react';
import cl from './admin-shops-clients.module.css';
import {Collapse, Dialog, IconButton, Snackbar, TextField} from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import {delete_, get, post, url} from '../../../../../utils/request';

class ClientsApiService {
  static async getClientsList() {
    const response = await get(url('/api/admin/client/list/'));

    return response;
  }

  static async copyPositionsToDb(id) {
    const response = await post(
      url('/api/admin/shop/positions/collect/'),
        {
        id,
      }
    );

    return response;
  }

  //   POST /admin/shop/screen/add/  id -> ID магазина
  // добавить screen
  static async createNewScreen(id) {
    const response = await post(
      url('/api/admin/shop/screen/add/'),
        {
        id,
      }
    );

    return response;
  }

  //   DELETE /admin/shop/screen/delete/ id -> ID магазина, screen_id
  // удалить screen
  static async removeScreen(id, shopId) {
    const response = await delete_(
      url('/api/admin/shop/screen/delete/', {
        screen_id: id,
        id: shopId,
      })
    );

    return response;
  }

  // POST /admin/shop/add/
  // name: str
  // address: str
  // owner_id: int
  // screens: int
  static async createNewStoreByOwnerId(name, address, owner_id, screens) {
    const response = await post(
      url('/api/admin/shop/add/'),
        {
        name,
        address,
        owner_id,
        screens,
      }
    );

    return response;
  }

  //   POST /admin/client/add/
  // first_name: str
  // last_name: str
  // email: str
  // password: str -> кнопка сгенерировать для него пароль 12 символов строчные заглавные все цифренные
  // type: str -> ip|company
  // company_name: str -> Индивидуальный предприниматель Руссу Даниил Казбекович
  // payment_account: str -> 40802810900485002000
  // bic: str -> 044525400
  // kpp: str -> КПП хз че это
  // legal_address: str -> юр адрес
  // bank_name: str -> Название банка -> Филиал «Центральный» Банка ВТБ(ПАО)
  // correspondent_account: str -> Корреспондентский счет банка -> 30101810145250000400
  // tax_code: str -> ИНН покупателя или заказчика -> 501815593500
  // personal_discount: int -> персональная скидка % -> 10
  // connection_price: int -> стоимость подключения -> default 12990
  static async createNewClient(params) {
    const response = await post(url('/api/admin/client/add/'), params);

    return response;
  }
}

function AdminShopsClientsComponent() {
  const [openRows, setOpenRows] = useState({});
  const [clientsList, setClientsList] = useState(null);
  const [toastSuccessOpen, setToastSuccessOpen] = useState(false);

  // forms)))))

  // add new store
  const [newStoreName, setNewStoreName] = useState('');
  const [newStorepAddress, setNewStoreAddress] = useState('');
  const [newStoreOwnerId, setNewStoreOwnerId] = useState(null);
  const [newStoreScreensCount, setNewStoreScreensCount] = useState(3);

  const handleAddNewStore = () => {
    const parsedOwnerId = +newStoreOwnerId;
    const parsedScreensCount = +newStoreScreensCount;

    ClientsApiService.createNewStoreByOwnerId(
      newStoreName,
      newStorepAddress,
      parsedOwnerId,
      parsedScreensCount
    ).then(() => updateClientsList());
  };

  const isFormInvalid = () =>
    !(
      newStoreName &&
      newStorepAddress &&
      newStoreOwnerId &&
      newStoreScreensCount
    );

  // add new client
  const [first_name, setFirst_name] = useState('');
  const [last_name, setLast_name] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [type, setType] = useState('');
  const [company_name, setCompany_name] = useState('');
  const [payment_account, setPayment_account] = useState('');
  const [bic, setBic] = useState('');
  const [kpp, setKpp] = useState('');
  const [legal_address, setLegal_address] = useState('');
  const [bank_name, setBank_name] = useState('');
  const [correspondent_account, setCorrespondent_account] = useState('');
  const [tax_code, setTax_code] = useState('');
  const [personal_discount, setPersonal_discount] = useState('');
  const [connection_price, setConnection_price] = useState('');

  const getReadyFormToSend = () => {
    return {
      first_name,
      last_name,
      email,
      password,
      type,
      company_name,
      payment_account: +payment_account,
      bic: +bic,
      kpp,
      legal_address,
      bank_name,
      correspondent_account: +correspondent_account,
      tax_code: +tax_code,
      personal_discount: +personal_discount,
      connection_price: +connection_price,
    };
  };

  const handleAddNewClient = () => {
    const parsedData = getReadyFormToSend();

    ClientsApiService.createNewClient(parsedData).then(() =>
    {
      updateClientsList()
      handleCloseModalAddClient()
    }
    );
  };

  const isNewClientFormInvalid = () =>
    !(
      first_name &&
      last_name &&
      email &&
      password &&
      type &&
      company_name &&
      payment_account &&
      bic &&
      legal_address &&
      bank_name &&
      correspondent_account &&
      tax_code &&
      connection_price
    );

  // modals))
  const [openModalAddStore, setOpenModalAddStore] = useState(false);
  const [openModalAddClient, setOpenModalAddClient] = useState(false);

  const handleCloseModalAddStore = () => {
    setOpenModalAddStore(false);
    setNewStoreOwnerId(null);
  };

  const handleOpenModalAddStore = (id) => {
    setNewStoreOwnerId(id);
    setOpenModalAddStore(true);
  };

  const handleCloseModalAddClient = () => {
    setOpenModalAddClient(false);
  };

  const handleCloseToast = () => setToastSuccessOpen(false);

  const handleToggleRow = (id) => {
    setOpenRows((prevState) => ({...prevState, [id]: !prevState[id]}));
  };

  const updateClientsList = () => {
    ClientsApiService.getClientsList().then((res) => {
      setClientsList(res.clients);
    });
  };

  useEffect(() => {
    updateClientsList();
  }, []);

  const onAddScreen = (id) => {
    ClientsApiService.createNewScreen(id)
      .then(() => updateClientsList())
      .catch((e) => alert(`Ошибка ${e}`));
  };

  const onRemoveScreen = (id, shopId) => {
    ClientsApiService.removeScreen(id, shopId)
      .then(() => updateClientsList())
      .catch((e) => alert(`Ошибка ${e}`));
  };

  const handleCopyPositions = (id) => {
    ClientsApiService.copyPositionsToDb(id)
      .then(() => setToastSuccessOpen(true))
      .catch((e) => alert(`Ошибка копирования ${e}`));
  };

  const generatePassword = () => {
    const lowercase = 'abcdefghijklmnopqrstuvwxyz';
    const uppercase = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    const numbers = '0123456789';
    const allChars = lowercase + uppercase + numbers;

    // Гарантируем наличие хотя бы одного символа из каждой группы
    let password = '';
    password += lowercase[Math.floor(Math.random() * lowercase.length)];
    password += uppercase[Math.floor(Math.random() * uppercase.length)];
    password += numbers[Math.floor(Math.random() * numbers.length)];

    // Добавляем оставшиеся символы (чтобы итоговая длина пароля была 12 символов)
    for (let i = 3; i < 12; i++) {
      password += allChars[Math.floor(Math.random() * allChars.length)];
    }

    // Перемешиваем пароль для случайности
    password = password
      .split('')
      .sort(() => 0.5 - Math.random())
      .join('');

    return password;
  };

  return (
    <div className={cl.pageWrapper}>
      <Snackbar
        open={toastSuccessOpen}
        autoHideDuration={5000}
        onClose={handleCloseToast}
        message='Позиции успешно сохраненны'
      />
      <Dialog
        maxWidth={'sm'}
        fullWidth={false}
        scroll={'body'}
        classes={{root: 'modal-styles-root'}}
        onClose={handleCloseModalAddStore}
        open={openModalAddStore}
      >
        <div className={cl.modalWrapper}>
          <div className={cl.modalHeader}>
            <div className={cl.modalTitle}>Модалка бля</div>

            <IconButton onClick={() => handleCloseModalAddStore()}>
              <CloseRoundedIcon />
            </IconButton>
          </div>

          <div className={cl.modalBody}>
            <TextField
              label='Название нового магазина'
              variant='outlined'
              type='text'
              value={newStoreName}
              sx={{width: '100%'}}
              onInput={(e) => setNewStoreName(e.target.value)}
            />

            <TextField
              label='Адрес'
              variant='outlined'
              type='text'
              value={newStorepAddress}
              sx={{width: '100%'}}
              onInput={(e) => setNewStoreAddress(e.target.value)}
            />

            <TextField
              label='Количество экранов'
              variant='outlined'
              type='number'
              value={newStoreScreensCount}
              sx={{width: '100%'}}
              onInput={(e) => setNewStoreScreensCount(e.target.value)}
            />
          </div>

          <button
            onClick={() => handleAddNewStore()}
            disabled={isFormInvalid()}
            className={cl.modalSaveAction}
          >
            Добавить магазин
          </button>
        </div>
      </Dialog>

      <Dialog
        maxWidth={'sm'}
        fullWidth={false}
        scroll={'body'}
        classes={{root: 'modal-styles-root'}}
        onClose={handleCloseModalAddClient}
        open={openModalAddClient}
      >
        <div className={cl.modalWrapper}>
          <div className={cl.modalHeader}>
            <div className={cl.modalTitle}>Модалка бля</div>

            <IconButton onClick={() => handleCloseModalAddClient()}>
              <CloseRoundedIcon />
            </IconButton>
          </div>

          <div className={cl.modalBody}>
            <TextField
              label='Имя'
              variant='outlined'
              type='text'
              value={first_name}
              sx={{width: '100%'}}
              onInput={(e) => setFirst_name(e.target.value)}
            />
            <TextField
              label='Фамилия'
              variant='outlined'
              type='text'
              value={last_name}
              sx={{width: '100%'}}
              onInput={(e) => setLast_name(e.target.value)}
            />
            <TextField
              label='Эл. почта'
              variant='outlined'
              type='email'
              value={email}
              sx={{width: '100%'}}
              onInput={(e) => setEmail(e.target.value)}
            />

            <div className={cl.passwordField}>
              <TextField
                label='Пароль'
                variant='outlined'
                type='text'
                value={password}
                sx={{width: '100%'}}
                onInput={(e) => setPassword(e.target.value)}
              />

              <button
                onClick={() => setPassword(generatePassword())}
                className={cl.actionGeneratePassword}
              >
                Сгенерировать
              </button>
            </div>
            <TextField
              label='ip / company'
              variant='outlined'
              type='text'
              value={type}
              sx={{width: '100%'}}
              onInput={(e) => setType(e.target.value)}
            />
            <TextField
              label='Наименование компании'
              variant='outlined'
              type='text'
              value={company_name}
              sx={{width: '100%'}}
              onInput={(e) => setCompany_name(e.target.value)}
            />
            <TextField
              label='Номер счета'
              variant='outlined'
              type='number'
              value={payment_account}
              sx={{width: '100%'}}
              onInput={(e) => setPayment_account(e.target.value)}
            />
            <TextField
              label='БИК'
              variant='outlined'
              type='number'
              value={bic}
              sx={{width: '100%'}}
              onInput={(e) => setBic(e.target.value)}
            />
            <TextField
              label='КПП'
              variant='outlined'
              type='text'
              value={kpp}
              sx={{width: '100%'}}
              onInput={(e) => setKpp(e.target.value)}
            />
            <TextField
              label='Юр. адрес'
              variant='outlined'
              type='text'
              value={legal_address}
              sx={{width: '100%'}}
              onInput={(e) => setLegal_address(e.target.value)}
            />
            <TextField
              label='Наименование банка'
              variant='outlined'
              type='text'
              value={bank_name}
              sx={{width: '100%'}}
              onInput={(e) => setBank_name(e.target.value)}
            />
            <TextField
              label='Корреспондентский счет банка'
              variant='outlined'
              type='number'
              value={correspondent_account}
              sx={{width: '100%'}}
              onInput={(e) => setCorrespondent_account(e.target.value)}
            />
            <TextField
              label='ИНН покупателя или заказчика'
              variant='outlined'
              type='number'
              value={tax_code}
              sx={{width: '100%'}}
              onInput={(e) => setTax_code(e.target.value)}
            />
            <TextField
              label='Персональная скидка'
              variant='outlined'
              type='number'
              value={personal_discount}
              sx={{width: '100%'}}
              onInput={(e) => setPersonal_discount(e.target.value)}
            />
            <TextField
              label='Стоимость подключения'
              variant='outlined'
              type='number'
              value={connection_price}
              sx={{width: '100%'}}
              onInput={(e) => setConnection_price(e.target.value)}
            />
          </div>

          <button
            onClick={() => handleAddNewClient()}
            className={cl.modalSaveAction}
          >
            Добавить клиента
          </button>
        </div>
      </Dialog>

      <div className={cl.header}>
        <span className={cl.headerTittle}>Редактор базы пива</span>

        <button
          onClick={() => setOpenModalAddClient(true)}
          className={cl.mainAction}
        >
          Добавить клиента
        </button>
      </div>
      <div className={cl.metaWrapper}>
        {clientsList &&
          clientsList.map((item) => {
            return (
              <div key={item.id} className={cl.cardWrapper}>
                <div className={cl.cardHeader}>
                  <div className={cl.cardInfo}>
                    <IconButton onClick={() => handleToggleRow(item.id)}>
                      {openRows[item.id] ? (
                        <KeyboardArrowUpIcon />
                      ) : (
                        <KeyboardArrowDownIcon />
                      )}
                    </IconButton>

                    <div className={cl.clientInfo}>
                      <span className={cl.clientName}>{item.first_name}</span>

                      <span className={cl.clientEmail}>{item.email}</span>
                    </div>
                  </div>

                  <div className={cl.cardActions}>
                    <button className={cl.asClientAction}>
                      Войти как клиент
                    </button>

                    <button
                      onClick={() => handleOpenModalAddStore(item.id)}
                      className={cl.addStoreAction}
                    >
                      Добавить магазин
                    </button>
                  </div>
                </div>

                <Collapse
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    marginTop: '16px',
                  }}
                  in={openRows[item.id]}
                  timeout='auto'
                  unmountOnExit
                >
                  <div className={cl.aboutClient}>
                    <div className={cl.aboutPart}>
                      <span className={cl.aboutInfo}>
                        Активные точки клиента
                      </span>

                      <span className={cl.aboutValue}>
                        {item.stores !== null && item.stores.length}
                      </span>
                    </div>

                    <div className={cl.aboutPart}>
                      <span className={cl.aboutInfo}>MRR клиента</span>

                      <span className={cl.aboutValue}>Хуй знает</span>
                    </div>
                  </div>

                  {item.stores !== null &&
                    item.stores.length > 0 &&
                    item.stores.map((store) => {
                      return (
                        <div
                          key={store.id}
                          className={cl.manageBlock}
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            backgroundColor: '#eeeeee',
                            gap: '12px',
                            borderRadius: '12px',
                            padding: '16px',
                            marginTop: '16px',
                          }}
                        >
                          <div className={cl.storeInfoWrapper}>
                            <div className={cl.storeInfo}>
                              <div className={cl.storeInfoPart}>
                                <span className={cl.infoPartName}>Магазин</span>
                                <span className={cl.valuePartName}>
                                  {store.name}
                                </span>
                              </div>

                              <div className={cl.storeInfoPart}>
                                <span className={cl.infoPartName}>Адрес</span>
                                <span className={cl.valuePartName}>
                                  {store.address}
                                </span>
                              </div>

                              <div className={cl.storeInfoPart}>
                                <span className={cl.infoPartName}>
                                  Товаров / баннеров
                                </span>
                                <span className={cl.valuePartName}>
                                  {store.menu_items_count +
                                    ' / ' +
                                    store.banners_count}
                                </span>
                              </div>

                              {/* <div className={cl.storeInfoPart}>
                              <span className={cl.infoPartName}>Аккаунтов</span>
                              <span className={cl.valuePartName}>5</span>
                            </div> */}
                            </div>

                            <div className={cl.storeInfoActions}>
                              <button
                                onClick={() => onAddScreen(store.id)}
                                className={cl.storeInfoAction}
                              >
                                Добавить экран
                              </button>

                              <button
                                disabled={true}
                                className={cl.storeInfoAction}
                              >
                                Добавить менеджера
                              </button>
                            </div>
                          </div>

                          <div className={cl.storeScreens}>
                            <span className={cl.headerPart}>Экраны</span>

                            <div className={cl.screensCompose}>
                              {store.screens && store.screens.length > 0 ? (
                                store.screens
                                  .sort((a, b) => a.number - b.number)
                                  .map((screen) => {
                                    return (
                                      <div
                                        key={screen.id}
                                        className={cl.screenWrapper}
                                      >
                                        <span className={cl.screenNumber}>
                                          {screen.number}
                                        </span>

                                        {screen.number > 3 && (
                                          <IconButton
                                            onClick={() =>
                                              onRemoveScreen(
                                                screen.id,
                                                store.id
                                              )
                                            }
                                            sx={{padding: '0'}}
                                          >
                                            <CloseRoundedIcon />
                                          </IconButton>
                                        )}
                                      </div>
                                    );
                                  })
                              ) : (
                                <span>{'Тут пока пусто :('}</span>
                              )}
                            </div>
                          </div>

                          <div className={cl.actions}>
                            <span className={cl.headerPart}>База пива</span>

                            <div className={cl.actionsWrapper}>
                              <button className={cl.actionsAction}>
                                Отправить в другой магазин
                              </button>

                              <button
                                onClick={() => handleCopyPositions(store.id)}
                                className={cl.actionsAction}
                              >
                                Сохранить позиции в общую базу
                              </button>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                </Collapse>
              </div>
            );
          })}
      </div>
    </div>
  );
}

export default AdminShopsClientsComponent;
