import React from 'react';
import cl from './landing-body.module.css';
import {ReactComponent as CustomIconBeer} from '../../../../../images/customIconBeer.svg';
import {ReactComponent as CustomIconDummyImage} from '../../../../../images/customIconDummyImage.svg';
import {ReactComponent as CustomIconUpload} from '../../../../../images/customIconUpload.svg';
import {ReactComponent as CustomIconLamp} from '../../../../../images/customIconLamp.svg';
import {ReactComponent as CustomChevronRight} from '../../../../../images/customIconChevronRight.svg';
import CustomIconGreatBeer from '../../../../../images/customIconGreatBeer.png';
import LandingBodyAdditionals from './components/additionals/additionals.components';

function LandingBody() {
  return (
    <div className={cl.landingBodyWrapper}>
      <div className={cl.bodyComposition}>
        <div className={cl.meta}>
          <h2 className={cl.bodyTitle}>ПабХаб делает всё практичнее</h2>

          <div className={cl.content}>
            <div className={cl.contentDescriptionWrapper}>
              <div className={cl.contentDescriptionPart}>
                <CustomIconBeer />
                <span className={cl.descriptionTitle}>
                  Меню на&nbsp;экране 24/7
                </span>
              </div>

              <div className={cl.contentDescriptionPart}>
                <span className={cl.description}>
                  Покажи гостям всё, что у&nbsp;тебя есть&nbsp;&mdash;
                  от&nbsp;стаутов до&nbsp;светлых лагеров&nbsp;&mdash;
                  в&nbsp;стильном и&nbsp;удобном формате
                </span>
              </div>
            </div>

            <div className={cl.descriptionImage}>
              <video preload loop muted autoPlay playsInline name='media'>
                <source
                  src='https://pubhub.su/assets/videos/landing.content2.mp4'
                  type='video/mp4'
                />
              </video>
            </div>
          </div>

          <div className={cl.content}>
            <div className={cl.contentDescriptionWrapper}>
              <div className={cl.contentDescriptionPart}>
                <CustomIconDummyImage />
                <span className={cl.descriptionTitle}>
                  Баннеры на&nbsp;весь экран или внутри меню
                </span>
              </div>

              <div className={cl.contentDescriptionPart}>
                <span className={cl.description}>
                  Заманивай гостей свежими акциями или хвастайся
                  новинками&nbsp;&mdash; всё это легко добавляется в&nbsp;меню
                </span>
              </div>
            </div>

            <div className={cl.descriptionImage}>
              <video preload loop muted autoPlay playsInline name='media'>
                <source
                  src='https://pubhub.su/assets/videos/landing.content3.mp4'
                  type='video/mp4'
                />
              </video>
            </div>
          </div>

          <div className={cl.content}>
            <div className={cl.contentDescriptionWrapper}>
              <div className={cl.contentDescriptionPart}>
                <CustomIconUpload />
                <span className={cl.descriptionTitle}>
                  Конструктор отображения
                </span>
              </div>

              <div className={cl.contentDescriptionPart}>
                <span className={cl.description}>
                  {' '}
                  Позволяет расположить все элементы в&nbsp;любом удобном
                  порядке, в&nbsp;том числе расставить их&nbsp;автоматически
                </span>
              </div>
            </div>

            <div className={cl.descriptionImage}>
              <video preload loop muted autoPlay playsInline name='media'>
                <source
                  src='https://pubhub.su/assets/videos/landing.content4.mp4'
                  type='video/mp4'
                />
              </video>
            </div>
          </div>

          <div className={cl.content}>
            <div className={cl.contentDescriptionWrapper}>
              <div className={cl.contentDescriptionPart}>
                <CustomIconLamp />
                <span className={cl.descriptionTitle}>
                  Современный вид, простое управление
                </span>
              </div>

              <div className={cl.contentDescriptionPart}>
                <span className={cl.description}>
                  ПабХаб создан для тех, кто любит порядок и&nbsp;стиль
                  в&nbsp;своём баре
                </span>
              </div>
            </div>

            <div className={cl.descriptionImage}>
              <video preload loop muted autoPlay playsInline name='media'>
                <source
                  src='https://pubhub.su/assets/videos/landing.content5.mp4'
                  type='video/mp4'
                />
              </video>
            </div>
          </div>

          <div className={cl.blockWrapper}>
            <h2 className={cl.bodyTitle}>Скидки от&nbsp;партнёров</h2>

            <div className={cl.partner}>
              <img src={CustomIconGreatBeer} />

              <span className={cl.announce}>
                Скидка от&nbsp;поставщика пива специально для наших клиентов
              </span>

              <a
                className={cl.partnerUrl}
                target='_blank'
                href='https://greatbeer.ru'
              >
                <span>Подробнее</span>

                <CustomChevronRight />
              </a>
            </div>
          </div>

          <LandingBodyAdditionals />
        </div>
      </div>
    </div>
  );
}

export default LandingBody;
