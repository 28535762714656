import {get, url} from '../../../../../../utils/request';

const MOCK_DATA = [
  {
    id: 1,
    time: '12:31',
    date: '12.09.2024',
    user: 'Галина Павлова',
    action: 'Проверка текстовой релевантности шаг 2 по parfum-lider завершена',
    label: 'Текстовая релевантность',
  },
  {
    id: 2,
    time: '12:30',
    date: '12.09.2024',
    user: 'Галина Павлова',
    action: 'Запуск проверки текстовой релевантности шаг 2 для parfum-lider',
    label: 'Текстовая релевантность',
  },
  {
    id: 3,
    time: '12:22',
    date: '12.09.2024',
    user: 'Галина Павлова',
    action: 'Запуск выгрузки по tstn [PROD]',
    label: 'Выгрузка коллекций',
  },
];

export default class HowAboutMansService {
  static async getHowAreMans() {
    // const response = await get(url('/admin/requests/list/'), {
    //   search_query,
    //   page,
    //   per_page,
    // });

    return Promise.resolve(MOCK_DATA);
  }
}
