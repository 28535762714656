import React, { useState, useRef } from 'react';

const VideoPlayer = ({ src, maxRetries = 5 }) => {
  const [retryCount, setRetryCount] = useState(0);
  const videoRef = useRef(null);

  const handleVideoError = () => {
    if (retryCount < maxRetries) {
      setRetryCount(prevCount => prevCount + 1);
      console.log(`Попытка перезагрузки видео: ${retryCount + 1}`);

      // Сбрасываем источник видео, чтобы принудительно перезагрузить его
      const currentSrc = videoRef.current.src;
      videoRef.current.src = '';
      videoRef.current.load();

      // Задержка перед повторной загрузкой (опционально)
      setTimeout(() => {
        videoRef.current.src = currentSrc;
        videoRef.current.load();
        videoRef.current.play();
      }, 1000);
    } else {
      console.error('Не удалось загрузить видео после максимального числа попыток.');
    }
  };

  return (
    <video
      ref={videoRef}
      src={src}
      muted
      autoPlay
      loop
      playsInline
      onError={handleVideoError}
    />
  );
};

export default VideoPlayer;